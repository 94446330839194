// @flow

import React, {useState, useEffect} from 'react';

type IWithPageScrollProps = {
    yPoint: number,
    children: any
}

/* HOC that lets it's children know when the user scrolled until a certain Y point */

export const usePageScroll = (yPoint: number): boolean => {
    const [hasReachedPoint, setHasReachedPoint] = useState(false);

    useEffect((): () => void => {
        const doc: any = document;
        const onScroll = () => {
            if (doc.body.scrollTop > yPoint || doc.documentElement.scrollTop > yPoint) {
                setHasReachedPoint(true);
            } else {
                setHasReachedPoint(false);
            }
        };
        doc.addEventListener('scroll', onScroll);
        return () => {
            doc.removeEventListener('scroll', onScroll);
        };
    });

    return hasReachedPoint;
};

export const WithPageScroll = ({yPoint, children}: IWithPageScrollProps): any => {
    const hasReachedPoint = usePageScroll(yPoint);
    return (
        <React.Fragment>
            {children(hasReachedPoint)}
        </React.Fragment>
    );
};
