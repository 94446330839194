// @flow
import { put, takeLatest } from 'redux-saga/effects';
import env from '../utils/env';
import auth from '../utils/auth';
import { getWotTraceId } from '../utils';

function* submitMRT(action: Object): Iterable<any> {
    try {
        let root = env(['configuration', 'scorecardApi']);
        const token = auth.getToken();

        if (typeof root !== 'string' || root.length < 5) {
            yield put({ type: 'MRT_FAILED', data: { message: 'empty configuration for api' } });
        } else {
            const wotTraceId = getWotTraceId();

            let response = yield fetch(`${root}v3/review/massRating`, {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token ? `Bearer ${token}` : '',
                    'wot-trace-id': wotTraceId
                },
                credentials: 'same-origin',
                body: JSON.stringify(action.data),
            });
            const result = yield response && response.json();
            if (response && response.status === 200 && result && result.status === 200) {
                yield put({ type: 'MRT_SUCCEED', data: result });
            } else {
                yield put({ type: 'MRT_FAILED', data: { status: response && response.status, message: result } });
            }
        }
    } catch (err) {
        yield put({ type: 'MRT_FAILED', data: { status: '', message: err } });
    }
}

function* massRatingSaga(): Iterable<any> {
    yield takeLatest('MRT_SUBMITTED', submitMRT);
}

export default massRatingSaga;
