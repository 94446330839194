import styled from 'styled-components';
import {FlexRow, mobileBreakPoint} from '../../StyledUtils';
import {HEADER_HEIGHT} from '../../layouts/theme';

export const CookiesConsentContainer = styled(FlexRow)`
  position: fixed;
  bottom: 16px;
  left: 16px;
  width: 479px;
  padding: 16px;
  z-index: 9999;
  border-radius: 8px;
  align-items: center;
  background: #FFFFFF;
  justify-content: space-between;
  border: 1px solid #D3D9DE;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 2px 1px -2px rgba(0, 0, 0, 0.06);
  @media (max-width: ${mobileBreakPoint}){
    box-sizing: border-box;
    border-radius: 0;
    padding: 8px 12px;
    height: ${HEADER_HEIGHT}px;
    width: 100%;
    top: 0;
    bottom: unset;
    left: unset;
  }
`;

export const Description = styled.div`
  color: #545454;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  width: 349px;
  @media (max-width: ${mobileBreakPoint}){
    width: auto;
    font-size: 12px;
    line-height: 16px;
  }
`;

export const Link = styled.a`
  color: #373B3E;
  border-bottom: 1px solid;
  font-weight: 600;
`;

export const CTA = styled.div.attrs({
  'data-automation': 'close'
})`
  display: flex;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  padding: 8px 12px;
  line-height: 16px;
  text-align: center;
  border-radius: 4px;
  background: #057AFF;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  @media (max-width: ${mobileBreakPoint}){
    padding: 8px;
  }
`;
