// @flow
import { initializeApp } from 'firebase/app';
import { getAuth, signInAnonymously, onAuthStateChanged, type User } from 'firebase/auth';
import React, { useEffect, useMemo, useState, Context, createContext, Node, useContext } from 'react';

import env from '../../utils/env';

const firebaseConfig = env(['configuration', 'firebaseConfig']);

const app = initializeApp(JSON.parse(firebaseConfig));

type FirebaseAuthContextType = {
  user: ?User,
};

type Props = {
  children: Node,
};

const FirebaseAuthContext: Context<?FirebaseAuthContextType> = createContext({
  user: null,
});

export const useFirebaseAuth = (): FirebaseAuthContextType => {
  return useContext(FirebaseAuthContext);
};


const FirebaseAuthComponent = ({ children }: Props): Node => {
  const [user, setUser] = useState<?User | null>(null);
  const value = useMemo(() => ({ user }), [user]);

  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      if (!authUser) {
        firebaseSignInAnonymously();
      } else {
        setUser(authUser);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [auth]);

  const firebaseSignInAnonymously = async () => {
    try {
      const userCard = await signInAnonymously(auth);
      if (userCard) {
        setUser(userCard.user);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FirebaseAuthContext.Provider value={value}>
      {children}
    </FirebaseAuthContext.Provider>
  );
};

export default FirebaseAuthComponent;
