// @flow
import pick from 'lodash/pick';
import { combineReducers } from 'redux';

import { lastAction } from './lastAction';
import { scorecard } from './scorecard';
import { auth } from './auth';
import { form } from './form';
import { general } from './general';
import { recentReviews } from './recentReviews';
import { massRating } from './massRating';
import { usercard } from './usercard';
import { sites } from './sites';
import { deviceInfo } from './deviceInfo';

let allReducers = { lastAction, scorecard, auth, form, general, recentReviews, usercard, massRating, sites, deviceInfo };

export default function configure(names: Array<string> | string): Function {
    return combineReducers(pick(allReducers, names));
}
