// @flow
import pick from 'lodash/pick';
import values from 'lodash/values';

import scorecardSaga from './scorecard.js';
import authSaga from './auth.js';
import formsSaga from './forms.js';
import recentReviewsSaga from './recentReviews.js';
import massRatingSaga from './massRating.js';
import usercardSaga from './usercard.js';
import sitesSaga from './sites.js';

export let sagas = { scorecardSaga, authSaga, formsSaga, massRatingSaga, recentReviewsSaga, usercardSaga, sitesSaga };

export default function configure(names: Array<string> | string): Function {
    return values(pick(sagas, names));
}
