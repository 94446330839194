// @flow
import {v4 as uuidV4} from 'uuid';

import { write } from './cookie';
import { SYNC_EXT_ANALYTIC_INFO_COOKIE_NAME, COOKIE_MAXIMUM_LIFESPAN } from './const';
import env from './env';
import type {EventParams} from '../hooks/useInternalAnalytics';

const ANALYTICS = {
    LOCAL_STORAGE_KEY: 'ANALYTICS_INFO'
};

const getAnalyticsInfoFromLocalStorage = (): string => JSON.parse(localStorage.getItem(ANALYTICS.LOCAL_STORAGE_KEY));

const syncWithWebapp = async (): Promise<string> => {
    let uuid;
    try {
        const syncApi = `${env(['configuration', 'mywotAppUrl'])}/api/analytics/sync`;
        const response = await fetch(syncApi);
        let uuidFromWebApp;
        if (response.status === 200) {
            uuidFromWebApp = await response.json();
        }
        uuid = uuidFromWebApp?.uuid || uuidV4();
        const domain = location.hostname.replace('www', '');
        write(SYNC_EXT_ANALYTIC_INFO_COOKIE_NAME, uuid, `;Path=/;Domain=${domain};Expires=${COOKIE_MAXIMUM_LIFESPAN}`);

        await fetch(syncApi, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ uuid })
        });
    } catch (e) {
        console.error(e);
    }
    return uuid || uuidV4();
};

export const getAnalyticsUuid = async (): Promise<string> => {
    if (!getAnalyticsInfoFromLocalStorage()?.uuid) {
        localStorage.setItem(ANALYTICS.LOCAL_STORAGE_KEY,
            JSON.stringify({
                uuid: await syncWithWebapp()
            })
        );
    }
    return getAnalyticsInfoFromLocalStorage().uuid;
};

const sendEvent = (event: Object) => {
    fetch(`${env(['configuration', 'mywotAppUrl'])}/api/analytics/event`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(event),
    });
};

const eventsQueue: EventParams[] = [];
const flashQueue = (uuid: string) => {
    while (eventsQueue.length) {
        const event = eventsQueue.shift();
        sendEvent({...event, uuid});
    }
};

let uuidPending = false;
const waitForUuidAndFlashQueue = () => {
    if (!uuidPending) {
        uuidPending = true;
        getAnalyticsUuid().then((uuid: string) => {
            flashQueue(uuid);
            uuidPending = false;
        });
    }
};

export const sendInternalEvent = (event: EventParams) => {
    const uuid = getAnalyticsInfoFromLocalStorage()?.uuid;
    if (uuid) {
        sendEvent({...event, uuid});
    } else {
        eventsQueue.push(event);
        waitForUuidAndFlashQueue();
    }
};
