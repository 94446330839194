import styled from 'styled-components';

export const FiveFullStarsRow = styled.img.attrs({
    src: '/images/stars/5-full-stars.svg',
    alt: 'WOT Review Stars'
})`
    width: 120px;
    height: 24px;
`;

export const FourAndAHalfStarsRow = styled.img.attrs({
    src: '/images/stars/4-half-stars.svg',
    alt: 'WOT Review Stars'
})`
    width: 120px;
    height: 24px;
`;

export const FourFullStarsRow = styled.img.attrs({
    src: '/images/stars/4-full-stars.svg',
    alt: 'WOT Review Stars'
})`
    width: 120px;
    height: 24px;
`;
