// @flow
import * as React from 'react';
import type { Node, ComponentType } from 'react';
import {LocalesConsumer} from '../utils/locales';
import type {ILocalesTransformers} from '../utils/locales';

type Props = {}

function withLocales(WrappedComponent: React.ComponentType<any>): ComponentType<Props> {
    return class Location extends React.PureComponent<Props> {
        constructor(props: Object) {
            super(props);
        }

        render(): Node {
            return (
                <LocalesConsumer>
                    {
                        ({translate}: ILocalesTransformers): Node => {
                            return <WrappedComponent translate={translate} {...this.props} />;
                        }
                    }
                </LocalesConsumer>

            );
        };
    };
}

export default withLocales;
