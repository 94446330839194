// @flow

import type { Node, ComponentType } from 'react';
import { isNode } from './isnode';
import { read, write } from './cookie';
import { EXTENSION_INSTALLED_COOKIE } from './const';

export function isExtensionInstalled(cookies?: Object): boolean {
    if (isNode()) {
        return Boolean(read(EXTENSION_INSTALLED_COOKIE, cookies));
    }
    const domain = location.hostname.replace('www', '');
    const isInCookies = Boolean(read(EXTENSION_INSTALLED_COOKIE));
    const isInstalled = Boolean(window.document.getElementById('wot-installed-container') || Boolean(window.document.getElementById('warning-container')) || Boolean(window.document.getElementById('wot-warning-container')));
    if (isInstalled && !isInCookies) {
        write(EXTENSION_INSTALLED_COOKIE, 'true', `;Path=/;Domain=${domain};Expires=Fri, 01 Jan 2038 00:00:01 GMT`);
    }
    if (!isInstalled && isInCookies) {
        write(EXTENSION_INSTALLED_COOKIE, '', `;Path=/;Domain=${domain};Expires=Thu, 01 Jan 1970 00:00:01 GMT`);
    }
    return isInCookies || isInstalled;
}

export const extInstalled = isExtensionInstalled();
