import styled from 'styled-components';
import {mobileBreakPoint} from '../../StyledUtils';

export const StyledUserAvatar = styled.div`
  width: 48px;
  height: 48px;
  background-size: cover;
  color: #FFFFFF;
  border-radius: 50%;
  text-align: center;
  line-height: 48px;
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  @media (max-width: ${mobileBreakPoint}) {
    line-height: 40px;
    font-size: 16px;
  }
  span {
    opacity: 0.9;
    text-transform: capitalize;
  }
  img {
    width: inherit;
    height: inherit;
    border-radius: 50%;
    object-fit: cover;
  }
`;
