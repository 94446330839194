import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import FirebaseAuthComponent from '../components/UserDetails/firebase';
import { MainApp } from '../components/MainApp';

const ClientProvider = ({basename, store, gbSSRData, children}) => {
    return (
        <FirebaseAuthComponent>
            <BrowserRouter basename={basename}>
                <MainApp reduxStore={store} gbSSRData={gbSSRData}>
                    {children}
                </MainApp>
            </BrowserRouter>
        </FirebaseAuthComponent>
    );
};

export default ClientProvider;
