// @flow
const styles: Function = (theme: Object): Object => ({
    root: {
        top: 24,
        [theme.breakpoints.down('sm')]: {
            top: 0,
        },
    },
    content: {
        flexWrap: 'nowrap',
        paddingLeft: 35,
    },
    default: {
        backgroundColor: '#4589dd',
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    success: {
        backgroundColor: theme.palette.primary.dark,
    },
});

export default styles;
