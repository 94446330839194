// @flow
import {zIndexModal} from '../../../StyledUtils';

const styles: Function = (theme: Object): Object => ({
    root: {
        zIndex: `${zIndexModal} !important`,
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 70,
        justifyContent: 'space-between',
        marginTop: 24,
        marginBottom: 20,
        // boxShadow: "0 10px 15px 0 rgba(155, 155, 155, 0.07)"
    },
    logo: {
        height: 23,
        width: 60,
        minWidth: 60,
        boxSizing: 'border-box',
    },
    content: {
        overflowY: 'visible',
        width: '100%',
        boxSizing: 'border-box',
    },
    actions: {
        padding: 18,
    },
    fields: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
    },
    helper: {
        textDecoration: 'none',
        marginTop: 3,
        color: theme.palette.primary.main,
    },
    socialContainer: {
        minWidth: 250,
        width: '100%',
        maxWidth: 280,
    },
    textDivider: {
        marginBottom: -12,
        marginTop: 5,
    },
    loader: {
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 100,
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
    modalInput: {
        width: 260,
    },
});

export default styles;
