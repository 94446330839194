// @flow
import React from 'react';
import {StyledSectionHeader, Icon, Title, Subtitle} from './StyledSectionHeader';

type ISectionHeaderProps = {
    iconPath?: string,
    title: string,
    subtitle?: string,
    subtitleComponent?: boolean
}

export const SectionHeader = (props: ISectionHeaderProps): any => {
    const { iconPath, title, subtitle, subtitleComponent } = props;

    return (
        <StyledSectionHeader>
            <Icon iconPath={iconPath}/>
            <Title>{title}</Title>
            <Subtitle as={subtitleComponent || 'div'}>{subtitle}</Subtitle>
        </StyledSectionHeader>
    );
};
