// @flow
import merge from 'lodash/merge';

export function sites(state: Object = {}, action: Object): Object {
    switch (action.type) {
        case 'SITE_CLAIM_REQUESTED':
        case 'SITE_CLAIM_FAILED':
            return merge({}, state, {
                sitesToClaim: action.data.urls,
                claimStatus: action.type,
            });
        case 'SITE_CLAIM_SUCCEED':
            return {
                sitesToClaim: [],
                claimStatus: action.type,
            };
        case 'SITE_VERIFY_REQUESTED':
        case 'SITE_VERIFY_FAILED':
        case 'SITE_VERIFY_CERT_EXPIRED':
            return merge({}, state, {
                sitesToVerify: action.data.domain,
                verifyStatus: action.type,
            });
        case 'SITE_VERIFY_SUCCEED':
            return merge({}, state, {
                sitesToVerify: '',
                verifyStatus: action.type,
            });
        default:
            return merge({}, state);
    }
}
