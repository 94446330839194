// @flow
import { isNode } from './isnode';
import { shouldHideCookieConsentInPage } from './index';
import { CLOSE_DISCLAIMER_KEY } from './const';

export function read(name: string, cookies: ?any): any {
    if (isNode()) {
        return cookies && cookies[name];
    } else {
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');

        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length).replace('=', '');
            }
        }
        return null;
    }
}

export function write(name: string, value: string, options: ?string, cookies: ?any): boolean {
    if (isNode()) {
        cookies && (cookies(name, value));
    } else {
        document.cookie = `${name}=${value}${options || ''}`;
    }
    return true;
}

export function shouldShowCookiesConsent(req?: Request): boolean {
    const userAcceptedCookies = read(CLOSE_DISCLAIMER_KEY, req?.cookies);
    return !userAcceptedCookies && !shouldHideCookieConsentInPage(req?.originalUrl);
}

export default { read, write };
