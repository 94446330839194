// @flow
const styles: Function = (theme: Object): Object => ({
    input: {
        'display': 'flex',
        'align-items': 'center',
        'borderRadius': 4,
        'backgroundColor': '#ededed',
        'border': 'none',
        'fontSize': 16,
        'padding': 15,
        'maxWidth': 500,
        'minWidth': 200,
        'transition': theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderColor: '#82d827',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
        'boxSizing': 'border-box',
    },
    adornment: {
        color: '#666666',
    },
    adornmentEnd: {
        marginLeft: '-38px',
        color: '#666666',
    },
    inputRoot: {
        'height': 'auto',
        'boxSizing': 'border-box',
        '&::placeholder': {
            color: 'rgba(0, 0, 0, 0.8)',
        },
        minHeight: 20
        // 'height': '1.4rem !important',
        // 'height': 'auto',
    },
    labelInvisible: {
        color: '#666666 !important',
        top: 36,
        left: 50,
        // transition: 'top 0.2s, left 0.2s, opacity 0.3s',
        transition: 'top 0.2s, opacity 0.3s',
    },
    labelVisible: {
        color: '#666666 !important',
        top: 0,
        left: 0,
        opacity: 1,
        // transition: 'top 0.2s, left 0.2s, opacity 0.3s',
        transition: 'top 0.2s, opacity 0.3s',
    },
    underline: {
        // '&:hover:before': {backgroundColor: "#aa3485"},
        '&:before': { border: 'none', backgroundColor: '#fffffff' },
        '&:hover:not($disabled):before': { border: 'none', backgroundColor: '#fffffff' },
    },
    disabled: {},
    inputHack: {
        // padding: "13px 0 0px", FIXINNG MULTILINE TEXTAREA
        marginTop: 12,

    },
    labelHack: {
        zIndex: 5,
        top: 26,
        left: 14,
    },
    labelHackAdornment: {
        zIndex: 5,
        top: 26,
        left: 47,
    },
    helperText: {
        top: 'calc(100% - 3px)',
        left: 17,
        position: 'absolute',
        maxWidth: 480,
    },
    endAdornmentHack: {
        marginRight: 32,
    },
});

export default styles;
