// @flow
import React, {useContext} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/Close';
import styles from './styles';
import type {ILocalesTransformers} from '../../../utils/locales';
import {LocalesContext} from '../../../utils/locales';

const CloseButton = (props: Object): any => {
    const { classes } = props;
    const {translate}: ILocalesTransformers = useContext(LocalesContext);

    return (
        <Button
            variant="contained"
            color="default"
            className={classes.root}
            {...props}
        >
            <CloseButton>
                {translate('general.close')}
            </CloseButton>
        </Button>
    );
};

export default withStyles(styles)(Close);
