import styled from 'styled-components';
import {FlexColumn, FlexRow, mobileBreakPoint} from '../../../StyledUtils';
import {CarouselContainer} from '../../../components/CarouselOurTechnology/StyledCarouselOurTechnology';
import {ButtonContainer, Text, Icon} from '../../../components/buttons/outlineRounded/StyledOutlineRoundedButton';
import {Container, Wrapper, Description} from '../../../components/FeatureCard/StyledFeatureCard';
import {Title} from '../../../components/SectionHeader/StyledSectionHeader';
import {StyledInstallContainer} from '../../../components/InstallCard/StyledInstallCard';

export const StyledFeaturesContainer = styled(FlexColumn)`
  justify-content: center;
  align-items: center;
  @media (max-width: ${mobileBreakPoint}) {
    flex-direction: column;
    ${StyledInstallContainer}{
      padding-bottom: 0px;
    }
  }
`;

export const FeaturesSectionContent = styled(FlexColumn)`
  align-items: center;
  justify-content: center;
  ${CarouselContainer}{
    display: flex;
    margin-top: 80px;
    flex-direction: column-reverse;
  }
`;

export const SectionTitle = styled(Title)`
  color: #037AFF;
  margin: 62px 0 0;
  font-family: Montserrat ,Roboto, Helvetica, Arial, sans-serif;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: normal;
  text-transform: none;
  @media (max-width: ${mobileBreakPoint}) {
     line-height: 26px;
     font-size: 24px;
  }
`;

export const FeatureCardsContainer = styled(FlexRow)`
  width: 1130px;
  margin-bottom: 39px;
  justify-content: space-between;
  ${Container}{
    margin-bottom: 150px;
    @media (max-width: ${mobileBreakPoint}) {
      margin-top: 64px;
    }
  }
  ${Wrapper}{
    height: 280px;
  }
  ${Description}{
     margin-top: 16px;
  }
  ${ButtonContainer}{
    margin-top: auto;
    transition: transform 0.1s ease-in-out;
    &:hover {
      transform: scale(1.1);
      background: #057AFF;
      ${Text}{
        color: white;
      }
      ${Icon}{
        background: url(/images/icons/arrows/arrow-right-white.svg) center center/contain no-repeat;
      }
    }
  }
  @media (max-width: ${mobileBreakPoint}) {
    width: 100%;
    align-items: center;
    flex-direction: column;
  }
`;

export const FeatureSlidesContainer = styled.div`
`;

export const MobileBGContainer = styled(FlexColumn)`
  @media (max-width: ${mobileBreakPoint}) {
    width: 100%;
    margin-top: 34px;
    background: #F1F7FD;
    align-items: center;
  }
`;

export const LogoContainer = styled(FlexRow)`
  height: 48px;
  max-width: 899px;
  margin-bottom: 147px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${mobileBreakPoint}) {
    width: 75%;
    height: 35px;
    flex-wrap: wrap;
  }
`;

export const Logo = styled.div`
  margin: 20px;
  height: 100%;
  width: ${props => props.imgWidthDesktop};
  background: url(${props => props.imgPath}) center center/contain no-repeat;
  @media (max-width: ${mobileBreakPoint}) {
    margin: 5px;
    width: ${props => props.imgWidthMobile};
  }
`;
