// @flow
import merge from 'lodash/merge';

export function massRating(state: Object = {}, action: Object): Object {
    switch (action.type) {
        case 'MRT_SUBMITTED':
        // return merge({}, state, {data: action.data, type: action.type});
        case 'MRT_FAILED':
        // return merge({}, state, {data: action.data, type: action.type});
        case 'MRT_SUCCEED':
            return merge({}, state, { data: action.data, type: action.type });
        default:
            return merge({}, state);
    }
}
