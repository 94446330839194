import styled from 'styled-components';
import {FlexRow} from '../../StyledUtils';

export const TagsContainer = styled(FlexRow)`
  width: 100%;
  max-width: 900px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;
export const TagWrapper = styled.div`
  position: relative;
`;

export const Tag = styled(FlexRow)`
  margin: 9px;
  width: max-content;
  align-items: center;
  border-radius: 999px;
  padding: 4px 12px 4px 4px;
  background: ${({selectedItem}) => selectedItem ? `linear-gradient(93.75deg, #FF961B 0%, #FDB454 100%)` : `rgb(4 106 222)`};
  box-shadow: ${({selectedItem}) => selectedItem ? `0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802)` : `0px 0px 4px 0px rgba(0, 0, 0, 0.05) inset`};
`;

export const CircleIcon = styled.div`
  width: 16px;
  height: 16px;
  padding: 8px;
  margin-right: 8px;
  border-radius: 50%;
  opacity: ${({selectedItem}) => selectedItem ? '100%' : '75%'};
  background: ${({selectedItem}) => selectedItem ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.1)'} 
    url(${({selectedItem, iconPath, selectedIconPath}) => selectedItem ? selectedIconPath : iconPath}) 
    center center/auto no-repeat;
`;

export const TagName = styled.div`
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
`;
