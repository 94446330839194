// @flow
import styled from 'styled-components';
import ListItem from '@material-ui/core/ListItem';
import CheckIcon from '@material-ui/icons/Check';
import ListItemText from '@material-ui/core/ListItemText';

export const LangButton = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  margin: 6px 16px;
  min-width: 129px;
  height: 24px;
  padding: 4px;
  background: white;
  border-radius: 12px;
  color: #373B3E;
  border: 1px solid #DEDEDE;
  box-shadow: ${(props: Object): string => props.open ? '0 0 0.5px 1px #EFEFEF' : 'none'};
  &:hover {
    box-shadow: 0 0 0.5px 1px #EFEFEF;
  }
`;

export const MenuPaper = styled.div`
  width: 129px;
  margin-top: 4px;
  margin-left: 2px;
  border: 1px solid #DEDEDE;
  background-color: #fff;
  border-radius: 12px;
  box-shadow:0 0 0.5px 1px #EFEFEF;
  overflow: hidden;
`;

export const LangMenuItem = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #373B3E;
  font-size: 16px;
  height: 30px;
  padding: 6px 4px;
  &:hover {
    background: #F1F7FD;
  }
`;

export const MenuItemFlag = styled.img`
  border-radius: 15px;
  width: 16px;
  height: 16px;
`;

export const LangListItem = styled(ListItem)`
  text-decoration: none;
`;

export const LangListItemText = styled(ListItemText)`
  padding: 0 16px;
`;

export const LangListSubItem = styled(LangListItem)`
  cursor: pointer;
  background-color: #f6f6f6;
`;

export const ListItemFlag = styled(MenuItemFlag)`
  border-radius: 30px;
  width: 32px;
  height: 32px;
`;

export const ListItemFlagMargin = styled(ListItemFlag)`
  margin-left: 34px;
`;

export const ListText = styled(LangListItemText)`
  font-weight: ${(props: Object): string => props.isBold ? 'bold' : 'normal'};
`;

export const ListItemCheck = styled.div`
  align-self: flex-end;
  color: #057AFF;
  ${CheckIcon}: {
    height: 12px;
    width: 12px;
  }
`;

export const LanguageChooserText = styled.p`
  font-weight: ${(props: Object): string => props.isBold ? 'bold' : 'normal'};
  margin-left: 5px;
`;
