import styled from 'styled-components';
import {mobileBreakPoint, FlexColumn} from '../../StyledUtils';
import {ButtonContainer, Icon} from '../buttons/outlineRounded/StyledOutlineRoundedButton';

export const StyledInstallContainer = styled(FlexColumn)`
  width: 865px;
  padding-top: 126px;
  margin: auto;
  border-radius: 4px;
  align-items: center;
  @media (max-width: ${mobileBreakPoint}) {
    width: 100%;
    padding-top: 104px;
  }
  ${Icon}{
    display: none;
  }
  
  #wot-install-button {
      width: 219px;
      height: 48px;
      border-radius: 24px;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
      background-color: white;
      font-size: 14px;
      color: black;
      align-self: center;
      &:hover {
        background-color: #eaeaea;
      }
  };
`;

export const DescriptionContainer = styled.h2`
  margin: 0;
  width: 85%;
  text-align: center;
`;

export const Description = styled.span`
  color: #373B3E;
  font-size: 32px;
  font-weight: 600;
  line-height: 39px;
  text-align: center;
  font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
  @media (max-width: ${mobileBreakPoint}) {
    font-size: 22px;
    line-height: 27px;
  }
`;

export const DescriptionColored = styled(Description)`
  color: #037AFF;
  @media (max-width: ${mobileBreakPoint}) {
    font-size: 24px;
  }
`;

export const ButtonBlurContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  ${ButtonContainer}{
    bottom: 80px;
    position: absolute;
    background-color: white;
    transition: transform 0.1s ease-in-out;
    &:hover {
      transform: scale(1.1);
      box-shadow: 0px 6px 127px rgba(5, 122, 255, 0.25), 
        0px 2.50666px 53.0576px rgba(5, 122, 255, 0.179714), 
        0px 1.34018px 28.3671px rgba(5, 122, 255, 0.149027), 
        0px 0.751293px 15.9024px rgba(5, 122, 255, 0.125), 
        0px 0.399006px 8.44563px rgba(5, 122, 255, 0.100973), 
        0px 0.166035px 3.51442px rgba(5, 122, 255, 0.0702864);
    }
  }
`;

export const BlurBackground = styled.div`
  width: 500px;
  height: 200px;
  background: url(/images/bg-blur-blue.svg) center center/contain no-repeat;
`;
