// @flow
import { all, call, put, takeLatest } from 'redux-saga/effects';
import env from '../utils/env';
import sagaRequest from '../utils/sagaRequest';
import merge from 'lodash/merge';

function* requestUsercard(action: Object): Iterable<any> {
    yield call(sagaRequest, {
        apiRoot: env(['configuration', 'userApi']),
        apiPath: `/v3/user/profile/${action.data.uid}`,
        apiMethod: 'GET',
        success: 'USERCARD_ENDED',
        fail: 'USERCARD_FAILED',
        debug: true,
    });
}

function* updateUsercard(action: Object): Iterable<any> {
    yield call(sagaRequest, {
        apiRoot: env(['configuration', 'userApi']),
        apiPath: '/v2/user/profile',
        apiMethod: 'PUT',
        data: action.data,
        success: 'USERCARD_UPDATE_SUCCEED',
        fail: 'USERCARD_UPDATE_FAILED',
        debug: true,

    });
}

function* confirmEmail(action: Object): Iterable<any> {
    let { hash } = action.data;
    yield call(sagaRequest, {
        apiRoot: env(['configuration', 'userApi']),
        apiPath: `v2/user/profile/confirm_email/${hash}`,
        apiMethod: 'GET',
        success: 'EMAIL_CONFIRM_SUCCEED',
        fail: 'EMAIL_CONFIRM_FAILED',
        debug: false,
    });
}

function* resendEmail(action: Object): Iterable<any> {
    yield call(sagaRequest, {
        apiRoot: env(['configuration', 'authApi']),
        apiPath: `v3/emailVerification/resendEmail`,
        data: action.data,
        apiMethod: 'POST',
        success: 'RESEND_EMAIL_SUCCEED',
        fail: 'RESEND_EMAIL_FAILED',
        debug: false,
    });
}

function* updateAvatar(action: Object): Iterable<any> {
    yield call(sagaRequest, {
        apiRoot: env(['configuration', 'userApi']),
        apiPath: `/v2/user/avatar`,
        contentType: null,
        data: action.data,
        apiMethod: 'POST',
        success: 'AVATAR_UPDATE_SUCCEED',
        fail: 'AVATAR_UPDATE_FAILED',
        debug: true,
    });
}

function* requestRatings(action: Object): Iterable<any> {
    let { uid, page } = action.data;
    yield call(sagaRequest, {
        apiRoot: env(['configuration', 'scorecardApi']),
        apiPath: `v3/user/reviews/${uid}?page=${page || 0}`,
        apiMethod: 'GET',
        success: 'USERRATINGS_SUCCEED',
        fail: 'USERRATINGS_FAILED',
        onSuccess: function* (response: Object, result: Object, successMessage: string): Iterable<any> {
            if (result.data.length === 0 || result.data.length < 10) {
                yield put({
                    type: successMessage,
                    data: merge({}, result, { moreContent: false }),
                });
            } else {
                yield put({
                    type: successMessage,
                    data: merge({}, result, { moreContent: true }),
                });
            }
        },
    });
}

function* requestSites(action: Object): Iterable<any> {
    let { uid, page } = action.data;
    yield call(sagaRequest, {
        apiRoot: env(['configuration', 'userApi']),
        apiPath: `/v3/user/sites/${uid}?page=${page || 0}`,
        apiMethod: 'GET',
        success: 'USERSITES_SUCCEED',
        fail: 'USERSITES_FAILED',
        onSuccess: function* (response: Object, result: Object, successMessage: string): Iterable<any> {
            if (result.data.length === 0 || result.data.length < 10) {
                yield put({
                    type: successMessage,
                    data: merge({}, result, { moreContent: false }),
                });
            } else {
                yield put({
                    type: successMessage,
                    data: merge({}, result, { moreContent: true }),
                });
            }
        },
    });
}

function* deleteSite(action: Object): Iterable<any> {
    let domain = action.data.url;
    yield call(sagaRequest, {
        apiRoot: env(['configuration', 'userApi']),
        apiPath: `/v2/sites/${encodeURIComponent(domain)}`,
        apiMethod: 'DELETE',
        success: 'USERSITE_DELETE_SUCCEED',
        fail: 'USERSITE_DELETE_FAILED',
        onSuccess: function* (response: Object, result: Object, successMessage: string): Iterable<any> {
            yield put({
                type: successMessage,
                data: merge({}, result, {
                    url: domain,
                }),
            });
        },
    });
}

function* requestProfileSites(action: Object): Iterable<any> {
    yield all([call(requestUsercard, action), call(requestSites, action)]);
    yield put({ type: 'USERPROFILE_SITES_ENDED' });
}

function* requestProfileRatings(action: Object): Iterable<any> {
    yield all([call(requestUsercard, action), call(requestRatings, action)]);
    yield put({ type: 'USERPROFILE_RATINGS_ENDED' });
}

function* deleteProfile(action: Object): Iterable<any> {
    yield call(sagaRequest, {
        apiRoot: env(['configuration', 'userApi']),
        apiPath: `/v2/user/delete`,
        apiMethod: 'GET',
        success: 'ACCOUNT_DELETE_SUCCEED',
        fail: 'ACCOUNT_DELETE_FAILED',
    });
}

function* banUser(action: Object): Iterable<any> {
    yield call(sagaRequest, {
        apiRoot: env(['configuration', 'mywotUrl']),
        apiPath: `/user/${action.data}/banUser`,
        contentType: null,
        apiMethod: 'GET',
        success: 'BAN_USER_SUCCESS',
        fail: 'BAN_USER_FAILED',
        debug: true,
    });
}

function* usercardSaga(): Iterable<any> {
    yield takeLatest('USERCARD_REQUESTED', requestUsercard);
    yield takeLatest('USERCARD_UPDATE_REQUESTED', updateUsercard);
    yield takeLatest('RESEND_EMAIL_REQUESTED', resendEmail);
    yield takeLatest('EMAIL_CONFIRM_REQUESTED', confirmEmail);
    yield takeLatest('AVATAR_UPDATE_REQUESTED', updateAvatar);
    yield takeLatest('USERRATINGS_REQUESTED', requestRatings);
    yield takeLatest('USERSITES_REQUESTED', requestSites);
    yield takeLatest('USERPROFILE_SITES_REQUESTED', requestProfileSites);
    yield takeLatest('USERPROFILE_RATINGS_REQUESTED', requestProfileRatings);
    yield takeLatest('USERSITE_DELETE_REQUESTED', deleteSite);
    yield takeLatest('ACCOUNT_DELETE_REQUESTED', deleteProfile);
    yield takeLatest('USER_BAN_REQUESTED', banUser);
}

export default usercardSaga;
