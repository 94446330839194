// @flow
import styled from 'styled-components';
import {mobileBreakPoint, FlexRow} from '../../StyledUtils';
import Toolbar from '@material-ui/core/Toolbar';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px;
  padding: 8px;
  @media (max-width: ${mobileBreakPoint}) {
    margin: 16px 0px 0px 0px;
  }
`;

export const ToolbarContainer = styled(FlexRow)`
  height: 56px;
  width: 544px;
  border-radius: 4px;
  align-items: center;
  background-color: white;
  justify-content: space-between;
  box-shadow: 0 8px 16px 0 rgba(112, 112, 112, 0.16), 0 2px 4px 0 rgba(61, 61, 61, 0.04);
  @media (max-width: ${mobileBreakPoint}) {
    width: 100%;
  }
`;

export const StyledToolbar = styled(Toolbar)`
  padding-left: 0px;
`;

export const SearchButton = styled.div`
  display: flex;
  cursor: pointer;
  margin: 8px;
  align-items: center;
  justify-content: center;
  color: white;
  width: 120px;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  background-color: #037aff;
  &:hover {
    box-shadow: 0 0 8px 0 rgba(53, 90, 60, 0.3);
    background-color: #036bdf;
  }
  @media (max-width: ${mobileBreakPoint}) {
    width: auto;
    margin: 0px;
  }
`;

export const SearchIcon = styled.div`
  margin: 12px;
  width: 16px;
  height: 16px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/images/icons/search-grey.svg);  
  @media (max-width: ${mobileBreakPoint}) {
    margin: 9px;
  }
`;

export const SearchIconMobile = styled(SearchIcon)`
  background-image: url(/images/icons/search-white.svg);
`;
