// @flow
import * as React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import delay from 'lodash/delay';
import merge from 'lodash/merge';

import { applyMiddleware, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { toString, validateJSON, isStagingEnv } from '../utils';
import configureReducers from '../reducers';
import configureSagas from '../actions';
import auth from '../utils/auth';

let store;
class Default {
    store: Object;
    container: Object;
    layout: Function | React.ComponentType<any>;

    constructor(
        reducers: Array<string>,
        sagas: Array<any> = [],
        layout: Function | React.ComponentType<any>,
        container: Object | string = 'root'
    ) {
        Default.ASAPSyncTokenFromCookieToLocalstorage();
        this.store = Default.createStoreFromCache(reducers, sagas);
        this.gbSSRData = Default.getGrowthBookSSRDateFromCache();
        this.layout = layout;

        if (typeof container === 'object') {
            this.container = container;
        } else if (typeof container === 'string') {
            let _container = document.getElementById(container);

            if (!_container) {// TODO get rid of that
                _container = document.createElement('div');
                _container.id = container;

                (function init(container: Object) {
                    if (document.body) {
                        document.body.appendChild(container);
                    } else {
                        delay(init.bind(this, container), 100);
                    }
                })(_container);
            }

            this.container = _container;
        }

        store = this.store;
    }

    static ASAPSyncTokenFromCookieToLocalstorage() {
        const token = ((document.cookie || '').match(/JWT=([^;]+)/) || [])[1];
        if (token) {
            window.localStorage.setItem('JWT', token);
        }
    }

    static getGrowthBookSSRDateFromCache(): Object {
        const gbSSRData = window.__WOT__.gbSSRData;
        if (gbSSRData !== undefined) {
            delete window.__WOT__.gbSSRData;
        }
        return gbSSRData;
    }

    static createStoreFromCache(reducers: Array<string>, sagas: Array<string> = [], key: string = 'cache'): Object {
        reducers.push('auth');
        reducers.push('form');
        reducers.push('general');
        reducers.push('deviceInfo');
        sagas.push('authSaga');
        sagas.push('formsSaga');

        const cache = sessionStorage.getItem(key);


        let defaultState = validateJSON(cache) ? JSON.parse(toString(cache)) : {};
        defaultState = typeof defaultState !== 'object' ? {} : defaultState;
        if (window.__WOT__.user) {
            defaultState.auth = merge(defaultState.auth || {}, window.__WOT__.user);
            delete window.__WOT__.user;
        }
        if (window.__WOT__.otherUser) {
            defaultState.general = merge(defaultState.general || {}, {otherUser: window.__WOT__.otherUser});
            delete window.__WOT__.otherUser;
        }
        if (window.__WOT__.deviceInfo !== undefined) {
            defaultState.deviceInfo = merge(defaultState.deviceInfo || {}, window.__WOT__.deviceInfo);
            delete window.__WOT__.deviceInfo;
        }
        if (window.__WOT__.isExtensionInstalled !== undefined) {
            defaultState.general = merge(defaultState.general || {}, {isExtensionInstalled: window.__WOT__.isExtensionInstalled});
            delete window.__WOT__.isExtensionInstalled;
        }
        if (window.__WOT__.isMobile !== undefined) {
            defaultState.general = merge(defaultState.general || {}, {isMobile: window.__WOT__.isMobile});
            delete window.__WOT__.isMobile;
        }

        const reducer = configureReducers(reducers);

        const sagaMiddleware = createSagaMiddleware();

        const composeEnhancers = isStagingEnv() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

        const store = createStore(reducer, defaultState, composeEnhancers(applyMiddleware(sagaMiddleware)));
        // todo fix env logger in the bundle as well.
        // if (env('NODE_ENV') === 'production') {
        //    store = createStore(reducer, defaultState, applyMiddleware(sagaMiddleware));
        // } else {
        //     store = createStore(reducer, defaultState, applyMiddleware(sagaMiddleware, logger));
        // }

        for (let saga of configureSagas(sagas)) {
            sagaMiddleware.run(saga);
        }

        window.addEventListener('message', (event: Object) => {
            if (event && typeof event.data === 'object') {
                if (event.data.status === 'login') {
                    auth.setToken(event.data.token);
                    store.dispatch({ type: 'WOTUSER_DATA_REQUESTED', token: event.data.token });
                } else if (store.getState().auth.status === 'WOTUSER_TOKEN_REQUESTED') {
                    store.dispatch({ type: 'WOTUSER_TOKEN_FAILED', data: event.data });
                }
            }
        });

        return store;
    }

    render(container: ?Object): any {
        if (this.layout) {
            let Layout = this.layout;
            return hydrate(<Provider store={this.store}><Layout/></Provider>, container || this.container);
        }
    }
}

export const getAppState = (): Object => {
    if(store) {
        return store.getState()
    }
    return {};
}

export default Default;
