// @flow
import {validate, version} from 'uuid';
import {write} from './cookie';
import {COOKIE_MAXIMUM_LIFESPAN} from './const';

export function wotTraceIdLocalItem(): string {
    return window.localStorage.getItem('wotTraceId');
}

export function saveWotTraceIdLocalItem(wotTraceId: string) {
    window.localStorage.setItem('wotTraceId', wotTraceId);
}

export function saveWotTraceIdCookie(wotTraceId: string) {
    write('wotTraceId', wotTraceId, `;Path=/;Expires=${COOKIE_MAXIMUM_LIFESPAN}`);
}

export function wotTraceIdConnectLocalItem(): string {
    return window.localStorage.getItem('wotTraceIdConnect');
}

export function saveWotTraceIdConnectLocalItem(wotTraceIdConnect: boolean) {
    window.localStorage.setItem('wotTraceIdConnect', wotTraceIdConnect);
}

export function saveWotTraceIdConnectCookie(wotTraceIdConnect: boolean) {
    write('wotTraceIdConnect', wotTraceIdConnect, `;Path=/;Expires=${COOKIE_MAXIMUM_LIFESPAN}`);
}

export const dispatchWotTraceIdConnect = (wotTraceIdConnect: boolean, dispatch: Function) => {
    saveWotTraceIdConnectLocalItem(wotTraceIdConnect);
    saveWotTraceIdConnectCookie(wotTraceIdConnect);
    dispatch({ type: 'WOT_TRACE_ID', data: {wotTraceIdConnect}});
};

export const isValidWotTraceId = (wotTraceId: string): boolean => {
    return typeof wotTraceId === 'string' &&
        wotTraceId.startsWith('web-') &&
        validate(wotTraceId.substring(4)) &&
        version(wotTraceId.substring(4)) === 4;
};
