// @flow

import React, {useContext} from 'react';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import {LocalesContext} from '../../utils/locales';
import type {ILocalesTransformers} from '../../utils/locales';
import {Slide, Title, Description, CroppedImageContainer, ImageContainer, CarouselContainer} from './StyledCarouselOurTechnology';
import {SlideContainer} from '../Carousel/StyledCarousel';

export const CarouselOurTechnology = (): any => {
    const {translate}: ILocalesTransformers = useContext(LocalesContext);
    const [activeStep, setActiveStep] = React.useState(0);
    const slideDetails = [1, 2, 3, 4, 5, 6];
    const maxSteps = slideDetails.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep: number): any => prevActiveStep + 1);
    };

    const handleBack = (): any => {
        setActiveStep((prevActiveStep: number): any => prevActiveStep - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    return (
        <CarouselContainer>
            <MobileStepper
                steps={maxSteps}
                position="static"
                variant="dots"
                activeStep={activeStep}
                nextButton={
                    <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                        <KeyboardArrowRight />
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        <KeyboardArrowLeft />
                    </Button>
                }/>
            <SwipeableViews
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents >
                { slideDetails.map((slide: Object, index: number): any => (
                    <SlideContainer key={slide}>
                        { Math.abs(activeStep - index) <= 2 ? (
                            <Slide>
                                <Title>{translate(`pages.mobile.our-technology.desc.title.${index+1}`)}</Title>
                                <Description>{translate(`pages.mobile.our-technology.desc.subtitle.${index+1}`)}</Description>
                                <CroppedImageContainer>
                                    <ImageContainer src={`/images/mobile/mobileApp/mobile-app-${index+1}.jpg`}/>
                                </CroppedImageContainer>
                            </Slide>
                        ) : null }
                    </SlideContainer>
                ))}
            </SwipeableViews>
        </CarouselContainer>
    );
};
