// @flow
import * as React from 'react';
import { hydrate } from 'react-dom';
import Default from '../default';
import {getBasename, isStagingEnv} from '../../utils';
import ClientProvider from '../ClientProvider';
import Homepage from './Homepage';
import { AnalyticsProvider } from '../../components/AnalyticsProvider/AnalyticsProvider';

class HomeApp extends Default {
    constructor(reducers: Array<string>, sagas: Array<string>, layout: Function | React.ComponentType<any>) {
        super(reducers, sagas, layout);
    }

    render(container: ?Object): any {
        const basename = getBasename(location.pathname);
        return hydrate(
            <ClientProvider basename={basename} store={this.store} gbSSRData={this.gbSSRData}>
                <AnalyticsProvider initParams={{ platform: 'homepage' }}>
                    <Homepage/>
                </AnalyticsProvider>
            </ClientProvider>, container || this.container);
    }
}

let app = new HomeApp(['lastAction'], [], Homepage);

app.render();

if (isStagingEnv()) {
window.app = app;
}
