// @flow
import React, {useContext} from 'react';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';


import styles from './styles';
import type {ILocalesTransformers} from '../../../../utils/locales';
import {LocalesContext} from '../../../../utils/locales';

export const GoogleLogin = withStyles(styles, { withTheme: true })(({ classes, ...props }: Object): any => {
    const {translate}: ILocalesTransformers = useContext(LocalesContext);
    return (
        <Button
            variant={props.variant || 'contained'}
            color={props.color || 'secondary'}
            className={classnames(classes.socialButton, props.className)}
            fullWidth={props.fullWidth || true}
            {...props}
        >
            <img className={classes.socialIcon} src={'../../../../images/icons/socLinks/google-color.svg'}/>
            {props.text || translate('components.google-login-button.text')}
        </Button>
    );
});
