// @flow
import { isNode } from './isnode';
import pick from 'lodash/pick';
import { write, read } from '../utils/cookie';
import env from './env';


export function clearToken(key: ?string) {
    if (!isNode()) {
        localStorage.removeItem(key || 'JWT');
        const domain = location.hostname.replace('www', '');
        write(key || 'JWT', '', `;Path=/;Domain=${domain};Expires=Thu, 01 Jan 1970 00:00:01 GMT`);
        window.postMessage('wot-user-update', window.location.origin);
        window.postMessage({type: 'wot-user-update', token: ''}, window.location.origin);
    }
}

export function setToken(token: string, key: ?string) {
    if (!isNode()) {
        const domain = location.hostname.replace('www', '');
        localStorage.setItem(key || 'JWT', token);
        window.postMessage('wot-user-update', window.location.origin);
        window.postMessage({type: 'wot-user-update', token}, window.location.origin);
        write(key || 'JWT', token, `;Domain=${domain};Path=/`);
    }
}

export function getToken(key: ?string): any {
    return !isNode() && (localStorage.getItem(key || 'JWT') || read('JWT'));
}

export function isPower(auth: Object): boolean {
    if (auth && auth.roles) {
        const roles = pick(auth.roles, ['18', '22', '3']);

        return (Object.keys(roles).length > 0);
    } else {
        return false;
    }
}

export function isAdmin(auth: Object): boolean {
    if (auth && auth.roles) {
        const roles = pick(auth.roles, ['3']);

        return (Object.keys(roles).length > 0);
    } else {
        return false;
    }
}

export default { setToken, getToken, clearToken, isPower, isAdmin };
