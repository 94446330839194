// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import auth from '../utils/auth';
import { Route, withRouter } from 'react-router-dom';
import Login from '../blocks/modal/auth/login';
import Signup from '../blocks/modal/auth/signup';
import Forgot from '../blocks/modal/auth/forgot';

import TextMeTheApp from '../blocks/modal/textMe';

type State = {
    pathname: string,
}

function withDialogRouter(WrappedComponent: React.ComponentType<any>): Object {
    class AuthRouter extends React.PureComponent<Object, State> {
        constructor(props: Object) {
            super(props);

            this.state = {
                pathname: this.props.history.location.pathname,
            };
        }

        componentDidMount() {
            this.props.history.listen((location: Object, action: Object) => {
                this.setState({ pathname: location.pathname });
            });

            const { auth } = this.props;
            if (!(auth && auth.user)) {
                this.props.authorize();
            }
        }


        render(): any {
            let {
                authorize,
                textMeTheApp,
                forgot,
                failAcknowledged,
                history,
                auth,
                lastAction,
                form,
                socialAuthorize,
                ...other
            } = this.props;
            const forwardUrl = history.location.search.split('?forwardUrl=')[1];

            return (
                <React.Fragment>
                    <WrappedComponent{...other} history={history} />
                    <Route
                        location={history.location}
                        path="*/login"
                        render={(props: Object): any => {
                            return (
                                <Login
                                    open={true}
                                    {...props}
                                    action={authorize}
                                    social={socialAuthorize}
                                    acknowledge={failAcknowledged}
                                    auth={auth}
                                    forwardUrl={forwardUrl}
                                />);
                        }}/>
                    <Route
                        location={history.location}
                        path="*/signup"
                        render={(props: Object): any => {
                            return (
                                <Signup
                                    open={true}
                                    {...props}
                                    action={authorize}
                                    social={socialAuthorize}
                                    acknowledge={failAcknowledged}
                                    auth={auth}
                                    forwardUrl={forwardUrl}
                                />);
                        }}/>
                    <Route
                        location={history.location}
                        path="*/forgot"
                        render={(props: Object): any => {
                            return (
                                <Forgot
                                    open={true}
                                    {...props}
                                    action={forgot}
                                    acknowledge={failAcknowledged}
                                    auth={auth}
                                    lastAction={lastAction}
                                />);
                        }}/>
                    <Route
                        location={history.location}
                        path="*/textme"
                        render={(props: Object): any => {
                            return (
                                <TextMeTheApp
                                    open={true}
                                    {...props}
                                    action={textMeTheApp}
                                    form={form}
                                    acknowledge={failAcknowledged}
                                />);
                        }}/>
                </React.Fragment>
            );
        }
    }

    function mapStateToProps(state: Object): Object {
        return {
            lastAction: state.lastAction || {},
            auth: state.auth || {},
            form: state.form || {},
        };
    }

    function mapDispatchToProps(dispatch: Function): Object {
        return {
            authorize: (credentials: Object, create: boolean = false) => {
                if (credentials) {
                    dispatch({ type: 'WOTUSER_DATA_REQUESTED', create, credentials });
                } else if (auth.getToken()) {
                    dispatch({ type: 'WOTUSER_DATA_REQUESTED', create, token: auth.getToken() });
                } else {
                    // console.warn("User unauthorized")
                }
            },
            failAcknowledged: () => {
                dispatch({ type: 'FAIL_ACKNOWLEDGED' });
            },
            forgot: (email: ?string) => {
                dispatch({ type: 'FORGOT_PASS_REQUESTED', data: { email } });
            },
            socialAuthorize: (network: string) => {
                dispatch({ type: 'SOCIAL_AUTH_OPENED', network });
            },
            textMeTheApp: (number: string) => {
                dispatch({ type: 'TEXT_ME_THE_APP_REQUESTED', data: { number } });
            },
        };
    }

    return withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthRouter));
}

export default withDialogRouter;
