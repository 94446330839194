import styled from 'styled-components';
import {mobileBreakPoint, FlexColumn} from '../../StyledUtils';

export const StyledSectionHeader = styled(FlexColumn)`
  @media (max-width: ${mobileBreakPoint}) {
  }
`;

export const Icon = styled.div`
  width: 24px;
  height: 24px;
  margin: auto;
  border-radius: 50%;
  background: #FF961B url(${props => props.iconPath}) center center/auto no-repeat;
`;

export const Title = styled.div`
  width: 100%;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin: 12px 0px 64px 0px;
`;

export const Subtitle = styled.div`
  margin: 0;
  color: #FFFFFF;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
`;
