// @flow

import React, {Fragment} from 'react';
import {FiveFullStarsRow, FourAndAHalfStarsRow, FourFullStarsRow} from './StyledStars';

type IStarsRowProps = {
    starsValue: number;
}

export const StarsRow = ({starsValue}: IStarsRowProps): any => {
    return (
        <Fragment>
            { starsValue === 5 ? <FiveFullStarsRow/> : null }
            { starsValue === 4.5 ? <FourAndAHalfStarsRow/> : null }
            { starsValue === 4 ? <FourFullStarsRow/> : null }
        </Fragment>
    );
};
