import styled from 'styled-components';
import {FlexColumn, mobileBreakPoint} from '../../StyledUtils';

export const Container = styled(FlexColumn)`
  width: 270px;
  position: relative;
  align-items: center;
`;

export const Icon = styled.div`
  width: 263px;
  height: 250px;
  background: url(${props => props.iconPath}) center center/contain no-repeat;
`;

export const Wrapper = styled(FlexColumn)`
  top: 150px;
  align-items: center;
  position: absolute;
`;

export const Title = styled.div`
  color: #373B3E;
  font-size: 32px;
  font-weight: 600;
  line-height: 39px;
  text-align: center;
  font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
  width: ${({width}) => width ? width : 'auto'};
`;

export const Description = styled.div`
  color: #636363;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
`;
