import styled, {keyframes} from 'styled-components';
import {FlexColumn, FlexRow} from '../../StyledUtils';

export const StyledSliderContainer = styled(FlexColumn)`
  width: 100%;
  min-width: 300px;
  margin-top: 100px;
  align-items: center;
`;

export const SliderContent = styled(FlexRow)`
  width: 100%;
  margin-top: 96px;
  justify-content: space-evenly;
  align-items: center;
`;

export const VideosContainer = styled(FlexColumn)`
  position: relative;
`;

export const SelectedItemVideo = styled.div`
  position: ${({videoItem}) => videoItem === 0 ? 'initial' : 'absolute'};
  opacity: ${({isSelected}) => isSelected ? 1 : 0};
`;

export const StyledTextContainer = styled(FlexColumn)`
  max-width: 550px;
`;

export const TextContainer = styled(FlexRow)`
  width: 100%;
  padding: 15px;
  cursor: pointer;
`;

export const ItemIcon = styled.div`
  min-width: 22px;
  height: 22px;
  margin-right: 25px;
  background: url(${({isSelected, iconPath, selectedIconPath}) => isSelected ? iconPath : selectedIconPath}) center center/contain no-repeat;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  line-height: 23px;
  color: ${({isSelected}) => isSelected ? '#037AFF' : '#929CA5'};
`;

export const Description = styled.div`
  margin-top: 8px;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  line-height: 28px;
  color: ${({isSelected}) => isSelected ? '#383C40' : '#929CA5'};
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 2px;
  margin-top: 24px;
  background-color: #D3D9DE;
`;

const progress = keyframes`
  from { width: 0%; }
  to { width: 100%; }
`;


export const Progress = styled.div`
  display: ${({isSelected}) => isSelected ? 'flex' : 'none'};

  width: 100%;
  height: 100%;
  background-color: #037AFF;
  
  animation-name: ${progress};
  animation-duration: 7s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
`;
