import { useMediaQuery } from 'react-responsive';
import {mobileBreakPointInt, desktopBreakPointInt, tabletBreakPointFromInt, tabletBreakPointToInt} from '../StyledUtils';
import {useIsMount} from './useIsMount';
import {useDeviceInfo} from './useReduxState';

export const useDesktopQuery = (states) => {
    const isMount = states?.isMount ?? useIsMount();
    const { isMobile } = states ?? useDeviceInfo();
    const query = useMediaQuery({ minWidth: desktopBreakPointInt });
    return isMount ? query: !isMobile;
};

export const useTabletQuery = (states) => {
    const isMount = states?.isMount ?? useIsMount();
    const { isMobile } = states ?? useDeviceInfo();
    const query = useMediaQuery({ minWidth: tabletBreakPointToInt, maxWidth: tabletBreakPointFromInt });
    return isMount ? query: isMobile;
};

export const useMobileQuery = (states) => {
    const isMount = states?.isMount ?? useIsMount();
    const { isMobile } = states ?? useDeviceInfo();
    const query = useMediaQuery({ maxWidth: mobileBreakPointInt });
    return isMount ? query: isMobile;
};

export const useQueries = () => {
    const isMount = useIsMount();
    const { isMobile } = useDeviceInfo();
    return {
        isMobileQuery: useMobileQuery({ isMount, isMobile }),
        isTabletQuery: useTabletQuery({ isMount, isMobile }),
        isDesktopQuery: useDesktopQuery({ isMount, isMobile })
    };
};
