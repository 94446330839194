// @flow

import * as React from 'react';
import { Link, withRouter } from 'react-router-dom';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import AccountCircle from '@material-ui/icons/AccountCircle';
import VpnKey from '@material-ui/icons/VpnKey';
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import { GoogleLogin } from '../../../components/buttons/social/login/index';
import Close from '../../../components/buttons/close/index';
import { CommonInput } from '../../../components/inputs/index';
import styles from './styles';
import { isStagingEnv } from '../../../utils';
import {LocalesConsumer} from '../../../utils/locales';
import type {ILocalesTransformers} from '../../../utils/locales';

type Props = {
    acknowledge: Function,
    action: Function,
    auth: Object,
    classes: Object,
    fullScreen: boolean,
    history: Object,
    onClose: Function,
    open: boolean,
    social: Function,
    theme: Object,
    setLoginModalOpenState: Function,
    forwardUrl?: string
}

type Field = {
    message: ?string,
    valid: boolean,
    value: string
}

type State = {
    login: Field,
    open: boolean,
    password: Field,
}

class Login extends React.PureComponent<Props, State> {
    translate;

    constructor(props: Object) {
        super(props);

        this.state = {
            open: this.props.open || false,
            login: { value: '', valid: true, message: '' },
            password: { value: '', valid: true, message: '' },
        };

        if (this.props.auth.status && this.props.auth.token) {
            this.props.action('', false);
        }
    }

    open() {
        this.setState({ open: true });
    };

    close(value: ?any, isPending: Boolean) {
        if (isPending) {
return;
}
        this.setState({ open: false });
        this.props.history.push('.');
        setTimeout(() => {
            this.props.onClose && this.props.onClose(value);
        }, 300);
    };

    componentDidMount() {
        if (isStagingEnv()) {
            window.login = this;
        }

        const { setLoginModalOpenState } = this.props;
        setLoginModalOpenState(true);
    }

    componentDidUpdate() {
        let succeed = Boolean(this.props.auth.status && this.props.auth.status === 'WOTUSER_DATA_SUCCEED');

        if (succeed) {
            if (this.props.forwardUrl) {
                  window.location.href = this.props.forwardUrl;
            }
            this.close();
        }
    }

    validate(name: string, filter: Function): Object {
        let field = merge({}, this.state[name]);
        let result = filter(field.value);

        this.setState({ [name]: merge(field, { valid: result.status, message: result.message }) });
        return result;
    }

    fieldChange(field: string): Function {
        return (event: Object) => {
            let { auth, acknowledge } = this.props;
            let failed = Boolean(auth.status && auth.status.indexOf('FAILED') !== -1);

            failed && acknowledge && acknowledge();

            this.setState({ [field]: { value: event.target.value, valid: true, message: '' } });
        };
    }

    validateAll(): boolean {
        let fields = pick(this.state, ['login', 'password']);
        let general = true;

        for (let field in fields) {
            if (Object.prototype.hasOwnProperty.call(fields, field)) {
                let validator = this.validator(field);
                let result = this.validate(field, validator);

                fields[field].valid = result.status;
                fields[field].message = result.message || '';

                if (result.status !== true) {
                    general = false;
                }
            }
        }

        this.setState((state: State): Object => merge(state, fields));
        return general;
    }

    validator(field: string): Function {
        switch (field) {
            default:
                return (val: string): Object => {
                    return !val ? { status: false, message: this.translate('components.label.empty.field') } : { status: true, message: '' };
                };
        }
    }

    login() {
        if (this.validateAll()) {
            this.props.action({
                username: this.state.login.value,
                password: this.state.password.value,
                forwardUrl: this.props.forwardUrl
            }, false);
        }
    }

    render(): any {
        const { classes, fullScreen, auth, social, forwardUrl } = this.props;
        const { login, password } = this.state;

        let pending = Boolean(auth.status && auth.status.indexOf('REQUESTED') !== -1);
        let failed = Boolean(auth.status && auth.status.indexOf('FAILED') !== -1);
        let succeed = Boolean(auth.status && auth.status === 'WOTUSER_TOKEN_SUCCEED');

        return (
            <LocalesConsumer>
                {
                    ({translate}: ILocalesTransformers): React.Node => {
                        this.translate = translate;
                        return (
                            <Dialog
                                fullScreen={fullScreen}
                                open={this.state.open}
                                onClose={this.close.bind(this, null, pending)}
                                aria-labelledby={'Log in modal'}
                                classes={{ root: classes.root }}
                                onKeyDown={(event: Object) => {
                                    (event.key === 'Enter') && this.login();
                                }}
                                tabIndex="0"
                                PaperProps={{
                                    'data-automation': 'dialog-login-paper',
                                }}
                            >
                                <Close
                                    data-automation={'close-button-login'}
                                    onClick={this.close.bind(this)}
                                />
                                <div className={classes.title}>
                                    <img
                                        data-automation={'modal-logo-login'}
                                        src="/images/logo.png"
                                        alt="myWOT logo"
                                        className={classes.logo}/>
                                    <DialogTitle
                                        variant="title"
                                        data-automation={'desktop-login-modal-title'}
                                    >
                                        {translate('modals.login.login.below')}
                                    </DialogTitle>
                                </div>
                                {
                                    (pending || succeed) && (
                                        <div className={classes.loader}>
                                            <CircularProgress className={classes.progress} size={100}/>
                                        </div>
                                    )
                                }
                                <DialogContent className={classes.content}>
                                    <Grid
                                        container
                                        item
                                        spacing={2}
                                        className={classes.fields}
                                        component="form"
                                        validate="true"
                                    >
                                        <Grid
                                            item
                                            md={12}
                                            className={classes.socialContainer}
                                        >
                                            <GoogleLogin
                                                data-automation={'desktop-login-modal-social-google'}
                                                onClick={social.bind(null, 'google')}
                                            />
                                        </Grid>
                                        <Grid item md={12}>
                                            <Typography
                                                variant="subtitle1"
                                                className={classes.textDivider}
                                                data-automation={'desktop-login-modal-or'}
                                            >
                                                {translate('general.or')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CommonInput
                                                label={translate('modals.login.input.label')}
                                                placeholder={translate('modals.login.input.label')}
                                                autoComplete={'email'}
                                                error={failed || login.valid !== true}
                                                adornment={<AccountCircle/>}
                                                value={login.value}
                                                helperText={login.message}
                                                onChange={this.fieldChange('login')}
                                                onBlur={this.validate.bind(this, 'login', this.validator('login'))}
                                                inputClass={classes.modalInput}
                                                data-automation={'desktop-login-modal-email'}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CommonInput
                                                helperText={
                                                    <Link
                                                        to="/forgot"
                                                        className={classes.helper}
                                                    >
                                                        {translate('modals.login.forgot')}
                                                    </Link>
                                                }
                                                value={password.value}
                                                onChange={this.fieldChange('password')}
                                                onBlur={this.validate.bind(this, 'password', this.validator('password'))}
                                                type="password"
                                                error={failed || password.valid !== true}
                                                label={translate('general.password')}
                                                placeholder={translate('general.password')}
                                                autoComplete={'password'}
                                                adornment={<VpnKey/>}
                                                inputClass={classes.modalInput}
                                                data-automation={'desktop-login-modal-password'}
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions className={classes.actions}>
                                    <Button
                                        component={Link}
                                        to={`/signup${forwardUrl ? `?forwardUrl=${forwardUrl}`: ''}`}
                                        color="default"
                                        data-automation={'desktop-login-modal-signup-link'}
                                    >
                                        {translate('modals.login.create.account')}
                                    </Button>
                                    <Button
                                        onClick={this.login.bind(this)}
                                        color="primary"
                                        variant="contained"
                                        disabled={!password.valid || !login.value || !login.valid}
                                        id={'wot-login-button'}
                                        autoFocus
                                    >
                                        {translate('modals.login.login')}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        );
                    }
                }
            </LocalesConsumer>
        );
    }
}

function mapDispatchToProps(dispatch: Function): Object {
    return {
        setLoginModalOpenState: (isOpen: boolean) => {
            const type = isOpen ? 'LOGIN_MODAL_OPEN' : 'LOGIN_MODAL_CLOSE';
            dispatch({ type, data: isOpen });
        },
    };
}

export default withRouter(connect(null, mapDispatchToProps)(withMobileDialog()(withStyles(styles, { withTheme: true })(Login))));
