// @flow
const styles: Function = (theme: Object): Object => ({
    socialButton: {
        padding: 16,
    },
    socialIcon: {
        marginRight: 15,
    },
});

export default styles;
