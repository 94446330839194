// @flow
import * as React from 'react';
export type ITranslate = (key: string, params?: {}) => string;
export type IPluralTranslate = (count: number, keys: string[], params?: {}) => string;
export type IGetNumberLocale = (number: number, formatParam?: string) => string;
export type IGetDateLocale = (date: string | number | {}, formatParam?: string) => string;
export type ILocalesTransformers = {
    translate: ITranslate,
    pluralTranslate: IPluralTranslate,
    getNumberLocale: IGetNumberLocale,
    getDateLocale: IGetDateLocale
};

export const localesFactory = (locales): ILocalesTransformers => {
    function translate (key, params) {
        let res = locales.current[key] || locales._default[key] || key;
        if (params) {
            Object.keys(params).forEach((param) => res = res.replace(new RegExp(`%${param}%`, 'g'), params[param]));
        }
        return res;
    }

    return {
        translate,
        pluralTranslate: function(count, keys, params) {
            if (isNaN(count) || count < 0) {
                throw new TypeError('Parameter \'count\' is not a number!');
            }

            const key = count < keys.length ? keys[count] : keys[keys.length - 1];
            return translate(key, Object.assign({count}, params));
        }
    };
};


export const LocalesContext: any = React.createContext({});
export const LocalesProvider = LocalesContext.Provider;
export const LocalesConsumer = LocalesContext.Consumer;
