// @flow

import React from 'react';
import {StyledUserAvatar} from './StyledAvatar';
import ReactImageFallback from 'react-image-fallback';
import {defaultAvatarImageHref} from '../../utils/const';
import {merge} from 'lodash/object';

export type IAvatarProps = {
    href: string,
    name?: string,
    size?: string,
    customWidthAndHeight?: number
};

const Avatar = ({href, name = 'user_avatar', size, customWidthAndHeight}: IAvatarProps): any => {
    const backgeroundColors = [
        { backgroundColor: '#EEB010'},
        { backgroundColor: '#2DC8BE'},
        { backgroundColor: '#9480FF'},
        { backgroundColor: '#FF9AD5'},
        { backgroundColor: '#5AB9FE'}
    ];
    const customSize = customWidthAndHeight ? {
        height: customWidthAndHeight,
        width: customWidthAndHeight,
        'line-height': customWidthAndHeight
    } : {};

    const avatarStyle = href ? null : backgeroundColors[name.charCodeAt(0) % 5];

    return (
        <StyledUserAvatar style={merge({}, avatarStyle, customSize)} size={size}>
            {href ?
                <ReactImageFallback
                    src={href}
                    fallbackImage={defaultAvatarImageHref}
                    alt={name}
                    width={size || null}
                    height={size || null}
                />
                :
                <span>{name[0]}</span>
            }
        </StyledUserAvatar>
    );
};

export default Avatar;
