// @flow
import {mobileBreakPoint} from '../../StyledUtils';

const styles: Function = (theme: Object): Object => ({
    root: {
        minWidth: 320,
        minHeight: 104,
        overflow: 'hidden',
        flexGrow: 1,
        backgroundColor: '#ffffff',
        borderTop: '1px solid #ccc',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    container: {
        padding: '30px 0',
        maxWidth: theme.constants.MAX_WIDTH_LIMITED_LAYOUT,
        flexDirection: 'column',
        position: 'relative'
    },
    contentGroup: {
        height: 70,
        paddingLeft: 17,
        minWidth: 120,
    },
    languageSwitcher: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 5,
        width: 168,
        height: 40,
        borderRadius: 4,
        marginTop: 20,
        border: 'solid 1px #eaeaea',
        [`@media screen and (max-width: ${mobileBreakPoint})`]: {
            padding: 5,
            width: 343,
            margin: 12
        }
    },
    languageList: {
        fontFamily: `'Roboto', sans-serif`,
        width: 168,
        margin: '10px 0px',
        [`@media screen and (max-width: ${mobileBreakPoint})`]: {
            width: 343
        }
    },
    languageItem: {
        fontFamily: `'Roboto', sans-serif`,
    },
    socLinks: {
        marginTop: '80px',
        width: 240,
        display: 'flex',
        justifyContent: 'space-between',
        [`@media screen and (max-width: ${mobileBreakPoint})`]: {
            width: 265,
            margin: 12
        }
    },
    socIcon: {
        width: 24,
        height: 24,
        display: 'inline-block',
        cursor: 'pointer'
    },
    menuText: {
        fontFamily: `'Roboto', sans-serif`,
        fontSize: 16,
        lineHeight: '24px',
        margin: '10px 0px',
        color: '#252525'
    },
    menuLink: {
        color: '#000000',
        textDecoration: 'none',
    },
    installButton: {
        padding: 24,
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    bottomRowSeparator: {
        margin: '0 8.75px'
    }
});

export default styles;
