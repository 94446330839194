// @flow
import merge from 'lodash/merge';
import { put, call, takeLatest } from 'redux-saga/effects';
import env from '../utils/env';
import { timeout, getWotTraceId } from '../utils/';
import sagaRequest from '../utils/sagaRequest';
import auth from '../utils/auth';
import { write } from '../utils/cookie';

const MAX_TIME = 1000 * 60;

function* requestPassword(action: Object): Iterable<any> {
    try {
        let root = env(['configuration', 'userApi']);
        if (typeof root !== 'string' || root.length < 5) {
            yield put({ type: 'FORGOT_PASS_FAILED', data: { message: 'empty configuration for api' } });
        } else {
            const wotTraceId = getWotTraceId();

            let response = yield fetch(`${root}/v2/user/reset-password/step-1/send-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'wot-trace-id': wotTraceId
                },
                credentials: 'same-origin',
                body: JSON.stringify(action.data),
            });

            let result = yield (response && response.json());
            if (response && response.status === 200 && result && result.status) {
                yield put({ type: 'FORGOT_PASS_SUCCEED', data: result });
                return result.token;
            } else {
                yield put({ type: 'FORGOT_PASS_FAILED', data: result });
            }
        }
    } catch (err) {
        yield put({ type: 'FORGOT_PASS_FAILED', data: err });
    }
}

function* getToken(action: Object): Iterable<any> {
    try {
        let root = env(['configuration', 'authApi']);
        if (typeof root !== 'string' || root.length < 5) {
            yield put({ type: 'WOTUSER_TOKEN_FAILED', data: { message: 'empty configuration for api' } });
        } else {
            let path = action.create ? 'v3/signup' : 'token';
            const wotTraceId = getWotTraceId();

            let response = yield timeout(MAX_TIME, fetch(`${root}/${path}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'wot-trace-id': wotTraceId
                },
                credentials: 'same-origin',
                body: JSON.stringify(action.credentials),
            }));

            let result = yield (response && response.json());
            if (response && response.status === 200 && result && !result.status) {
                auth.setToken(result.token);
                yield put({ type: 'WOTUSER_TOKEN_SUCCEED', data: result });
                return result.token;
            } else {
                yield put({ type: 'WOTUSER_TOKEN_FAILED', data: result });
            }
        }
    } catch (err) {
        yield put({ type: 'WOTUSER_TOKEN_FAILED', data: err });
    }
}

function* getUserData(action: Object): Iterable<any> {
    try {
        let root = env(['configuration', 'userApi']);


        let token = action.token;

        if (typeof root !== 'string' || root.length < 5) {
            yield put({ type: 'WOTUSER_DATA_FAILED', data: { message: 'Empty configuration for api' } });
        } else {
            if (!token) {
                token = yield getToken(action);
            }

            if (!token) {
                yield put({ type: 'WOTUSER_DATA_FAILED', data: { message: 'User token is empty' } });
            } else {
                const wotTraceId = getWotTraceId();

                let response = yield timeout(MAX_TIME, fetch(`${root}/v3/user/profile/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + (token || ''),
                        'wot-trace-id': wotTraceId
                    }
                }));

                let result = yield (response && response.json());
                if (response && response.status === 200 && result) {
                    result.token = token;
                    write('uid', result.uid);
                    auth.setToken(result.token);
                    if (action.credentials && action.credentials.forwardUrl) {
                        location.href = action.credentials.forwardUrl;
                    } else {
                        yield put({ type: 'WOTUSER_DATA_SUCCEED', data: result });
                    }
                } else {
                    yield put({ type: 'WOTUSER_DATA_FAILED', data: result });
                    yield logout();
                }
            }
        }
    } catch (err) {
        yield put({ type: 'WOTUSER_DATA_FAILED', data: err });
        yield logout();
    }
}

function* invalidateToken(): Iterable<any> {
    try {
        const token = auth.getToken();
        if (!token) {
            return;
        }
        const wotTraceId = getWotTraceId();

        let root = env(['configuration', 'authApi']);
        const response = yield fetch(`${root}/logout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'wot-trace-id': wotTraceId
            },
        });
    } catch (error) {
        yield put({ type: 'WOTUSER_TOKEN_FAILED', data: error });
    };
}

function* logout(): Iterable<any> {
    yield invalidateToken();
    auth.clearToken();
    setTimeout(() => {
        location.pathname === '/' ? location.reload() : location.pathname = '/';
    });
}

function* socialLogin(action: Object): Iterable<any> {
    let root = env(['configuration', 'authApi']);
    if (typeof root !== 'string' || root.length < 5) {
        yield put({ type: 'WOTUSER_TOKEN_FAILED', data: { message: 'empty configuration for api' } });
    } else {
        window.open(`${root}/${action.network}?source=${location.origin}`, '_blank', 'width=800,height=600');
    }
}

function* authSaga(): Iterable<any> {
    yield takeLatest('WOTUSER_TOKEN_REQUESTED', getToken);
    yield takeLatest('WOTUSER_DATA_REQUESTED', getUserData);
    yield takeLatest('SOCIAL_AUTH_OPENED', socialLogin);
    yield takeLatest('FORGOT_PASS_REQUESTED', requestPassword);
    yield takeLatest('LOGOUT_REQUESTED', logout);
}

export default authSaga;
