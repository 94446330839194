// @flow
import { put, call, takeLatest } from 'redux-saga/effects';
import env from '../utils/env';
import sagaRequest from '../utils/sagaRequest';

function* requestRecentReviews(action: Object): Iterable<any> {
    let { limit, from } = action.data;
    try {
        yield call(sagaRequest, {
            apiRoot: env(['configuration', 'scorecardApi']),
            apiPath: `v3/reviews/recent?limit=${limit || 20}&from=${from || 0}`,
            apiMethod: 'GET',
            success: 'RECENT_REVIEWS_SUCCEED',
            fail: 'RECENT_REVIEWS_FAILED',
            mode: 'cors',
        });
    } catch (e) {
        console.log(e);
    }
}

function* recentReviewsSaga(): Iterable<any> {
    yield takeLatest('RECENT_REVIEWS_REQUESTED', requestRecentReviews);
}

export default recentReviewsSaga;
