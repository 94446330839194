// @flow
import * as React from 'react';

class SSR extends React.PureComponent<
    {
        isMount?: boolean,
        OnClient: React.Node,
        OnServer: React.Node,
    }, {
        isMount: boolean,
    }
> {
    constructor(props: Object) {
        super(props);

        this.state = {
            isMount: this.props.isMount || false,
        };
    }

    componentDidMount() {
        this.setState({ isMount: true });
    }

    render(): any {
        let { isMount, OnServer, OnClient } = this.props;
        if (isMount || this.state.isMount) {
            return OnClient;
        } else {
            return OnServer;
        }
    }
}

export default SSR;
