// @flow
import merge from 'lodash/merge';

export function deviceInfo(state: Object = {}, action: Object): Object {
    switch (action.type) {
        case 'IS_MOBILE':
            return merge({}, state, {isMobile: action.data});
        case 'IS_IOS':
            return merge({}, state, {isIos: action.data});
        case 'IS_ANDROID':
            return merge({}, state, {isAndroid: action.data});
        case 'BROWSER_NAME':
            return merge({}, state, {browserName: action.data});
        case 'IS_EXT_INSTALLED':
            return merge({}, state, {isExtInstalled: action.data});
        case 'IS_EXT_SUPPORTED':
            return merge({}, state, {isExtSupported: action.data});
        case 'WOT_TRACE_ID':
            return merge({}, state, action.data);
        case 'COOKIES_ACCEPTED':
            return merge({}, state, {shouldShowCookiesConsent: false});
        default:
            return merge({}, state);
    }
}
