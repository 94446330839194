import styled, {css} from 'styled-components';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import {FlexColumn, FlexRow, desktopMediaQuery, tabletMediaQuery, mobileMediaQuery} from '../../../StyledUtils';

const getHeroImagePath = ({isMobileQuery, isTabletQuery, isDesktopQuery, isIos}) => {
    const getDeviceType = () => {
        switch (true) {
            case isMobileQuery:
                return isIos ? 'iOS' : 'android';
            case isTabletQuery:
                return 'tablet';
            case isDesktopQuery:
                return 'desktop';
        }
    };
    const device = getDeviceType();
    return device && `/images/home/webHistory/${device}.svg`;
};

export const Hero = styled(FlexColumn)`
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    padding: 56px 100px 0;
    ${tabletMediaQuery(css`
        padding: 40px 0 0;
    `)}
    ${mobileMediaQuery(css`
        padding: 32px 20px 0;
    `)}
`;

export const DescriptionContent = styled(FlexColumn)`
    align-items: center;
    text-align: center;
`;

export const TopDescriptionSection = styled(FlexColumn)`
    align-items: inherit;
`;

export const ScoreRow = styled(FlexRow)`
    align-items: center;
    color: #929CA5;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
`;

export const Score = styled.span`
    margin-right: 4px;
    color: #373B3E;
    font-size: 14px;
`;

export const Star = styled(StarRoundedIcon)`
    margin-right: 8px;
    color: #FFD900;
    width: 12px;
    height: 12px;
`;

export const TopDescription = styled.h1`
    color: #057AFF;
    font-size: 16px;
    font-weight: 500;
    margin: 8px 0 0;
    letter-spacing: 3px;
    ${mobileMediaQuery(css`
        font-size: 14px;
    `)}
`;

export const Title = styled.span`
    color: #373B3E;
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    font-weight: 700;
    max-width: 724px;
    margin: 32px 0 0 0;
    font-size: 56px;
    line-height: 64px;
    ${tabletMediaQuery(css`
        max-width: 608px;
        font-size: 40px;
        line-height: 48px;
    `)}
    ${mobileMediaQuery(css`
        font-size: 28px;
        line-height: 36px;
        margin: 24px 0 0 0;
    `)}
`;

export const Subtitle = styled.span`
    color: #545454;
    font-weight: 400;
    margin-top: 24px;
    max-width: 724px;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 48px;
    ${tabletMediaQuery(css`
        max-width: 608px;
        font-size: 18px;
        line-height: 26px;
    `)}
    ${mobileMediaQuery(css`
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 40px;
    `)}
`;

export const Red = styled.span`
    color: #FF4747;
`;

export const Bold = styled.span`
    font-weight: 700;
`;

export const ConnectWithGoogleSection = styled(FlexColumn)`
    align-items: inherit;
    max-width: ${({limitWidth}) => limitWidth ? '255px': null};
`;

export const DeviceImageContainer = styled(FlexRow)`
    flex-direction: row-reverse;
    flex-grow: 1;
    padding-top: 48px;
    ${desktopMediaQuery(css`
        padding-top: 64px;
    `)}
`;

export const DeviceImage = styled.img.attrs((props) => ({
    src: getHeroImagePath(props) || null,
    alt: 'hero-device-image'
}))`
    pointer-events: none;
    ${desktopMediaQuery(css`
        padding-right: 40px;
        margin-top: -220px;
        margin-bottom: -210px;
    `)}
`;

export const LogosSection = styled(FlexColumn)`
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    padding: 56px 0 68px;
`;

export const LogosTitle = styled.span`
    color: #929CA5;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.8;
`;

export const LogosContainer = styled(FlexRow)`
    width: 100%;
    max-width: 1164px;
    box-sizing: border-box;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0 8px;
    ${tabletMediaQuery(css`
        max-width: 490px;
    `)}
    ${mobileMediaQuery(css`
        max-width: 350px;
    `)}
`;

export const Logo = styled.div`
    color: #D3D9DE;
    margin: 12px 12px 16px;
    height: 24px;
    width: ${({imgWidth}) => imgWidth.desktop}px;
    ${mobileMediaQuery(css`
        height: 16px;
        width: ${({imgWidth}) => imgWidth.mobile}px;
    `)}
    svg {
        height: 100%;
        width: 100%;
    }
`;
