import React from 'react';
import styled from 'styled-components';
import {FlexRow, FlexRowCentered} from '../../../StyledUtils';

const Anchor = styled.a.attrs({
  'data-automation': 'link-icon',
  'rel': 'noopener'
})`
  background-size: contain;
  background-repeat: no-repeat;
`;

export const GoogleLink = styled(Anchor)`
  background-image: url(/images/mobile/stores/store-badges_google_full_color.svg);
  width: 164.5px;
  height: 47px;
  margin-right: 6px;
  margin-bottom: 8px;
`;

export const AppleLink = styled(Anchor)`
  background-image: url(/images/mobile/stores/store-badges_apple_full.svg);
  width: 164.5px;
  height: 47px;
  margin-left: 6px;
`;

export const InstallButtonContainerRow = styled(FlexRow)`
  margin-top: 1px;
  justify-content: center;
  z-index: 2;
`;


export const CtaButton = styled(FlexRowCentered)`
  justify-content: center;
  height: 40px;
  background: #037AFF;
  border-radius: 999px;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
`;

export const CtaButtonText = styled.div`
  padding: 0 24px;
`;

export const GetPremiumButton = styled(CtaButton)`
  background: linear-gradient(135deg, #037AFF 0%, #03B3FF 100%);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.18), 0px 0px 2px rgba(0, 38, 80, 0.12), 0px 0px 1px rgba(0, 38, 80, 0.04);
  font-size: 20px;
  height: 72px;
`;

export const GetPremiumButtonArrow = styled.div`
  background: url(/images/icons/arrows/arrow-right-white.svg) no-repeat;
  width: 32px;
  height: 32px;
  margin: 12px 32px 0 0;
`;

export const GetPremiumButtonText = styled.div`
  padding: 0 15px 0 32px;
`;
