import styled from 'styled-components';
import {FlexRow, mobileBreakPoint} from '../../StyledUtils';

export const SupportedBrowsersContainer = styled(FlexRow)`
    width: 350px;
    align-items: center;
    justify-content: space-evenly;
`;

export const InstallSubtitle = styled.div`
  font-size: 16px;
  color: #808080;
  line-height: normal;
  text-align: center;
  
  @media (max-width: ${mobileBreakPoint}) {
    font-size: 14px;
    text-align: center;
    max-width: 280px;
  }
`;

export const BrowserIconsContainer = styled(FlexRow)`
  margin: 10px 8px;
  align-items: center;
`;

export const BrowserIcon = styled.a.attrs({
    alt: 'Browser icon',
})`
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-right: 4px;
    background-size: 24px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    filter: ${({iconsInColor}) => iconsInColor ? 'none' : 'grayscale(1)'};
    background-image: ${({imgPath}) => `url(/images/icons/browsers/${imgPath}-color.svg)`};
`;

export const BrowserName = styled.div`
    color: #808080;
    font-size: 12px;
    text-transform: capitalize;
`;
