// @flow
import React from 'react';
import {useDeviceInfo, useTranslate} from '../../../hooks';
import { FrameContent } from '../../../components/FrameContent/FrameContent';
import { FeatureCard } from '../../../components/FeatureCard/FeatureCard';
import { Slider } from '../../../components/Slider/Slider';
import { CarouselOurTechnology } from '../../../components/CarouselOurTechnology/CarouselOurTechnology';
import featureslideslist from './featureslideslist.json';
import { FeatureCardsContainer, FeatureSlidesContainer, FeaturesSectionContent, SectionTitle, StyledFeaturesContainer, Logo, LogoContainer, MobileBGContainer } from './StyledFeaturesSection';
import { InstallCard } from '../../../components/InstallCard/InstallCard';
import { getStoreLink, getUtmString } from '../../../utils';
import { REFERRAL_STORE_LINKS } from '../../../utils/const'


export const FeaturesSection = (): any => {
    const translate = useTranslate();
    const {isMobile, browserName} = useDeviceInfo();
    const btnLink = getStoreLink('homepage_features');
    const saferBrowsingMobileLink = REFERRAL_STORE_LINKS.SAFE_BROWSING;
    const malewareMobileLink = REFERRAL_STORE_LINKS.MALEWARE_ALERTS;
    const mobileProtectBrowsing = REFERRAL_STORE_LINKS.PROTECT_BROWSING;

    return (
        <StyledFeaturesContainer>
            <FrameContent>
                <FeaturesSectionContent>
                    <SectionTitle as={'h2'}>{translate('pages.home.features-section.title')}</SectionTitle>
                    <FeatureCardsContainer>
                        <FeatureCard
                            iconPath={'/images/icons/shieldsWithBlur/security-check-with-blur.svg'}
                            title={translate('pages.home.features-section.card1.title')}
                            description={translate('pages.home.features-section.card1.desc')}
                            buttonIconPath={'/images/icons/arrows/arrow-right-blue.svg'}
                            buttonIconPathHovered={'/images/icons/arrows/arrow-right-white.svg'}
                            buttonText={translate('pages.home.features-section.card.button')}
                            buttonLink={`https://www.mywot.com/website-safety-check?${getUtmString()}`}
                            trackingCategory={'Homepage'}
                            trackingAction={'Feature_card_button_Website_Check'}/>
                        <FeatureCard
                            iconPath={'/images/icons/shieldsWithBlur/safe-browsing-with-blur.svg'}
                            title={translate('pages.home.features-section.card2.title')}
                            description={translate('pages.home.features-section.card2.desc')}
                            buttonIconPath={'/images/icons/arrows/arrow-right-blue.svg'}
                            buttonIconPathHovered={'/images/icons/arrows/arrow-right-white.svg'}
                            buttonText={translate('pages.home.features-section.card.button')}
                            buttonLink={isMobile ? saferBrowsingMobileLink : btnLink}
                            trackingCategory={'Homepage'}
                            trackingAction={'Feature_card_button_Safe_Browsing'}/>
                        <FeatureCard
                            iconPath={'/images/icons/shieldsWithBlur/virus-alerts-with-blur.svg'}
                            title={translate('pages.home.features-section.card3.title')}
                            description={translate('pages.home.features-section.card3.desc')}
                            buttonIconPath={'/images/icons/arrows/arrow-right-blue.svg'}
                            buttonIconPathHovered={'/images/icons/arrows/arrow-right-white.svg'}
                            buttonText={translate('pages.home.features-section.card.button')}
                            buttonLink={isMobile ? malewareMobileLink : btnLink}
                            trackingCategory={'Homepage'}
                            trackingAction={'Feature_card_button_Malware_Alerts'}/>
                    </FeatureCardsContainer>
                    <FeatureSlidesContainer>
                    { isMobile ? <CarouselOurTechnology /> :
                        <Slider
                            slidesToMap={featureslideslist}
                            trackingCategory={'Homepage'}
                            trackingAction={'Features_slide_click'}
                            videoWidth={'500px'}
                            videoHeight={'500px'}
                            browserName={browserName}/>
                    }
                    </FeatureSlidesContainer>
                </FeaturesSectionContent>
            </FrameContent>
            <MobileBGContainer>
                <InstallCard
                    buttonText={translate('pages.home.protect.button')}
                    buttonLink={isMobile ? mobileProtectBrowsing : btnLink}
                    trackingCategory={'Homepage'}
                    trackingAction={'Features_button_click'} />
                <LogoContainer>
                    <Logo imgPath={`/images/logos/pc-world.svg`}
                          imgWidthMobile={'70px'} imgHeightMobile={'24px'}
                          imgWidthDesktop={'88px'} imgHeightDesktop={'30px'}/>
                    <Logo imgPath={`/images/logos/yahoo.svg`}
                          imgWidthMobile={'72px'} imgHeightMobile={'20px'}
                          imgWidthDesktop={'92px'} imgHeightDesktop={'26px'}/>
                    <Logo imgPath={`/images/logos/know-techie.svg`}
                          imgWidthMobile={'104px'} imgHeightMobile={'17px'}
                          imgWidthDesktop={'152px'} imgHeightDesktop={'25px'}/>
                    <Logo imgPath={`/images/logos/pc-magazine.svg`}
                          imgWidthMobile={'26px'} imgHeightMobile={'34px'}
                          imgWidthDesktop={'33px'} imgHeightDesktop={'49px'}/>
                    <Logo imgPath={`/images/logos/usa-today.svg`}
                          imgWidthMobile={'51px'} imgHeightMobile={'30px'}
                          imgWidthDesktop={'65px'} imgHeightDesktop={'37px'}/>
                    <Logo imgPath={`/images/logos/yandex.svg`}
                          imgWidthMobile={'58px'} imgHeightMobile={'23px'}
                          imgWidthDesktop={'74px'} imgHeightDesktop={'30px'}/>
                    <Logo imgPath={`/images/logos/cnbc.svg`}
                          imgWidthMobile={'47px'} imgHeightMobile={'35px'}
                          imgWidthDesktop={'60px'} imgHeightDesktop={'45px'}/>
                </LogoContainer>
            </MobileBGContainer>
        </StyledFeaturesContainer>
    );
};
