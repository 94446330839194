// @flow

import * as React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import VpnKey from '@material-ui/icons/VpnKey';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Lock from '@material-ui/icons/Lock';
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import Close from '../../../components/buttons/close/index';
import { GoogleLogin } from '../../../components/buttons/social/login/index';
import { CommonInput } from '../../../components/inputs/index';
import styles from './styles';
import pick from 'lodash/pick';
import merge from 'lodash/merge';

import env from '../../../utils/env';
import { isStagingEnv, isRecaptchaLoaded } from '../../../utils';
import { validateEmail, validatePassword } from '../../../utils/index';
import type {ILocalesTransformers, ITranslate} from '../../../utils/locales';
import {LocalesConsumer} from '../../../utils/locales';

type Props = {
    acknowledge: Function,
    action: Function,
    auth: Object,
    classes: Object,
    fullScreen: boolean,
    history: Object,
    onClose: Function,
    open: boolean,
    social: Function,
    theme: Object,
    setSignupModalOpenState: Function,
    forwardUrl?: string
}

type Field = {
    message: ?string,
    valid: boolean,
    value: string
}

type State = {
    confirmation: Field,
    login: Field,
    open: boolean,
    password: Field,
    showPassword: boolean,
}

class Signup extends React.PureComponent<Props, State> {
    translate;

    constructor(props: Object) {
        super(props);

        this.state = {
            open: this.props.open || false,
            login: { value: '', valid: true, message: '' },
            password: { value: '', valid: true, message: '' },
            confirmation: { value: '', valid: true, message: '' },
            showPassword: false,
        };

        if (this.props.auth.status && this.props.auth.token) {
            this.props.action('', false);
        }

        if (!isRecaptchaLoaded()) {
            const captchaScript = document.createElement('script');
            captchaScript.src = env(['configuration', 'captchaUrlPrefix']) + env(['configuration', 'captchaSitePublic']);
            document.head.appendChild(captchaScript);
        }
    }

    open() {
        this.setState({ open: true });
    };

    close(value: ?any) {
        this.setState({ open: false });
        this.props.history.push('.');
        setTimeout(() => {
            this.props.onClose && this.props.onClose(value);
        }, 400);
    };

    componentDidMount() {
        if (isStagingEnv()) {
            window.signup = this;
        }

        const { setSignupModalOpenState } = this.props;
        setSignupModalOpenState(true);
    }

    componentDidUpdate() {
        let succeed = Boolean(this.props.auth.status && this.props.auth.status === 'WOTUSER_DATA_SUCCEED');

        if (succeed) {
            if (this.props.forwardUrl) {
                  window.location.href = this.props.forwardUrl;
            } else if (window.location.href.indexOf('scorecard') === -1) {
                // In scorecard page we want to enable the user to see his comment after sign-up
                window.location.href = `/user/${this.props.auth.uid}/ratings`;
            }
            this.close();
        }
    }


    validate(name: string, filter: Function): Object {
        let field = merge({}, this.state[name]);
        let result = filter(field.value);

        this.setState({ [name]: merge(field, { valid: result.status, message: result.message }) });
        return result;
    }

    fieldChange(field: string): Function {
        return (event: Object) => {
            let { auth, acknowledge } = this.props;
            let failed = Boolean(auth.status && auth.status.indexOf('FAILED') !== -1);

            failed && acknowledge && acknowledge();

            this.setState({ [field]: { value: event.target.value, valid: true, message: '' } });
        };
    }

    validateAll(): boolean {
        let fields = pick(this.state, ['login', 'password', 'confirmation']);
        let general = true;

        for (let field in fields) {
            if (Object.prototype.hasOwnProperty.call(fields, field)) {
                let validator = this.validator(field);
                let result = this.validate(field, validator);

                fields[field].valid = result.status;
                fields[field].message = result.message || '';

                if (result.status !== true) {
                    general = false;
                }
            }
        }

        this.setState((state: State): Object => merge(state, fields));
        return general;
    }

    validator(field: string): Function {
        switch (field) {
            case 'confirmation':
                return (val: string): Object => {
                    if (!val) {
                        return { status: false, message: this.translate('components.label.empty.field') };
                    } else if (val !== this.state.password.value) {
                        return { status: false, message: this.translate('components.label.pass.mismatch') };
                    } else {
                        return { status: true, message: '' };
                    }
                };
            case 'password':
                return (val: string): Object => validatePassword(val, this.translate);
            case 'login':
            case 'email':
                return (val: string): Object => validateEmail(val, this.translate);
            default:
                return (val: string): Object => {
                    return !val ? { status: false, message: this.translate('components.label.empty.field') } : { status: true, message: '' };
                };
        }
    }

    signup() {
        if (this.validateAll()) {
            window.grecaptcha.ready(() => {
                console.log(env(['configuration', 'captchaSitePublic']));
                window.grecaptcha.execute(
                    env(['configuration', 'captchaSitePublic']),
                    { action: 'signup' },
                ).then((token: string) => {
                    this.props.action({
                        pass: this.state.password.value,
                        mail: this.state.login.value,
                        forwardUrl: this.props.forwardUrl,
                        grc: token,
                    }, true);
                }, (error: any) => {
                    console.error(error);
                });
            });
        }
    }

    handleMouseDownPassword(event: Object) {
        event.preventDefault();
    };

    togglePassword() {
        this.setState((state: State): Object => ({ showPassword: !state.showPassword }));
    };


    render(): any {
        const { classes, fullScreen, auth, social, forwardUrl } = this.props;
        const { login, password, confirmation } = this.state;


        let pending = Boolean(auth.status && auth.status.indexOf('REQUESTED') !== -1);
        let failed = Boolean(auth.status && auth.status.indexOf('FAILED') !== -1);
        let succeed = Boolean(auth.status && auth.status === 'WOTUSER_TOKEN_SUCCEED');

        return (
            <LocalesConsumer>
                {
                    ({translate}: ILocalesTransformers): React.Node => {
                        this.translate = translate;
                        return (
                            <Dialog
                                fullScreen={fullScreen}
                                open={this.state.open}
                                onClose={this.close.bind(this)}
                                aria-labelledby={'Sign up modal'}
                                classes={{ root: classes.root }}
                                onKeyDown={(event: Object) => {
                                    (event.key === 'Enter') && this.signup();
                                }}
                                tabIndex="0"
                                PaperProps={{
                                    'data-automation': 'dialog-signup-paper',
                                }}
                            >
                                <Close
                                    data-automation={'close-button-signup'}
                                    onClick={this.close.bind(this)}
                                />
                                <div className={classes.title}>
                                    <img
                                        src="/images/logo.png"
                                        alt="myWOT logo"
                                        className={classes.logo}
                                        data-automation={'desktop-signup-modal-logo'}
                                    />
                                    <DialogTitle
                                        variant="title"
                                        data-automation={'desktop-signup-modal-title'}
                                    >
                                        {translate('modals.signup.signup.below')}
                                    </DialogTitle>
                                </div>
                                {
                                    (pending || succeed) && (
                                        <div className={classes.loader}>
                                            <CircularProgress className={classes.progress} size={100}/>
                                        </div>
                                    )
                                }
                                <DialogContent className={classes.content}>
                                    <Grid
                                        container
                                        item
                                        spacing={2}
                                        className={classes.fields}
                                        component="form"
                                        validate="true"
                                        autoComplete="off"
                                    >
                                        <Grid
                                            item
                                            md={12}
                                            className={classes.socialContainer}
                                        >
                                            <GoogleLogin
                                                text={translate('modals.signup.google')}
                                                onClick={social.bind(null, 'google')}
                                                data-automation={'desktop-signup-modal-social-google'}
                                            />
                                        </Grid>
                                        <Grid item md={12}>
                                            <Typography
                                                variant="subheading"
                                                className={classes.textDivider}
                                                data-automation={'desktop-signup-modal-or'}
                                            >
                                                {translate('general.or')}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <CommonInput
                                                label={translate('general.email')}
                                                placeholder={translate('general.email')}
                                                adornment={<AccountCircle/>}
                                                error={failed || login.valid !== true}
                                                value={login.value}
                                                helperText={login.message}
                                                onChange={this.fieldChange('login')}
                                                onBlur={this.validate.bind(this, 'login', this.validator('email'))}
                                                inputClass={classes.modalInput}
                                                data-automation={'desktop-signup-modal-email'}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <CommonInput
                                                value={password.value}
                                                helperText={password.message}
                                                onChange={this.fieldChange('password')}
                                                onBlur={this.validate.bind(this, 'password', this.validator('password'))}
                                                type={this.state.showPassword ? 'text' : 'password'}
                                                error={failed || password.valid !== true}
                                                label={translate('general.password')}
                                                placeholder={translate('general.password')}
                                                adornment={<VpnKey/>}
                                                inputClass={classes.modalInput}
                                                data-automation={'desktop-signup-modal-password'}
                                                endAdornment={
                                                    <IconButton
                                                        aria-label="Toggle password visibility"
                                                        onClick={this.togglePassword.bind(this)}
                                                        onMouseDown={this.handleMouseDownPassword.bind(this)}
                                                    >
                                                        {this.state.showPassword ? <VisibilityOff/> : <Visibility/>}
                                                    </IconButton>
                                                }
                                            />
                                        </Grid>
                                        <Grid item>
                                            <CommonInput
                                                value={confirmation.value}
                                                helperText={confirmation.message}
                                                onChange={this.fieldChange('confirmation')}
                                                onBlur={this.validate.bind(this, 'confirmation', this.validator('confirmation'))}
                                                type="password"
                                                error={failed || confirmation.valid !== true}
                                                label={translate('modals.signup.confirm.password')}
                                                placeholder={translate('modals.signup.confirm.password')}
                                                adornment={<Lock/>}
                                                inputClass={classes.modalInput}
                                                data-automation={'desktop-signup-modal-confirm'}
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions className={classes.actions}>
                                    <Button
                                        component={Link}
                                        to={`/login${forwardUrl ? `?forwardUrl=${forwardUrl}`: ''}`}
                                        color="default"
                                        data-automation={'desktop-signup-modal-action-sub'}
                                    >
                                        {translate('modals.login.login')}
                                    </Button>
                                    <Button
                                        onClick={this.signup.bind(this)}
                                        color="primary"
                                        variant="contained"
                                        disabled={!confirmation.valid || !password.valid || !login.value || !login.valid}
                                        data-automation={'desktop-signup-modal-action-main'}
                                        id={'wot-signup-button'}
                                    >
                                        {translate('modals.login.create.account')}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        );
                    }
                }
            </LocalesConsumer>
        );
    }
}
function mapDispatchToProps(dispatch: Function): Object {
    return {
        setSignupModalOpenState: (isOpen: boolean) => {
            const type = isOpen ? 'SIGNUP_MODAL_OPEN' : 'SIGNUP_MODAL_CLOSE';
            dispatch({ type, data: isOpen });
        },
    };
}

export default withRouter(connect(null, mapDispatchToProps)(withMobileDialog()(withStyles(styles, { withTheme: true })(Signup))));
