// @flow
import React from 'react';
import {Container, Icon, Title, Description, Wrapper} from './StyledFeatureCard';
import {OutlineRoundedButton} from '../buttons/outlineRounded/OutlineRoundedButton';

type FeatureCardProps = {
    title: string,
    width?: string,
    iconPath: string,
    hideButton?: boolean,
    description: string,
    buttonText?: string,
    buttonLink?: string,
    buttonIconPath?: string,
    trackingCategory?: string,
    trackingAction?: string
}

export const FeatureCard = (props: FeatureCardProps): any => {
    const { hideButton, width, iconPath, title, description, buttonText, buttonLink, buttonIconPath, trackingCategory, trackingAction } = props;

    return (
        <Container>
            <Icon iconPath={iconPath} />
            <Wrapper>
                <Title width={width}>{title}</Title>
                <Description>{description}</Description>
                { hideButton ? null :
                    <OutlineRoundedButton
                        buttonText={buttonText}
                        buttonLink={buttonLink}
                        buttonIconPath={buttonIconPath}
                        trackingCategory={trackingCategory}
                        trackingAction={trackingAction}/> }
            </Wrapper>
        </Container>
    );
};
