// @flow

import { put } from 'redux-saga/effects';
import auth from './auth';
import { getWotTraceId } from './index';

type SagaRequest = {
    apiMethod: string,
    apiPath: string,
    apiRoot: string,
    data?: Object,
    contentType?: string | null,
    ifSuccess?: Function,
    mode?: "cors" | "no-cors" | "same-origin",
    fail: string,
    onFail?: Function,
    onSuccess?: Function,
    afterSuccess?: Function,
    success: string,
    debug?: boolean,
}

export default function* (sagaRequest: SagaRequest): Generator<any, any, any> {
    const {
        apiRoot,
        apiPath,
        apiMethod,
        data,
        contentType,
        ifSuccess,
        success,
        fail,
        onSuccess,
        afterSuccess,
        onFail,
        mode,
        debug,
    } = sagaRequest;
    try {
        if (typeof apiRoot !== 'string' || apiRoot.length < 5) {
            yield put({ type: fail, data: { message: 'empty configuration for api' } });
        } else {
            let url = (`${apiRoot}/${apiPath}`).replace(/(\w)\/\//, '$1/');
            let token = (auth.getToken() || '');
            debug && console.log('REQUEST TOKEN: ' + token);
            const wotTraceId = getWotTraceId();

            let fetchOptions = {
                mode: mode || 'cors',
                method: apiMethod,
                headers: {
                    'Content-Type': contentType || 'application/json; charset=utf-8',
                    'Authorization': token ? `Bearer ${token}` : '',
                    'wot-trace-id': wotTraceId
                },
                credentials: 'same-origin',
                body: data ? JSON.stringify(data) : null,
            };
            if (contentType === null) {
                delete fetchOptions.headers['Content-Type'];
                fetchOptions.body = data ? data : null;
            }

            debug && console.log('REQUEST URL: ' + url);
            let response = yield fetch(url, fetchOptions);
            debug && console.log('RESPONSE: ' + response);

            let result = yield (response && (response.status === 200 ? response.json() : response.text()));
            if (
                response && response.status === 200
                && result
                && (ifSuccess ? ifSuccess(result) : true)
            ) {
                debug && console.log('SUCCESS: ' + result);
                if (onSuccess) {
                    yield onSuccess(response, result, success);
                } else {
                    afterSuccess && afterSuccess();
                    yield put({ type: success, data: result });
                }
            } else {
                debug && console.log('FAIL: ' + result);
                onFail ?
                    yield onFail(response, result, fail)
                    :
                    yield put({ type: fail, data: { status: response && response.status, message: result } });
            }

            return result;
        }
    } catch (err) {
        console.error(err);
        yield put({ type: fail, data: { status: '', message: err } });
    }
}
