import styled from 'styled-components';
import {FlexRow, mobileBreakPoint} from '../../StyledUtils';

export const StyledFrameContentContainer = styled(FlexRow)`
  justify-content: center;
  width: 100%;
`;

export const StyledFrameContent = styled.div`
  margin: 0 18px;
  max-width: 1164px;
  min-width: 300px;
  width: 100%;
  
  @media (max-width: ${mobileBreakPoint}) {
    margin: 0 12px;
  }
`;
