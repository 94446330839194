import styled, {css} from 'styled-components';
import {FlexRow} from '../../../StyledUtils';
import {iconsPath} from '../../../utils/const';

const Anchor = styled.a.attrs({
    'data-automation': 'link-icon',
    'rel': 'noopener'
})`
  background-size: contain;
  background-repeat: no-repeat;
`;

export const GoogleLink = styled(Anchor)`
  background-image: url(/images/mobile/stores/store-badges_google_full_color.svg);
  width: 164.5px;
  height: 47px;
  margin-right: 6px;
  margin-bottom: 8px;
`;

export const AppleLink = styled(Anchor)`
  background-image: url(/images/mobile/stores/store-badges_apple_full.svg);
  width: 164.5px;
  height: 47px;
  margin-left: 6px;
`;

export const MobileButton = styled(FlexRow)`
  justify-content: center;
  z-index: 2;
`;

const browserIconCss = (browserName, color) => !browserName ? '' : css`
    ${BrowserIcon} {
        background-image: url("${iconsPath}/browsers/${browserName}-${color}.svg");
    };
`;

const sharedStyle = (styleSheet = {}, browserName) => css`
    padding: ${styleSheet.padding || '12px 24px'};
    margin: ${styleSheet.margin || 0};
    box-shadow: ${styleSheet.withBoxShadow && '0 1px 8px 0 rgba(0,0,0,0.2)'};
    font-size: ${styleSheet.fontSize || 18}px;
    font-weight: ${styleSheet.fontWeight || 700};
    ${!browserName ? '' : css`
        ${BrowserIcon} {
            margin-right: 8px;
            height: ${styleSheet.iconSize || 20}px;
            width: ${styleSheet.iconSize || 20}px;
            background-size: contain;
            background-repeat: no-repeat;
        }
    `};
`;

const outlinedStyle = ({styleSheet, browserName}) => css`
    ${sharedStyle(styleSheet, browserName)};
    color: #057AFF;
    background: white;
    ${browserIconCss(browserName, 'blue')}
    &:hover {
        color: white;
        background: #057AFF;
        ${browserIconCss(browserName, 'white')}
    }
`;

const defaultStyle = ({styleSheet, browserName}) => css`
    ${sharedStyle(styleSheet, browserName)};
    color: white;
    background: #057AFF;
    ${browserIconCss(browserName, 'color')}
    &:hover {
        background: #036BDF;
    }
`;

export const DesktopButton = styled(FlexRow)`
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    border: 2px solid #057AFF;
    transition: .2s linear all;
    border-radius: 99px;
    font-style: normal;
    text-transform: initial;
    ${({browserName, outLined, styleSheet}) => outLined ? outlinedStyle({styleSheet, browserName}): defaultStyle({styleSheet, browserName})}
`;

export const BrowserIcon = styled.div``;

