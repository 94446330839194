// @flow
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import merge from 'lodash/merge';

import Button from '@material-ui/core/Button';
import { BROWSER_STORES_LINKS, GOOGLE_PLAY_STORE } from '../../../utils/const.js';
import withBrowserInfo from '../../../HOCs/withBrowserInfo.js';
import isExtInstalled from '../../../HOCs/isExtInstalled.js';
import isMobile from '../../../HOCs/isMobile.js';
import { isNode } from '../../../utils/isnode';
import { getUtmString } from '../../../utils';


import styles from './styles';
import {LocalesConsumer} from '../../../utils/locales';
import type {ILocalesTransformers} from '../../../utils/locales';

export type Props = {
    browserInfo: Object,
    classes: Object,
    className: ?string,
    huge: ?boolean,
    isExtInstalled: boolean,
    isMobile: boolean,
    round: boolean,
    bigButton: boolean,
    hideIcon: ?boolean,
    color: ?string,
    dataAutomation: ?string,
    capitalize: ?boolean,
    text: ?string,
    onButtonClick: ?Function
}

type State = {
    isMount: boolean,
}
const googlePlayStore = GOOGLE_PLAY_STORE.replace('utmCampaign', 'MainPage');
class BrowserInstallButton extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isMount: false,
        };
    }

    componentDidMount() {
        this.setState({ isMount: true });
        this.utm = getUtmString();
    }

    getButtonInfo(): Object {
        const { classes, browserInfo, isExtInstalled, isMobile, round, bigButton, capitalize} = this.props;
        const { isMount } = this.state;
        const lowerCasedBrowserName = browserInfo.name.toLowerCase();
        const capitalizedBrowserName = lowerCasedBrowserName.charAt(0).toUpperCase() + lowerCasedBrowserName.substring(1);
        const browserName = capitalize ? capitalizedBrowserName : lowerCasedBrowserName;
        let info = {
            text: 'components.install-button.wot',
            link: `${BROWSER_STORES_LINKS.chrome}?${this.utm}`,
            icon: '/images/icons/browsers/chrome-white.svg',
            className: classes.container,
            variant: 'contained',
            color: 'primary',
            gaLabel: `${isMobile ? 'Mobile' : 'Desktop'} ${lowerCasedBrowserName}`,
        };
        const browserLink = `/download?${this.utm}`;
        switch (true) {
            case (isNode()):
            case (!isMount):
                return merge({}, info);
            case (!bigButton && round && !isExtInstalled):
                return merge({}, info, {
                    text: 'components.install-button.add.to',
                    browserName,
                    link: browserLink,
                    className: classes.roundButton,
                    icon: `/images/icons/browsers/${lowerCasedBrowserName}-color.svg`,
                });
            case (!bigButton && !round && !isExtInstalled):
                return merge({}, info, {
                    text: 'components.install-button.add.to',
                    browserName,
                    link: browserLink,
                    icon: `/images/icons/browsers/${lowerCasedBrowserName}-color.svg`,
                });
            case (bigButton && round && !isExtInstalled):
                return merge({}, info, {
                    text: 'components.floating.add.wot.ext.cta',
                    browserName,
                    link: browserLink,
                    className: classes.roundButton,
                    icon: `/images/icons/browsers/${lowerCasedBrowserName}-color.svg`,
                });
            case (bigButton && !round && !isExtInstalled):
                return merge({}, info, {
                    text: 'components.floating.add.wot.ext.cta',
                    browserName,
                    link: browserLink,
                    icon: `/images/icons/browsers/${lowerCasedBrowserName}-color.svg`,
                });
            case (!round && isExtInstalled):
                return merge({}, info, {
                    text: 'components.install-button.android',
                    link: googlePlayStore,
                    className: classes.long,
                    icon: `/images/icons/browsers/android-color.svg`,
                });
            case (round && isExtInstalled):
                return merge({}, info, {
                    text: '',
                    link: googlePlayStore,
                    icon: '',
                    className: classes.containerGooglePlay,
                    variant: 'text',
                    color: 'default',
                });
            default:
                return {};
        }
    }


    render(): any {
        const {
            classes,
            className,
            huge,
            text,
            browserInfo,
            isExtInstalled,
            isMobile,
            round,
            bigButton,
            hideIcon,
            color,
            dataAutomation,
            capitalize,
            onButtonClick,
            ...other
        } = this.props;
        const { isMount } = this.state;
        const info = this.getButtonInfo();

        return (
            <LocalesConsumer>
                {
                    ({translate}: ILocalesTransformers): React.Node => (
                        <Button
                            onClick={onButtonClick}
                            variant={info.variant}
                            color={color || info.color}
                            className={classNames(className, huge
                                ? classes.huge
                                : '', !isMount ? classes.invisible : '', capitalize ? classes.capitalize: '', classes.button, info.className)}
                            size={'large'}
                            component={'a'}
                            href={info.link}
                            classes={{ label: classes.label }}
                            id={'wot-install-button'}
                            data-ga-label={isMount && info.gaLabel}
                            data-automation={dataAutomation || 'download-button'}
                            rel='noopener'
                            {...other}
                        >
                            {info.icon && !hideIcon ? <img src={info.icon} className={classes.icon} alt={`add-to-${info.browserName}`} /> : <i/>}
                            {capitalize ?
                                (translate(text || info.text).charAt(0).toLocaleUpperCase() +
                                    translate(text || info.text).substring(1).toLocaleLowerCase()
                                        .replace('%browsername%', info.browserName)
                                ):
                                translate(text || info.text, { browserName: info.browserName })
                            }
                        </Button>
                    )
                }
            </LocalesConsumer>
        );
    }
}

export default isMobile(isExtInstalled(withBrowserInfo(withStyles(styles)(BrowserInstallButton))));
