// @flow
import React from 'react';
import {Avatar, UserReviewCardContainer, UserReview, UserDetailsContainer, UserDetail} from './StyledUserReviewCard';
import {StarsRow} from '../Stars/StarsRow';

type IReviewsSectionProps = {
    avatarNo: number;
    starsValue: number;
    userReview: string;
    userName: string;
    reviewDate: string
}

export const UserReviewCard = ({avatarNo, starsValue, userReview, userName, reviewDate}: IReviewsSectionProps): any => {
    return (
        <UserReviewCardContainer>
            <Avatar avatarNo={avatarNo}/>
            <StarsRow starsValue={starsValue}/>
            <UserReview>{userReview}</UserReview>
            <UserDetailsContainer>
                <UserDetail>{userName}</UserDetail>
                <UserDetail>{reviewDate}</UserDetail>
            </UserDetailsContainer>
        </UserReviewCardContainer>
    );
};
