/* eslint-disable */
import env from '../utils/env';

export const CLOSE_DISCLAIMER_KEY = 'cookies_consent_disclaimer';
export const CLOSE_DISCLAIMER_VALUE = 'closed';
export const URLS_WITH_NO_COOKIES_CONSENT = ['welcome'];
export const EXTENSION_INSTALLED_COOKIE = 'ExtensionInstalled';

export const CLOSED_KEY_PREMIUM = 'add_wot_premium_close';
export const CLOSED_KEY_NOTIFICATION = 'reviews_notification_close';
export const DESKTOP = 'desktop';
export const EXTENSION = 'extension';
export const MOBILE = 'mobile';
export const NUMBER_OF_VISIBLE_REVIEWS = 5;

export const WOT_PRODUCT = 'wot_product';

export const BROWSER_STORES_LINKS = {
    'chrome': 'https://chromewebstore.google.com/detail/wot-website-security-safe/bhmmomiinigofkjcapegjjndpbikblnp',
    'yandex': 'https://chromewebstore.google.com/detail/wot-website-security-safe/bhmmomiinigofkjcapegjjndpbikblnp',
    'brave': 'https://chromewebstore.google.com/detail/wot-website-security-safe/bhmmomiinigofkjcapegjjndpbikblnp',
    'firefox': 'https://addons.mozilla.org/firefox/addon/wot-safe-browsing-tool/',
    'opera': 'https://addons.opera.com/extensions/details/wot/',
    'safari': 'https://apps.apple.com/app/wot-safe-browsing/id1557101771',
    'edge': 'https://microsoftedge.microsoft.com/addons/detail/iiclaphjclecagpkkaacljnpcppnoibi',
    'samsung': 'https://galaxy.store/wot',
    'android': `https://play.google.com/store/apps/details?id=com.wot.security&referrer=${ WOT_PRODUCT }%3DWebsite`,
    'iOS': 'https://apps.apple.com/app/wot-mobile-security-protection/id1451498125'
};

const REF_BASE_LINK = 'https://wotemail.onelink.me';
export const REFERRAL_STORE_LINKS = {
    SAFE_BROWSING: `${REF_BASE_LINK}/teed/SaferBrowsing`,
    MALEWARE_ALERTS: `${REF_BASE_LINK}/teed/MalwareAlerts`,
    PROTECT_BROWSING: `${REF_BASE_LINK}/teed/ProtectBrowsing`,
    FREE_TRIAL: `${REF_BASE_LINK}/teed/FreeTrial`,
    BOOTOM_HP: `${REF_BASE_LINK}/teed/BottomHP`,
    DESKTOP_ATF: `${REF_BASE_LINK}/teed/DesktopATF`,
    DESKTOP_BTF: `${REF_BASE_LINK}/teed/DesktopBTF`,
    MOBILE_ATF: `${REF_BASE_LINK}/teed/MobileATF`,
    MOBILE_BTF: `${REF_BASE_LINK}/teed/MobileBTF`
}

export const EXTENSION_PREMIUM_PURCHASE_URL = `${env(['configuration', 'mywotAppUrl'])}/choosePlan?planType=extensionPremium`;
export const MY_DASHBOARD_URL = `${env(['configuration', 'mywotAppUrl'])}/dashboard/mydashboard`;
export const HELP_CENTER_URL = 'https://support.mywot.com';
export const CONTACT_US_URL = 'https://zfrmz.com/zrZ2NGWHPzA3vF4hU9GE';
export const HELP_CENTER_URL_GUIDELINES = 'https://support.mywot.com/hc/en-us/sections/360003961560-User-Guidelines';
export const REPORT_URL = 'https://support.mywot.com/hc/en-us/requests/new';
export const LATEST_VERSION_URL = 'http://getwot.info/download';
export const PRIVACY_POLICY_URL = 'https://mywot.com/privacy';
export const TERMS_OF_USE_URL = 'https://www.mywot.com/terms';
export const PROMO_CODE_URL = `https://app.mywot.com/choosePlan?planType=extensionPremium&coupon=FMjxbbOI&${ WOT_PRODUCT }=Uninstall`;

export const DASHBOARD_V2_SUPPORTED_BROWSERS = ['chrome', 'edge'];

export const DEVELOPER_API_GET_STARTED_URL = 'https://zfrmz.com/K56ROyPbhFAjfGkwp5dk';
export const DEVELOPER_API_DOCUMENTATION_URL = 'https://support.mywot.com/hc/en-us/sections/360004477734-API-';
export const DEVELOPER_API_PRICING_URL = 'https://support.mywot.com/hc/en-us/articles/360024234294-4-Pricing';

export const LITE_PLAN_CALLS = '500';
export const STARTUP_PLAN_CALLS = '10K';
export const SMB_PLAN_CALLS = '100K';
export const ENTERPRISE_PLAN_CALLS = '300K';

export const STARTUP_PLAN_PRICE = '$39';
export const SMB_PLAN_PRICE = '$199';
export const ENTERPRISE_PLAN_PRICE = '$499';

// const STAGING = ".ninja";
// const PRODUCTION = ".com";
// export const DOMAIN = STAGING;
export const GA_TID = 'UA-2412412-1';

// UTM question - change google store and app store links?
export const GOOGLE_PLAY_STORE = `https://play.google.com/store/apps/details?id=com.wot.security&referrer=${ WOT_PRODUCT }%3DWotWebsite%26anid%3Dadmob`;
export const GOOGLE_PLAY_STORE_UTM = `https://play.google.com/store/apps/details?id=com.wot.security&referrer=${ WOT_PRODUCT }%3Dwebsite`;
export const APP_STORE = "https://apps.apple.com/app/wot-mobile-security-protection/id1451498125";
export const APP_STORE_UTM = "https://apps.apple.com/app/wot-mobile-security-protection/id1451498125?pt=119691152&ct=iosmywot&mt=8";
export const APP_STORE_UTM_2 = `https://apps.apple.com/app/wot-mobile-security-protection/id1451498125?ls=1&mt=8?id=com.my.wot&referrer=${ WOT_PRODUCT }%3Dwebsite`;
// export const MIN_PASSWORD_LENGTH = 6;

export const DASHBOARD_REQUEST_EXTENSION_DATA_MESSAGE = 'wot-db-ext-get-data';
export const DASHBOARD_RECEIVE_EXTENSION_DATA_MESSAGE = 'wot-ext-db-send-data';
export const EXTENSION_DATA_LOADING_STATE = 'EXTENSION_DATA_LOADING_STATE';
export const GOT_EXTENSION_DATA_STATE = 'GOT_EXTENSION_DATA_STATE';
export const NO_SUPPORTED_EXTENSION_STATE = 'NO_SUPPORTED_EXTENSION_STATE';
export const NO_EXTENSION_INSTALLED_STATE = 'NO_EXTENSION_INSTALLED_STATE';

export const UNINSTALL_FORM = 'https://docs.google.com/forms/d/e/1FAIpQLSd6HF6LPVYLbi_Z0c9-y6ONVOdJ4fMRR81gR7LFPwtEnIZLDg/viewform?embedded=true';

export const PLAN_TYPE = {
    EXTENSION: 'extension_premium'
};
export const PAYMENT_STATUSES = {
    PAYMENT_PENDING: 0,
    PAYMENT_RECIEVED: 1,
    FREE_TRIAL: 2,
    PAYMENT_DEFERED: 3,
    CANCELED: 4
}

export const TAGS_CONFIG = {
    clicks: [
        {
            id: 'wot-login-button',
            args: {
                category: 'Authorize',
                action: 'Login button click',
            },
        }, {
            id: 'wot-signup-button',
            args: {
                category: 'Authorize',
                action: 'Signup button click',
            },
        }, {
            id: 'wot-forgot-button',
            args: {
                category: 'Authorize',
                action: 'Forgot button click',
            },
        },
        {
            id: /wot-header-menu-.*/,
            args: {
                category: 'General',
                action: 'Header menu click',
            },
        },
        {
            id: 'wot-header-logo',
            args: {
                category: 'General',
                action: 'Header logo click',
            },
        },
        {
            id: /wot-footer-menu-.*/,
            args: {
                category: 'General',
                action: 'Footer menu click',
            },
        },
        {
            id: 'wot-footer-logo',
            args: {
                category: 'General',
                action: 'Footer logo click',
            },
        },
        {
            id: /wot-footer-link-.*/,
            args: {
                category: 'General',
                action: 'Footer link click',
            },
        },
        {
            id: /wot-footer-social-.*/,
            args: {
                category: 'General',
                action: 'Footer social click',
            },
        },
        {
            id: 'wot-quote-button',
            args: {
                category: 'Contact',
                action: 'Contact button click',
            },
        },
        {
            id: 'wot-textme-button',
            args: {
                category: 'Mobile',
                action: 'Text me the app click',
            },
        },
    ],
};

// Important note -
// When adding a new language update console keys at:
// kv/b2c/production/mywot/sitemap/languageCodes
// kv/b2c/staging/mywot/sitemap/languageCodes
// In order to update the sitemap with the new supported language

export const dropdownLanguages = [
    { key: 'en-us', name: 'English', image: '/images/icons/flags/US.svg' },
    { key: 'ru-ru', name: 'Русский', image: '/images/icons/flags/RU.png' },
    { key: 'fr-fr', name: 'Français', image: '/images/icons/flags/FR.svg' },
    { key: 'pt-br', name: 'Português', image: '/images/icons/flags/BR.png' },
    { key: 'de-de', name: 'Deutsch', image: '/images/icons/flags/DE.svg' },
    { key: 'ja-jp', name: '日本語', image: '/images/icons/flags/JP.svg' }
];
const ddLangsObj = {};
export const dropdownLangsPossibilities = dropdownLanguages.map(l => l.key);
dropdownLangsPossibilities.forEach(i => {
    const trimmed = i.substr(0, 2);
    ddLangsObj[trimmed] = true;
    dropdownLangsPossibilities.push(trimmed); // e.g: ['en-us', 'ru-ru', 'fr-fr', 'en', 'ru', 'fr']
});

export const dropdownLangsPossibilitiesRegex = new RegExp(`(${dropdownLangsPossibilities.join('|/')})`);

const urlLanguages = ['en-us','en','ru-ru','ru','nl-nl','nl','cs-cz','cs','da-dk','da','fi-fi','fi','ja-jp','jp','ja','pl-pl','pl','pt-br','pt',
    'es-la','es','sv-se','sv','tr-tr','tr','nb-no','nb','no-no','no','de-de','de','hi-in','hi','zh-hant','zh-hans','zh-tw','zh-cn','zh','fr-fr',
    'fr','uk-ua','uk','ko-kr','ko','it-it','it'];
const urlWebFullLanguages = dropdownLanguages.map(data => [data.key]);
export const urlWebLanguages = dropdownLanguages.map(data => [data.key.substr(0, 2)]).concat(urlWebFullLanguages);
export const redirectedLanguages = urlLanguages.filter(lang => !ddLangsObj[lang.substr(0, 2)]);
export const validLanguages = urlLanguages.filter(lang => ddLangsObj[lang.substr(0, 2)]);

export const URLS = {
    LANGUAGE_SECTION_REGEX: new RegExp(`(${urlLanguages.map(lang => `/${lang}`).join('|')}|)`),
    FIRST_SECTION_REGEX: new RegExp(`/(${urlLanguages.join('/|')}/)*[a-zA-Z0-9-]*`),
    EXCEPT_MODALS_REGEX: /\/(login|signup|forgot|edit|textme|updatePassword)(\/$|$)/
};

export const REGEX = {
    PASSWORD: /^(?=.*[A-Za-z])(?=.*\d)[\w@$!%*#?&+}{\]\[\;\:\/\\\-\(\)]{8,}$/,
    // DOMAIN: /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)(?:(?:(?:\w[\.\-\+]?){0,62})+)\.(\w{2,6})$/,
    DOMAIN: /[a-zA-Z0-9_-]*\.*[a-zA-Z0-9_-]+\.[a-zA-Z0-9_-]+/,
    IPV4_SUBSTRING: /^((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){1}|(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){2}|(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3})([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])?$/i,
};

export const MAX_SEARCH_SUGGESTIONS = 4;

export const TOP_TLDS = [
    '.com', '.org', '.net', '.edu', '.gov', '.co', '.cn',
    '.ca', '.co.uk', '.de', '.jp', '.co.jp', '.ru', '.fr',
    '.com.au', '.us',  '.ch', '.it', '.nl', '.se', '.no',
    '.es', '.io', '.in', '.info', '.biz', '.xyz', '.me'
];

export const RX_EMAIL_VALIDATION = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ratingTexts = ['general.rating.poor', 'general.rating.fair', 'general.rating.average', 'general.rating.good', 'general.rating.excellent'];

const lowRankingCategories = [401, 101, 103, 104, 105, 201, 202, 203, 205, 102, 201, 204, 206, 207, 402, 403]; // 1-2 stars
const highRankingCategories = [501, 401, 402, 403]; // 4-5 stars

export const ratingCategories = {
    '0': highRankingCategories,
    '1': lowRankingCategories,
    '2': lowRankingCategories,
    '3': [401, 201, 202, 203, 205, 102, 204, 206, 207, 402, 403],
    '4': highRankingCategories,
    '5': highRankingCategories
};

export const ALL_CATEGORIES = {
    101: {
        "group": "safety",
        "color": "red",
        "id": 101,
        "text": "pages.mass.rating.malware",
    },
    102: {
        "group": "reputation",
        "color": "yellow",
        "id": 102,
        "text": "pages.mass.rating.customer.service"
    },
    103: {
        "group": "safety",
        "color": "red",
        "id": 103,
        "text": "general.phishing",
    },
    104: {
        "group": "reputation",
        "color": "red",
        "id": 104,
        "text": "general.scam"
    },
    105: {
        "group": "reputation",
        "color": "red",
        "id": 105,
        "text": "pages.mass.rating.illegal"
    },
    201: {
        "group": "reputation",
        "color": "yellow",
        "id": 201,
        "text": "pages.mass.rating.misleading"
    },
    202: {
        "group": "safety",
        "color": "yellow",
        "id": 202,
        "text": "pages.mass.rating.privacy",
        "show": true
    },
    203: {
        "group": "reputation",
        "color": "yellow",
        "id": 203,
        "text": "general.suspicious"
    },
    204: {
        "group": "reputation",
        "color": "yellow",
        "id": 204,
        "text": "pages.mass.rating.hate"
    },
    205: {
        "group": "reputation",
        "color": "yellow",
        "id": 205,
        "text": "general.spam"
    },
    206: {
        "group": "safety",
        "color": "yellow",
        "id": 206,
        "text": "pages.mass.rating.unwanted",
    },
    207: {
        "group": "safety",
        "color": "yellow",
        "id": 207,
        "text": "general.ads.popups",
    },
    301: {
        "group": "reputation",
        "color": "red",
        "id": 301,
        "text": "general.tracking"
    },
    302: {
        "group": "reputation",
        "color": "blue",
        "id": 302,
        "text": "pages.mass.rating.alt.medicine"
    },
    303: {
        "group": "reputation",
        "color": "blue",
        "id": 303,
        "text": "pages.mass.rating.opinions"
    },
    304: {
        "group": "reputation",
        "color": "blue",
        "id": 304,
        "text": "general.other"
    },
    305: {
        "group": "reputation",
        "color": "blue",
        "id": 305,
        "text": "general.games"
    },
    306: {
        "group": "reputation",
        "color": "blue",
        "id": 306,
        "text": "general.news"
    },
    307: {
        "group": "reputation",
        "color": "blue",
        "id": 307,
        "text": "general.social"
    },
    501: {
        "group": "reputation",
        "color": "green",
        "id": 501,
        "text": "pages.mass.rating.good.site",
    },
    502: {
        "group": "reputation",
        "color": "green",
        "id": 502,
        "text": "general.popular",
    },
    401: {
        "group": "adult",
        "color": "red",
        "id": 401,
        "text": "pages.mass.rating.adult"
    },
    402: {
        "group": "adult",
        "color": "yellow",
        "id": 402,
        "text": "pages.mass.rating.nudity"
    },
    403: {
        "group": "adult",
        "color": "yellow",
        "id": 403,
        "text": "pages.mass.rating.shocking"
    },
    404: {
        "group": "adult",
        "color": "green",
        "id": 404,
        "text": "pages.mass.rating.site.kids"
    },
    405: {
        "group": "adult",
        "color": "green",
        "id": 405,
        "text": "general.gambling"
    }
};
export const BAD_CATEGORIES = [101,102,103,104,105,201,202,203,204,205,206,207,401,402,403,405];

export const confidenceRanks = [
    'general.na',
    'general.very.low',
    'general.low',
    'general.medium',
    'general.high',
    'general.extreme'
];

export const confidenceRanksCircles = [
    ' ○ ○ ○ ○ ○',
    ' ● ○ ○ ○ ○',
    ' ● ● ○ ○ ○',
    ' ● ● ● ○ ○',
    ' ● ● ● ● ○',
    ' ● ● ● ● ●'
];

export const imgsPath = '/images';
export const iconsPath = `${imgsPath}/icons`;
export const imgsCDN = 'https://cdn-cf.mywot.net';
export const defaultAvatarImageHref = '/images/blueAvatar.svg';

export const FACEBOOK_DIALOG_URL = 'https://www.facebook.com/dialog/feed?handler=wot&app_id=2062054284030378&display=page&';
export const FACEBOOK_SHARE_URL = 'https://goo.gl/ex62cM';
export const TWITTER_DIALOG_URL = 'https://twitter.com/intent/tweet?';

export const BY_WOT_ESTIMATION = "BY_WOT_ESTIMATION";
export const NOT_SAFE = "NOT_SAFE";
export const SAFE = "SAFE";
export const SUSPICIOUS = "SUSPICIOUS";
export const HIGHLY_SUSPICIOUS = "HIGHLY_SUSPICIOUS";
export const UNKNOWN = "UNKNOWN";

export const SUB_TARGETS_ENABLED_LIST = ['twitter.com'];

export const LEARNMORE_BLACKLIST_URL = 'https://support.mywot.com/hc/en-us/articles/115002806694-My-site-was-blacklisted-What-can-I-do-';
export const LEARNMORE_MACHINELEARNING_URL = 'https://support.mywot.com/hc/en-us/articles/360012989179-Machine-Learning-Algorithm-Prediction';

export const SCORECARD_REVIEWS_PER_PAGE = 20;

export const TRY_API_MAX_ATTEMPTS = 3;
export const ONE_MINUTE_IN_S = 60;
export const ONE_MINUTE_IN_MS = ONE_MINUTE_IN_S * 1000;
export const ONE_HOUR_IN_S = ONE_MINUTE_IN_S * 60;
export const ONE_HOUR_IN_MS = ONE_HOUR_IN_S * 1000;
export const ONE_DAY_IN_S = ONE_HOUR_IN_S * 24;
export const ONE_DAY_IN_MS = ONE_DAY_IN_S * 1000;
export const ONE_MONTH_IN_S = ONE_DAY_IN_S * 30;
export const ONE_MONTH_IN_MS = ONE_MONTH_IN_S * 1000;
export const COOKIE_MAXIMUM_LIFESPAN = ONE_DAY_IN_MS * 400;
export const GET_FAVICON_PREFIX = 'https://www.google.com/s2/favicons?domain=';

export const UTM_VARIABLES = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];
export const SYNC_EXT_ANALYTIC_INFO_COOKIE_NAME = 'WOT_SYNC_EXT_ANALYTIC_INFO';
export const DEFAULT_ALTERNATIVE_SITES = ['google.com', 'netflix.com', 'facebook.com', 'apple.com', 'foxnews.com'];

export const UNINSTALL_REASONS_WITH_FREE_TEXT = ['Something else', 'Other'];
export const UNINSTALL_REASONS = ["Don't need", 'Technical issues', 'Not free'].concat(UNINSTALL_REASONS_WITH_FREE_TEXT);
