import React from 'react';
import styled from 'styled-components';
import {InstallButtonContainerRow, GoogleLink, AppleLink} from '../../components/buttons/InstallContainer/StyledInstallContainer';
import {FlexRow, FlexRowCentered, FlexColumn, mobileBreakPoint} from '../../StyledUtils';
import {langPrefix} from '../../utils';

export const FooterContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    ${InstallButtonContainerRow} {
      flex-direction: column;
      align-self: flex-start;
      margin: 12px;
      z-index: 0;
      @media (max-width: ${mobileBreakPoint}){
        margin: 6px;
        flex-direction: row;
        align-self: center;
      }
    };
    #wot-install-button {
        width: 219px;
        height: 48px;
        border-radius: 24px;
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
        background-color: #4989f4;
        font-size: 14px;
        color: #fff;
        align-self: center;
        &:hover {
            background-color: #036bdf;
        }
    };
    ${GoogleLink}, ${AppleLink} {
      align-self: flex-end;
      margin: 5px 0px;
      width: 140px;
      height: 40px;
      @media (max-width: ${mobileBreakPoint}){
        margin: 6px;       
        }
    };
`;
export const FooterWrapper = styled(FlexColumn)`
    justify-content: space-around;
    align-items: center;
    @media (max-width: ${mobileBreakPoint}){
        margin: 12px;
        flex-grow: 1;
    }
`;
export const TopRow = styled(FlexRow)`
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    flex-wrap: nowrap;
    margin-bottom: 50px;
`;
export const BottomRow = styled(FlexRow)`
    align-items: center;
    width: 100%;
    height: 56px;
    flex-wrap: wrap;
    background-color: #F7F7F7;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
`;
export const Logo = styled.a.attrs(({lang}) => ({
    'alt': 'WOT Logo',
    'href': lang && lang !== 'en-us' ? langPrefix({lang}): '/',
}))`
    display: flex;
    width: 96px;
    height: 44px;
    margin: 12px;
    background-size: 96px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(/images/logo.svg);
    cursor: pointer;
    @media (max-width: ${mobileBreakPoint}){
        margin: 12px;       
    }
`;
export const SeparatorLine = styled.div`
    height: 1px;
    width: 95%;
    background-color: #EAEAEA;
    margin: 24px 12px;
`;
export const SeparatorDot = styled.div`
    color: #B2B2B2;
    margin: 0 8px;
    &::before {
        content: "·"
    }
`;
export const SeparatorColumn = styled.div`
    border-right: 1px solid #B2B2B2;
    height: 24px;
`;
export const LanguageFlag = styled.img.attrs({
    alt: 'flag',
    width: '16px',
    height: '16px'
})`
    align-self: center;
    width: 16px;
    height: 16px;
    background-size: 25px;
    border-radius: 15px;
    margin: 5px;
`;
export const LanguageSelected = styled.p`
    font-family: 'Roboto', sans-serif;
    text-transform: capitalize;
    margin: 5px;
`;
export const MenuContainer = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-around;
    margin: 12px;
    @media (min-width: 480px) and (max-width: ${mobileBreakPoint}){
        width: 100%;
    }
    @media (max-width: 479px){
        flex-direction: column;
        width: 343px;
        margin: 16px;
    }
    #wot-footer-menu-Downloads-title ~ div {
        margin: 0
    }

`;
export const MenuTitle = styled.p`
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    color: #252525;
    margin: 0 0 24px 0;
`;
export const LinksContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: ${mobileBreakPoint}){
        margin: 16px 0 0;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 100%;
    }
`;
export const StyledLink = styled.a.attrs({
    'alt': 'WOT Footer Link',
})`
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-decoration: none;
    color: #B2B2B2;
    @media (max-width: ${mobileBreakPoint}){
        color: #252525;
    }
`;
export const SocialAndCopyrightContainer = styled.div`
    display: flex;
    margin-right: auto;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;
`;
export const CopyrightText = styled.div`
    font-size: 14px;
    margin-right: 8px;
    color: #B2B2B2;
    @media (max-width: ${mobileBreakPoint}) {
        margin-top: 85px;       
    }
`;
export const AlsoAvailableOnContainer = styled(FlexRow)`
    align-items: center;
`;
export const BrowserIcon = styled.a.attrs({
    alt: 'Browser icon',
    target: '_blank'
  })`
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin: 0px 4px;
    box-sizing: border-box;
    background: url(${props => props.imgPath}) center center/contain no-repeat;
  `;

  export const IconCircle = styled(FlexRowCentered)`
    @media (max-width: ${mobileBreakPoint}){
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        box-shadow: 0px 2px 4px rgba(61, 61, 61, 0.04), 0px 8px 16px rgba(112, 112, 112, 0.16);
        background: #FFFFFF;
    }
  `;
