import React from 'react';
import styled from 'styled-components';
import {FlexColumn, mobileBreakPoint} from '../../StyledUtils';

export const CarouselContainer = styled(FlexColumn)` 
  flex-grow: 1;
  width: 100%;
  @media (max-width: ${mobileBreakPoint}) {
    max-width: 400px;  
  }
`;

export const StyledMobileStepper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SlideContainer = styled.div` 
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${mobileBreakPoint}) {
    max-width: 400px;  
  }
`;
