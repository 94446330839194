// @flow
import React from 'react';
import type Node from 'react';
import {useDeviceInfo, useQueries, useTranslate} from '../../../hooks';
import PcWorld from '../../../images/logos/pc-world-styleable.svg';
import Yahoo from '../../../images/logos/yahoo-styleable.svg';
import UsaToday from '../../../images/logos/usa-today-3-styleable.svg';
import Yandex from '../../../images/logos/yandex-styleable.svg';
import Cnbc from '../../../images/logos/cnbc-2-styleable.svg';
import KnowTechie from '../../../images/logos/know-techie-styleable.svg';
import { ConnectWithGoogleButton, ConnectWithGoogleTerms } from '../../../components/ConnectWithGoogleHistoryButton/ConnectWithGoogleButton';
import {Bold, ConnectWithGoogleSection, DeviceImage, DeviceImageContainer, LogosContainer, LogosSection, Red, Score, ScoreRow,
    Star, Subtitle, Title, TopDescription, TopDescriptionSection, LogosTitle, DescriptionContent, Hero, Logo} from './StyledHistorySection';

export const HistorySection = (): Node => {
    const { isIos, wotTraceIdConnect } = useDeviceInfo();
    const translate = useTranslate();
    const translateHistory = (key: string): string => translate(`pages.home.heroHistory.${key}`);
    const { isDesktopQuery, isMobileQuery, isTabletQuery} = useQueries();

    const HeroTitle = (): Node => {
        const title = translateHistory('title-1');
        const splitTitle = title.split('Compromised');
        return splitTitle.length === 2 ?
            <Title>{splitTitle[0]}<Red>Compromised</Red>{splitTitle[1]}</Title>:
            <Title>{title}</Title>;
    };
    const HeroSubtitle = (): Node => {
        const subtitle = translateHistory('subtitle-1');
        const splitSubtitle = subtitle.split(' - ');
        return splitSubtitle.length === 2 ?
            <Subtitle><Bold>{splitSubtitle[0]}</Bold>{` ${splitSubtitle[1]}`}</Subtitle>:
            <Subtitle>{subtitle}</Subtitle>;
    };

    return (
        <>
            <Hero>
                <DescriptionContent>
                    <TopDescriptionSection>
                        <ScoreRow>
                            <Score>4.5</Score><Star/>{translateHistory('2mReviews')}
                        </ScoreRow>
                        <TopDescription>{translateHistory('ensureYourSafety')}</TopDescription>
                    </TopDescriptionSection>
                    <HeroTitle/>
                    <HeroSubtitle/>
                    <ConnectWithGoogleSection limitWidth={!wotTraceIdConnect}>
                        <ConnectWithGoogleButton/>
                        <ConnectWithGoogleTerms/>
                    </ConnectWithGoogleSection>
                </DescriptionContent>
                <DeviceImageContainer>
                    <DeviceImage {...{isMobileQuery, isTabletQuery, isDesktopQuery, isIos}}/>
                </DeviceImageContainer>
            </Hero>
            <LogosSection>
                <LogosTitle>{translateHistory('asFeaturedOn')}</LogosTitle>
                <LogosContainer>
                    <Logo imgWidth={{ desktop: 107, mobile: 72 }}><PcWorld/></Logo>
                    <Logo imgWidth={{ desktop: 87, mobile: 58 }}><Yahoo/></Logo>
                    <Logo imgWidth={{ desktop: 160, mobile: 107 }}><UsaToday/></Logo>
                    <Logo imgWidth={{ desktop: 99, mobile: 66 }}><Yandex/></Logo>
                    <Logo imgWidth={{ desktop: 146, mobile: 98 }}><KnowTechie/></Logo>
                    <Logo imgWidth={{ desktop: 141, mobile: 94 }}><Cnbc/></Logo>
                </LogosContainer>
            </LogosSection>
        </>
    );
};
