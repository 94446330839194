import styled from 'styled-components';
import {FlexColumn, FlexRow, mobileBreakPoint} from '../../../StyledUtils';
import {SupportedBrowsersContainer} from '../../../components/SupportedBrowsers/StyledSupportedBrowsers';
import {Title, Icon} from '../../../components/SectionHeader/StyledSectionHeader';
import {CarouselContainer} from '../../../components/Carousel/StyledCarousel';

export const StyledReviewsContainer = styled(FlexColumn)`
  align-items: center;
  position: relative;
  justify-content: center;
  
  #wot-install-button {
    width: 335px;
    height: 56px;
    color: #FFFFFF;
    font-size: 20px;
    align-self: center;
    border-radius: 28px;
    text-transform: none;
    background-color: #037AFF;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
    &:hover {
      background-color: #165AD9;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.18), 
        0px 0px 2px rgba(0, 38, 80, 0.12), 
        0px 0px 1px rgba(0, 38, 80, 0.04);
    }
  };
`;

export const ReviewsTopBackground = styled.div`
  top: 100px;
  width: 80%;
  height: 40%;
  position: absolute;
  filter: blur(200px);
  background: rgba(3, 122, 255, 0.25);
  @media (max-width: ${mobileBreakPoint}) {
    top: 200px;
    width: 744px;
    height: 444px;
    position: absolute;
    filter: blur(200px);
    background: rgba(3, 122, 255, 0.25);
  }
`;

export const ReviewsContent = styled(FlexColumn)`
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 104px 0px 141px 0px;
  ${CarouselContainer}{
    flex-direction: column-reverse;
  }
  ${SupportedBrowsersContainer}{
    height: 32px;
    width: 384px;
    margin: 24px 0px 100px 0;
  }
  ${Title}{
    color: #037AFF;
  }
  ${Icon}{
    border-radius: unset;
    background-color: transparent;
  }
  @media (max-width: ${mobileBreakPoint}) {
    padding: 104px 0px 141px 0px;
  }
`;

export const UserReviewsContainer = styled(FlexColumn)`
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Description = styled.h2`
  width: 672px;
  color: #373B3E;
  font-size: 32px;
  font-weight: 600;
  line-height: 39px;
  text-align: center;
  margin: 140px 0 56px;
  font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
  @media (max-width: ${mobileBreakPoint}) {
    width: 326px;
    font-size: 22px;
    line-height: 27px;
  }
`;

export const BrowserIconsRow = styled(FlexRow)`
  align-items: center;
  margin: 24px;
  justify-content: space-between;
  @media (max-width: ${mobileBreakPoint}) {
    width: 100%;
    margin: 24px 0px 33px 0px;
    justify-content: space-evenly;
  }
`;

export const BrowserIcon = styled.a.attrs({
  alt: 'Browser icon',
})`
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin: 0 10px;
  background: url(${props => props.imgPath}) center center/contain no-repeat;
`;

export const VerticalSeparatorLine = styled.div`
  width: 0px;
  height: 32px;
  margin: 0px 10px;
  border: 1px solid rgba(211, 217, 222, 1);
`;
