// @flow

import {mobileBreakPoint} from '../../StyledUtils';
import {HEADER_HEIGHT} from '../../layouts/theme';

const styles: Function = (theme: Object): Object => ({
    backdrop: {
        backgroundColor: '#66666694',
        height: '100%',
    },
    paper: {
        top: 41,
        left: -28,
        width: 245,
        zIndex: 1,
        position: 'absolute',
        background: '#FFFFFF',
        boxShadow: '0px 20px 25px rgba(0, 0, 0, 0.25)',
        borderRadius: '0px 0px 12px 12px',
        [`@media screen and (max-width: ${mobileBreakPoint})`]: {
            marginLeft: 28,
            width: '55vw',
        }
    },
    search: {
        textTransform: 'none !important',
        display: 'flex',
        position: 'relative',
        margin: '0px 10px',
        width: '100%'
    },
    searchRoot: {
        flexWrap: 'nowrap',
        height: 40,
        padding: 0,
        marginTop: '0!important',
    },
    searchInput: {
        padding: 0,
        height: 'initial',
        width: 'auto',
        letterSpacing: 'normal',
    },
    searchUnderline: {
        '&:after': {
            borderBottom: '1px solid #037AFF',
        },
    },
    mobileSearchInput: {
        marginTop: '0px !important',
        width: '55vw',
        fontWeight: 'normal',
    },
    searchFormLabel: {
        display: 'none',
    },
    searchAdornment: {
        position: 'absolute',
        top: 10,
        left: 10,
    },
    simpleMenu: {
        zIndex: `1302 !important`
    },
    simpleMenuWrapper: {
        padding: '8px 0px'
    },
    searchAdornmentSVG: {
        width: 20,
        height: 20,
    },
    menuItem: {
        height: 'auto',
        margin: '0px 16px',
        padding: '16px 16px 16px 0px',
        '&:hover': {
            borderRadius: '8px',
            background: '#F1F7FD',
            justifyContent: 'flex-start'
        }
    },
    menuItemPadding: {
        paddingLeft: 34,
    },
    mobileMenuItem: {
        textDecoration: 'none',
        color: 'black',
    },
    mobileMenuDownloadLink: {
        color: '#057AFF',
    },
    mobileMenuIcon: {},
    hidden: {
        visibility: 'hidden',
    },
    mobileMenuSubItem: {
        backgroundColor: '#f6f6f6',
        textDecoration: 'none',
    },
    suggestionStyle: {
        width: '400px',
        '@media (min-width: 1212px)': {
            width: 461
        },
        '@media (max-width: 820px)': {
            width: 300
        }
    },
    desktopAccount: {
        paddingTop: 5,
        paddingRight: 3,
        paddingLeft: 5,
        paddingBottom: 5,
        borderRadius: 999,
        '&:hover': {
            background: '#F1F7FD',
        }
    },
    accountIcon: {
        width: 40,
        height: 40,
        borderRadius: '50%',
    },
    mobileSignUpButtonText: {
        color: theme.palette.primary.main,
    },
    avatar: {
        width: 40,
        height: 40,
        borderRadius: '50%',
    },
    onTop: {
        zIndex: 1301,
        boxShadow: 'none',
    },
    drawer: {
        boxShadow: 'none',
        border: 'none',
        borderRadius: '0 0 15px 15px',
        backgroundColor: '#fafafa',
        height: 'max-content',
        top: HEADER_HEIGHT,
        width: '100%',
        position: 'fixed',
        outline: 'none',
    },
    drawerTopWithCookies: {
        top: HEADER_HEIGHT * 2
    },
    buttonLink: {
        padding: '8px 16px',
        letterSpacing: 'normal',
    },
    profileMenuItem: {
        paddingTop: '12px',
        paddingBottom: '12px',
        letterSpacing: 'normal',
    },
    profileMenu: {
        boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    },
});

export default styles;
