// @flow

import * as React from 'react';
import { getOS } from '../utils/index';
import { isNode } from '../utils/isnode';
import type { Node, ComponentType } from 'react';

type Props = {}

let mobile = !isNode() && (getOS() === 'Android' || getOS() === 'iOS');

function isMobile(WrappedComponent: React.ComponentType<any>): ComponentType<Props> {
    return class IsMobile extends React.PureComponent<Props> {
        constructor(props: Object) {
            super(props);
        }

        render(): Node {
            return <WrappedComponent isMobile={mobile} {...this.props}/>;
        }
    };
}

export default isMobile;
