// @flow
import merge from 'lodash/merge';

export function auth(state: Object, action: Object): Object {
    switch (action.type) {
        case 'WOTUSER_TOKEN_REQUESTED':
            return merge({}, state, { status: action.type, token: null });
        case 'WOTUSER_TOKEN_FAILED':
            // jwt.clearToken();
            return merge({}, state, { status: action.type, token: null });
        case 'WOTUSER_TOKEN_SUCCEED':
            return merge({}, state, { status: action.type, token: action.data.token });
        case 'WOTUSER_DATA_REQUESTED':
            return merge({}, state, { status: action.type });
        case 'WOTUSER_DATA_FAILED':
            // jwt.clearToken();
            return merge({}, state, { status: action.type });
        case 'WOTUSER_DATA_SUCCEED':
            return merge({}, state, action.data, { status: action.type });
        case 'FORGOT_PASS_REQUESTED':
            return merge({}, state, { status: action.type });
        case 'FORGOT_PASS_FAILED':
            return merge({}, state, { status: action.type });
        case 'FORGOT_PASS_SUCCEED':
            return merge({}, state, action.data, { status: action.type });
        case 'FAIL_ACKNOWLEDGED':
            return merge({}, state, { status: action.type });
        default:
            return merge({}, state);
    }
}
