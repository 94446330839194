// @flow
import merge from 'lodash/merge';

export function form(state: Object = {}, action: Object): Object {
    switch (action.type) {
        case 'ONE_TIME_LOGIN_REQUESTED':
        case 'ONE_TIME_LOGIN_SUCCEED':
        case 'ONE_TIME_LOGIN_FAILED':


        case 'TEXT_ME_THE_APP_REQUESTED':
        case 'TEXT_ME_THE_APP_SUCCEED':
        case 'TEXT_ME_THE_APP_FAILED':

        case 'QUOTE_REQUESTED':
        case 'QUOTE_FAILED':
        case 'QUOTE_SUCCEED':

        case 'CHECK_LINK_EXPIRATION_REQUESTED':
        case 'CHECK_LINK_EXPIRATION_SUCCEED':
        case 'CHECK_LINK_EXPIRATION_FAILED':

        case 'RESET_PASSWORD_REQUESTED':
        case 'RESET_PASSWORD_SUCCEED':
        case 'RESET_PASSWORD_FAILED':

            return merge({}, state, { data: action.data, type: action.type });
        case 'ACKNOWLEDGE_MESSAGE':
            return merge({}, state, { type: action.type });
        default:
            return merge({}, state);
    }
}
