// @flow
import { put, takeLatest, call } from 'redux-saga/effects';
import env from '../utils/env';
import sagaRequest from '../utils/sagaRequest';
import { getWotTraceId } from '../utils';

function* requestQuote(action: Object): Iterable<any> {
    try {
        let lastContactUs = +localStorage.getItem('_lastContactUs');

        if (lastContactUs > 0 && (((+new Date()) - lastContactUs) < 10 * 60 * 1000)) {
            yield put({
                type: 'QUOTE_FAILED',
                data: {
                    status: '',
                    message: 'Sorry, you can take this opportunity every 10 minutes. Please try again later.',
                },
            });
        } else {
            let root = env(['configuration', 'authApi']);

            if (typeof root !== 'string' || root.length < 5) {
                yield put({ type: 'QUOTE_FAILED', data: { message: 'empty configuration for api' } });
            } else {
                const wotTraceId = getWotTraceId();

                let response = yield fetch(`${root}/mailer/contact_us`, {
                    mode: 'cors',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'wot-trace-id': wotTraceId
                    },
                    credentials: 'same-origin',
                    body: JSON.stringify(action.data),
                });

                let result = yield (response && (response.status === 200 ? response.json() : response.text()));

                if (response && response.status === 200 && result && result.status === 200) {
                    localStorage.setItem('_lastContactUs', '' + +new Date());
                    yield put({ type: 'QUOTE_SUCCEED', data: result });
                } else {
                    yield put({ type: 'QUOTE_FAILED', data: { status: response && response.status, message: result } });
                }
            }
        }
    } catch (err) {
        yield put({ type: 'QUOTE_FAILED', data: { status: '', message: err } });
    }
}

function* requestResetPassword(action: Object): Iterable<any> {
    let { hash, password, confirmPassword } = action.data;
    yield call(sagaRequest, {
        apiRoot: env(['configuration', 'userApi']),
        apiPath: `/v2/user/reset-password/step-3/set-new-password/${hash}`,
        apiMethod: 'POST',
        success: 'RESET_PASSWORD_SUCCEED',
        fail: 'RESET_PASSWORD_FAILED',
        data: { password, confirmPassword },
    });
}


function* requestSendMeTheApp(action: Object): Iterable<any> {
    let { number } = action.data;

    const last = +localStorage.getItem('text-me-last');
    let count = +localStorage.getItem('text-me-last-count') || 0;

    if (+last && (+new Date() - last) >= 1000 * 60 * 15) {
        count = 0;
    }

    if (count < 3) {
        try {
            yield call(sagaRequest, {
                apiRoot: env(['configuration', 'authApi']),
                apiPath: `/sendappurl`,
                apiMethod: 'POST',
                success: 'TEXT_ME_THE_APP_SUCCEED',
                fail: 'TEXT_ME_THE_APP_FAILED',
                data: { phone: number.replace(/[\+\s\-\(\)]/gm, ''), referrer: location.href },
                mode: 'cors',
                afterSuccess: () => {
                    localStorage.setItem('text-me-last', `${+new Date()}`);
                    localStorage.setItem('text-me-last-count', `${count + 1}`);
                },
            });
        } catch (e) {
            localStorage.setItem('text-me-last', `0`);
            console.log(e);
        }
    } else {
        yield put({
            type: 'TEXT_ME_THE_APP_FAILED',
            data: { message: 'Sorry, you’re allowed three tries every 15 minutes. Please try again later.' },
        });
    }
}

function* requestCheckLinkExpiration(action: Object): Iterable<any> {
    let { hash } = action.data;
    yield call(sagaRequest, {
        apiRoot: env(['configuration', 'userApi']),
        apiPath: `/v2/user/reset-password/step-2/check-hash-viability/${hash}`,
        apiMethod: 'GET',
        success: 'CHECK_LINK_EXPIRATION_SUCCEED',
        fail: 'CHECK_LINK_EXPIRATION_FAILED',
    });
}

function* formsSaga(): Iterable<any> {
    yield takeLatest('QUOTE_REQUESTED', requestQuote);
    yield takeLatest('CHECK_LINK_EXPIRATION_REQUESTED', requestCheckLinkExpiration);
    yield takeLatest('TEXT_ME_THE_APP_REQUESTED', requestSendMeTheApp);
    yield takeLatest('RESET_PASSWORD_REQUESTED', requestResetPassword);
}

export default formsSaga;
