// @flow
import React, {useCallback, useMemo} from 'react';
import {GrowthBook, GrowthBookProvider as Provider} from '@growthbook/growthbook-react';
import type {Experiment, FeatureApiResponse, Result} from '@growthbook/growthbook-react';
import type { Node } from 'react';
import env from '../../utils/env';
import {useDeviceInfo, useInternalAnalytics} from '../../hooks';
import {write, read} from '../../utils/cookie';
import {getSegment} from '../AnalyticsProvider/AnalyticsProvider';

type Props = {
    children: Node,
    gbSSRData?: FeatureApiResponse
};

const GrowthBookProvider = ({ children, gbSSRData }: Props): Node => {
    const { wotTraceId, browserName } = useDeviceInfo();
    const sendAnalytics = useInternalAnalytics();

    const sendExperimentAnalytics = useCallback((experiment: Experiment, result: Result) => {
        const testGroup = experiment.key?.replace(/-/g, '_');
        if (testGroup && !read(`${testGroup}-ab`)) {
            write(`${testGroup}-ab`, true, `;Path=/;Expires=Fri, 01 Jan 2038 00:00:01 GMT`);
            sendAnalytics({
                testGroup,
                type: 'ASSIGN_TO_AB_TEST',
                specificFields: {
                    segment: getSegment(result?.value)
                }
            });
        }
    }, []);

    const growthBook = useMemo((): GrowthBook =>
            new GrowthBook({
                apiHost: 'https://cdn.growthbook.io',
                clientKey: env(['configuration', 'growthbookClientKey']),
                enableDevMode: process.env.NODE_ENV === 'development',
                attributes: {
                    id: wotTraceId,
                    browserName
                },
                trackingCallback: sendExperimentAnalytics
            }).initSync({
                payload: gbSSRData || {}
            }),
        [gbSSRData, wotTraceId, browserName]
    );

    return (
        <Provider growthbook={growthBook}>
            {children}
        </Provider>
    );
};

export default GrowthBookProvider;
