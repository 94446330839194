// @flow

import * as React from 'react';
import { getBrowserInfo } from '../utils/index';
import { isNode } from '../utils/isnode';
import type { Node, ComponentType } from 'react';

type Props = {}

let browserInfo = !isNode() ? getBrowserInfo() : { name: '', version: '' };

function withBrowserInfo(WrappedComponent: React.ComponentType<any>): ComponentType<Props> {
    return class BrowserInfo extends React.PureComponent<Props> {
        constructor(props: Object) {
            super(props);
        }

        render(): Node {
            return <WrappedComponent browserInfo={browserInfo} {...this.props}/>;
        }
    };
}

export default withBrowserInfo;
