// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import Notification from '../blocks/notification/index';
import type { ComponentType } from 'react';
import { lastAction } from '../reducers/lastAction';
import { REGEX } from '../utils/const';
import {LocalesConsumer} from '../utils/locales';
import type {ILocalesTransformers} from '../utils/locales';

type Props = {
    acknowledge: Function,
    auth: Object,
    classes: Object,
    history: Object,
    lang: string,
    logout: Function,
    lastAction: Object,
    location: Object,
    theme: Object,
    isMobile: boolean,
    user?: Object
}

type State = {
    color: string,
    onClose: Function,
    open: boolean,
    text: string
};

function withSnackbar(WrappedComponent: React.ComponentType<Props>): ComponentType<Props> {
    class Notifier extends React.PureComponent<Props, State> {
        constructor(props: Props) {
            super(props);

            this.state = {
                text: '',
                color: '',
                onClose: () => {
                },
                open: false,
            };
        }

        static getDerivedStateFromProps(props: Props, state: State): any {
            let { lastAction } = props;

            switch (lastAction.type) {
                case 'ONE_TIME_LOGIN_FAILED':
                case 'RESET_PASSWORD_FAILED':
                    return {
                        text: 'components.snackbar.reset.password',
                        color: 'red',
                        onClose: props.acknowledge,
                        open: true,
                    };
                case 'QUOTE_FAILED':
                    return {
                        text: lastAction.data && lastAction.data.message || 'components.snackbar.error.quote',
                        color: 'red',
                        onClose: props.acknowledge,
                        open: true,
                    };
                case 'MRT_FAILED':
                    return {
                        text: lastAction.data.message.message || 'components.snackbar.error.mrt',
                        color: 'red',
                        onClose: props.acknowledge,
                        open: true,
                    };
                case 'MRT_SUCCEED':
                    return {
                        text: 'components.snackbar.success.mrt',
                        color: 'green',
                        onClose: props.acknowledge,
                        open: true,
                    };
                case 'WOTUSER_TOKEN_FAILED':
                    return {
                        text: lastAction.data && lastAction.data.message || 'components.snackbar.error.token',
                        color: 'red',
                        onClose: props.acknowledge,
                        open: true,
                    };
                case 'RESEND_EMAIL_SUCCEED':
                    return {
                        text: lastAction.data && lastAction.data.message || 'components.snackbar.success.mrt',
                        color: 'green',
                        onClose: props.acknowledge,
                        open: true,
                    };
                case 'RESEND_EMAIL_FAILED':
                    return {
                        text: lastAction.data && lastAction.data.message || 'components.snackbar.error.token',
                        color: 'red',
                        onClose: props.acknowledge,
                        open: true,
                    };
                case 'QUOTE_SUCCEED':
                    return {
                        text: 'components.snackbar.success.quote',
                        color: 'green',
                        onClose: props.acknowledge,
                        open: true,
                    };
                case 'FORGOT_PASS_SUCCEED':
                    return {
                        text: 'components.snackbar.forgot.password',
                        color: 'green',
                        onClose: props.acknowledge,
                        open: true,
                    };
                case 'ACKNOWLEDGE_MESSAGE':
                    return {
                        open: false,
                    };
                case 'RECENT_REVIEWS_FAILED':
                    return {
                        text: 'components.snackbar.recent.reviews.failed',
                        color: 'red',
                        onClose: props.acknowledge,
                        open: true,
                    };
                case 'TEXT_ME_THE_APP_SUCCEED':
                    return {
                        text: 'components.snackbar.textme.success',
                        color: 'green',
                        onClose: props.acknowledge,
                        open: true,
                    };
                case 'TEXT_ME_THE_APP_FAILED':
                    return {
                        text: lastAction.data.message.message
                        || lastAction.data.message
                        || 'components.snackbar.textme.failed',
                        color: 'red',
                        onClose: props.acknowledge,
                        open: true,
                    };
                case 'USERCARD_UPDATE_SUCCEED':
                case 'AVATAR_UPDATE_SUCCEED':
                    setTimeout(() => {
                        location.pathname.indexOf('updatePassword') !== -1
                            ? (location.pathname = location.pathname.replace('updatePassword', ''))
                            : location.reload();
                    }, 3000);
                    return {
                        text: lastAction.data.message && lastAction.data.message.length > 10
                            ? lastAction.data.message
                            : 'Successfully updated',
                        color: 'green',
                        onClose: props.acknowledge,
                        open: true,
                    };
                case 'USERCARD_UPDATE_FAILED':
                    return {
                        text: lastAction.data.message && lastAction.data.message.length > 10
                            ? lastAction.data.message
                            : 'Update Failed',
                        color: 'red',
                        onClose: props.acknowledge,
                        open: true,
                    };
                case 'SITE_CLAIM_SUCCEED':
                    return {
                        text: 'components.snackbar.site.claimed',
                        color: 'green',
                        onClose: props.acknowledge,
                        open: true,
                    };
                case 'SITE_CLAIM_FAILED':
                    const message = lastAction.data.message.message;
                    return {
                        text: message.indexOf('xn--') !== -1
                            ? message.replace(REGEX.DOMAIN, '').replace('s:', '')
                            : message,
                        color: 'red',
                        onClose: props.acknowledge,
                        open: true,
                    };
                case 'MASSRATE_SUCCEED':
                    return {
                        text: 'components.snackbar.site.mtr.success',
                        color: 'green',
                        onClose: props.acknowledge,
                        open: true,
                    };
                case 'MASSRATE_FAILED':
                    return {
                        text: 'components.snackbar.site.mtr.failed',
                        color: 'red',
                        onClose: props.acknowledge,
                        open: true,
                    };
                case 'USERSITE_DELETE_SUCCEED':
                    return {
                        text: 'components.snackbar.site.user.deleted',
                        color: 'green',
                        onClose: props.acknowledge,
                        open: true,
                    };
                case 'USERSITE_DELETE_FAILED':
                    return {
                        text: 'components.snackbar.site.user.delete.failed',
                        color: 'red',
                        onClose: props.acknowledge,
                        open: true,
                    };
                case 'AVATAR_UPDATE_FAILED':
                    return {
                        text: 'components.snackbar.avatar.failed',
                        color: 'red',
                        onClose: props.acknowledge,
                        open: true,
                    };
                case 'ACCOUNT_DELETE_SUCCEED':
                    return {
                        text: 'components.snackbar.account.delete.success',
                        color: 'green',
                        onClose: props.acknowledge,
                        open: true,
                    };
                case 'ACCOUNT_DELETE_FAILED':
                    return {
                        text: 'components.snackbar.site.user.delete.failed',
                        color: 'red',
                        onClose: props.acknowledge,
                        open: true,
                    };
                case 'COMMUNITY_SITE_REVIEW':
                    return {
                        text: 'components.snackbar.community.site.review',
                        color: 'green',
                        onClose: props.acknowledge,
                        open: true,
                    };
                case 'COPY_LINK':
                    return {
                        text: 'components.snackbar.copy.link',
                        color: 'green',
                        onClose: props.acknowledge,
                        open: true,
                    };
                case 'REPORT_REVIEW_SUCCESS':
                    return {
                        text: 'components.snackbar.scorecard.flag.review.success',
                        color: 'green',
                        onClose: props.acknowledge,
                        open: true,
                    };
                case 'REPORT_REVIEW_EXISTS':
                    return {
                        text: 'components.snackbar.scorecard.flag.review.alreadyFlagged',
                        color: 'red',
                        onClose: props.acknowledge,
                        open: true,
                    };
                case 'BAN_USER_SUCCESS':
                    return {
                        text: 'USER BANNED',
                        color: 'green',
                        onClose: props.acknowledge,
                        open: true,
                    };
                case 'BAN_USER_FAILED':
                    return {
                        text: 'BANNED FAILED',
                        color: 'red',
                        onClose: props.acknowledge,
                        open: true,
                    };
                default:
                    return null;
            }
        }

        render(): any {
            let { ...other } = this.props;

            return (
                <LocalesConsumer>
                    {
                        ({translate}: ILocalesTransformers): React.Node => {
                            const message = {
                                ...this.state,
                                text: translate(this.state.text)
                            };
                            return (
                                <React.Fragment>
                                    <Notification message={message} />
                                    <WrappedComponent{...other} />
                                </React.Fragment>
                            );
                        }
                    }
                </LocalesConsumer>
            );
        }
    }

    function mapStateToProps(state: Object): Object {
        return { lastAction: state.lastAction || {} };
    }

    function mapDispatchToProps(dispatch: Function): Object {
        return {
            acknowledge: () => {
                dispatch({ type: 'ACKNOWLEDGE_MESSAGE' });
            },
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(Notifier);
}

export default withSnackbar;
