import React from 'react';
import styled from 'styled-components';
import {FlexColumn} from '../../StyledUtils';

export const CarouselContainer = styled.div` 
  max-width: 400px;
  flex-grow: 1;
`;

export const Slide = styled(FlexColumn)` 
  max-width: 300px;
  height: 100%;
  flex-shrink: 0;
  padding: 25px;
  margin: 0px 50px;
  scroll-snap-align: center;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
`;

export const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #808080;
  max-width: 350px;
  text-align: center;
  margin-bottom: 32px;
`;

export const CroppedImageContainer = styled.div`
  overflow: hidden;
  max-width: 228px;
  max-height: 328px;
`;

export const ImageContainer = styled.img.attrs({
    alt: 'Mobile Image',
})`
    width: 228px;
    height: 456px;
`;

