// @flow
import React, {useContext} from 'react';
import withLocales from '../../HOCs/withLocales';
import type {ILocalesTransformers} from '../../utils/locales';
import {OutlineRoundedButton} from '../buttons/outlineRounded/OutlineRoundedButton';
import {Description, DescriptionColored, DescriptionContainer, StyledInstallContainer, ButtonBlurContainer, BlurBackground} from './StyledInstallCard';
import {LocalesContext} from '../../utils/locales';

export type IInstallCardProps = {
    buttonText: string;
    buttonLink?: string;
    trackingCategory?: string;
    trackingAction?: string;
}

export const InstallCard = ({buttonText, buttonLink, trackingCategory, trackingAction}: IInstallCardProps): any => {
    const {translate}: ILocalesTransformers = useContext(LocalesContext);

    return (
        <StyledInstallContainer>
            <DescriptionContainer>
                <Description>{translate('pages.home.features-section.subtitle1')}</Description>
                <DescriptionColored>{translate('pages.home.features-section.subtitle2')}</DescriptionColored>
                <Description>{translate('pages.home.features-section.subtitle3')}</Description>
                <DescriptionColored>{translate('pages.home.features-section.subtitle4')}</DescriptionColored>
                <Description>{translate('pages.home.features-section.subtitle5')}</Description>
                <DescriptionColored>{translate('pages.home.features-section.subtitle6')}</DescriptionColored>
            </DescriptionContainer>
            <ButtonBlurContainer>
                <BlurBackground />
                <OutlineRoundedButton
                    buttonText={buttonText}
                    buttonLink={buttonLink}
                    trackingCategory={trackingCategory}
                    trackingAction={trackingAction} />
            </ButtonBlurContainer>
        </StyledInstallContainer>
    );
};
