// @flow
import { call, put, takeLatest } from 'redux-saga/effects';
import env from '../utils/env';
import sagaRequest from '../utils/sagaRequest';

function* claimSites(action: Object): Iterable<any> {
    yield call(sagaRequest, {
        apiRoot: env(['configuration', 'userApi']),
        apiPath: `/v2/sites`,
        apiMethod: 'POST',
        data: action.data,
        success: 'SITE_CLAIM_SUCCEED',
        fail: 'SITE_CLAIM_FAILED',
    });
}

function* verifySite(action: Object): Iterable<any> {
    yield call(sagaRequest, {
        apiRoot: env(['configuration', 'userApi']),
        apiPath: `/v2/sites/verify/${action.data.domain}`,
        apiMethod: 'GET',
        ifSuccess: (result: Object): boolean => {
            return result.verified;
        },
        onFail: function* (response: Object, result: Object, fail: string): Iterable<any> {
            let message;
            if (result.message && result.message === 'Invalid Certificate') {
                message = 'SITE_VERIFY_CERT_EXPIRED';
            } else {
                message = fail;
            }
            yield put({ type: message, data: { status: response && response.status, message: result }});
        },
        success: 'SITE_VERIFY_SUCCEED',
        fail: 'SITE_VERIFY_FAILED',
    });
}

function* adminVerifySite(action: Object): Iterable<any> {
    yield call(sagaRequest, {
        apiRoot: env(['configuration', 'mywotUrl']),
        apiPath: `/user/adminSiteVerify/${action.data.domain}/${action.data.uid}`,
        apiMethod: 'GET',
        ifSuccess: (result: Object): boolean => {
            return result.verified;
        },
        success: 'SITE_VERIFY_SUCCEED',
        fail: 'SITE_VERIFY_FAILED',
    });
}

function* sitesSaga(): Iterable<any> {
    yield takeLatest('SITE_CLAIM_REQUESTED', claimSites);
    yield takeLatest('SITE_VERIFY_REQUESTED', verifySite);
    yield takeLatest('SITE_ADMIN_VERIFY_REQUESTED', adminVerifySite);
}

export default sitesSaga;
