// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import Grid from '@material-ui/core/Grid';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import CheckIcon from '@material-ui/icons/Check';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import {FlexRowCentered} from '../../StyledUtils';
import {dropdownLanguages} from '../../utils/const';
import {LangButton, LangListItem, LangListSubItem, LangMenuItem, LanguageChooserText, ListItemFlag, ListItemCheck, ListItemFlagMargin, MenuItemFlag, MenuPaper, ListText, LangListItemText} from './StyledLanguageChooser';
import {pathWithLang} from '../../utils';
import {write} from '../../utils/cookie';

type Props = {
    language: string,
    isInListMenu: boolean,
    dataAutomationId?: string
}
const LanguageChooser = (props: Props): Node => {
    const initialLang: Object = dropdownLanguages.find((lang: Object): Object => lang.key.startsWith(props.language));
    const currentLanguage = initialLang ? initialLang : dropdownLanguages[0];

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const openMenu = (event: MouseEvent<HTMLElement>) => {
        if (!Boolean(anchorEl)) {
            setAnchorEl(event.currentTarget);
        }
        setOpen(true);
    };

    const closeMenu = (): void => setOpen(false);

    const changeLang = (lang: string) => {
        const domain = location.hostname.startsWith('www.') ? location.hostname.substr(3): location.hostname;
        write('lang', lang, `;Path=/;Domain=${domain}`); // lang cookie is always on path '/'
        location.href = `${location.origin}${pathWithLang(location.pathname + location.search, {lang})}`;
    };

    return props.isInListMenu ? (
        <div>
            <LangListItem onClick={open ? closeMenu : openMenu} key='Languages' divider button>
                <ListItemFlag src={currentLanguage.image} data-automation={'flag' + props.dataAutomationId} alt={`${currentLanguage.key}-flag`}/>
                <ListItemText primary={currentLanguage.name}/>
                {open ? <ExpandLess/> : <ExpandMore/>}
            </LangListItem>
            <Collapse in={open} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                    {dropdownLanguages.map((language: Object, i: number): Node => {
                        return (
                            <LangListSubItem divider onClick={(): void => changeLang(language.key)} key={'Lang' + i}>
                                <ListItemFlagMargin src={language.image}/>
                                <ListText primary={language.name} isBold={currentLanguage.name === language.name} disableTypography/>
                                {currentLanguage.name === language.name &&
                                    <ListItemCheck>
                                        <CheckIcon/>
                                    </ListItemCheck>
                                }
                            </LangListSubItem>);
                    })}
                </List>
            </Collapse>
        </div>
    ): (
        <Grid item>
            <LangButton onClick={openMenu} data-automation={'language-button' + props.dataAutomationId} open={open} aria-owns={open ? 'menu-list-grow' : undefined} aria-haspopup="true" >
                <FlexRowCentered>
                    <MenuItemFlag src={currentLanguage.image} data-automation={'flag' + props.dataAutomationId} alt={`${currentLanguage.key}-flag`}/>
                    <LanguageChooserText>{currentLanguage.name}</LanguageChooserText>
                </FlexRowCentered>
                {open ? <ExpandLess/> : <ExpandMore/>}
            </LangButton>
            <Popper open={open} anchorEl={anchorEl} style={{zIndex: 2}} transition disablePortal>
                {({ TransitionProps, placement }: Object): Node => (
                    <Grow {...TransitionProps} id='menu-list-grow' style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                        <MenuPaper>
                            <ClickAwayListener onClickAway={closeMenu} mouseEvent='onClick' touchEvent='onTouchEnd'>
                                <div data-automation={'languages-menu-wrapper' + props.dataAutomationId} >
                                    {dropdownLanguages.map((language: Object, i: number, langList: Object): Node => {
                                        return (
                                            <LangMenuItem onClick={(): void => changeLang(language.key)} data-automation={'language-item' + props.dataAutomationId} key={i}>
                                                <MenuItemFlag src={language.image}></MenuItemFlag>
                                                <LanguageChooserText isBold={currentLanguage.name === language.name}>{language.name}</LanguageChooserText>
                                            </LangMenuItem>);
                                    })}
                                </div>
                            </ClickAwayListener>
                        </MenuPaper>
                    </Grow>
                )}
            </Popper>
        </Grid>
    );
};

export default LanguageChooser;
