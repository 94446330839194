// @flow
import merge from 'lodash/merge';

export function scorecard(state: Object, action: Object): Object {
    switch (action.type) {
        case 'SCORECARD_REQUESTED':
            return merge({}, state, { status: action.type });
        case 'SCORECARD_FAILED':
            return merge({}, state, { status: action.type });
        case 'SCORECARD_SUCCEED':
            return merge({}, state, { status: action.type }, action.data);
        case 'FAIL_ACKNOWLEDGED':
            return merge({}, state, { status: action.type });
        default:
            return merge({}, state);
    }
}
