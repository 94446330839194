// @flow
import merge from 'lodash/merge';

export function general(state: Object = {}, action: Object): Object {
    switch (action.type) {
        case 'LOGIN_MODAL_OPEN':
        case 'LOGIN_MODAL_CLOSE': {
            return merge({}, state, {isLoginModalOpen: action.data});
        }
        case 'SIGNUP_MODAL_OPEN':
        case 'SIGNUP_MODAL_CLOSE': {
            return merge({}, state, {isSignupModalOpen: action.data});
        }
        case 'SET_IS_USER_PREMIUM': {
            return merge({}, state, {isUserPremium: action.data});
        }
        default:
            return merge({}, state);
    }
}
