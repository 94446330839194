// @flow
const styles: Function = (theme: Object): Object => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 18,
        fontWeight: 500,
        lineHeight: '1em',
    },
    containerGooglePlay: {
        width: 203,
        minWidth: '203px !important',
        height: '60px !important',
        backgroundImage: 'url(/images/play.svg)',
        borderRadius: 30,
    },
    button: {
        boxSizing: 'border-box',
        height: 54,
    },
    capitalize: {
        textTransform: 'none',
    },
    icon: {
        width: 26,
        height: 26,
        marginRight: 10,
    },
    roundButton: {
        'width': 'max-content',
        'borderRadius': 25,
        '&$huge': {
            borderRadius: 40,
        },
    },
    huge: {
        minWidth: 240,
        height: 55,
        fontSize: 18,
    },
    long: {
        minWidth: 235,
    },
    invisible: {
        display: 'none',
    },
    label: {
        whiteSpace: 'pre !important',
    },
});

export default styles;
