// @flow
const styles: Function = (theme: Object): Object => ({
    root: {
        position: 'absolute',
        top: 10,
        right: 10,
        cursor: 'pointer',
    },
});

export default styles;
