// @flow

import * as React from 'react';
import type { Node, ComponentType } from 'react';
import { isExtensionInstalled } from '../utils/isExtentionInstalled';

type Props = {}

function isExtInstalled(WrappedComponent: React.ComponentType<any>): ComponentType<Props> {
    return class ExtInstalled extends React.PureComponent<Props> {
        constructor(props: Object) {
            super(props);
        }

        render(): Node {
            return <WrappedComponent isExtInstalled={isExtensionInstalled()} {...this.props}/>;
        }
    };
}

export default isExtInstalled;
