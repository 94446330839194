import styled from 'styled-components';

export const StyledHomepageContainer = styled.div`
  overflow-x: hidden;
`;

export const StyledFooterSection = styled.span`  
  #footerContainer {
      border-top: 0px;
  }
`;
