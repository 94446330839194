// @flow

import React from 'react';
import {StyledFrameContentContainer, StyledFrameContent} from './StyledFrameContent';

type IFrameContentProps = {
    children: any
}

export const FrameContent = (props: IFrameContentProps): any => {
    const {children} = props;
    return (
        <StyledFrameContentContainer>
            <StyledFrameContent>
                {children}
            </StyledFrameContent>
        </StyledFrameContentContainer>
    );
};
