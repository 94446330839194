// @flow
import React, {useContext} from 'react';

import {CircleIcon, TagName, TagWrapper, Tag, TagsContainer, TrailerLine} from './StyledTags';
import type {ILocalesTransformers} from '../../utils/locales';
import {LocalesContext} from '../../utils/locales';

export type ITagsProps = {
    tagsToMap: Object;
    iconPath: string;
    selectedIconPath?: string;
    trackingCategory?: string;
    trackingAction?: string;
}

export const Tags = ((props: ITagsProps): any => {
    const {tagsToMap, iconPath} = props;
    const {translate}: ILocalesTransformers = useContext(LocalesContext);

    return (
        <TagsContainer>
            { tagsToMap.map((tag: Object, index: number): any => (
                <TagWrapper key={tag.name} >
                    <Tag>
                        <CircleIcon iconPath={iconPath}/>
                        <TagName>{translate(tag.name)}</TagName>
                    </Tag>
                </TagWrapper> ))}
        </TagsContainer>
    );
});
