// @flow

import React from 'react';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import {CarouselContainer, StyledMobileStepper, SlideContainer} from './StyledCarousel';

type ICarouselProps = {
    customCarouselProps: Object;
    itemsList: any;
    children: any;
}

export const Carousel = (props: ICarouselProps): any => {
    const {customCarouselProps, itemsList, children} = props;
    const {dotStyle, activeDotStyle, rightButtonStyle, leftButtonStyle, arrowStyle, hideBackButton, hideNextButton, hideOverflow} = customCarouselProps;
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = itemsList.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep: number): any => prevActiveStep + 1);
    };

    const handleBack = (): any => {
        setActiveStep((prevActiveStep: number): any => prevActiveStep - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    const styles = {
        root: {
            overflowX: 'visible'
        }
    };

    return (
        <CarouselContainer>
            <StyledMobileStepper>
                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    variant="dots"
                    activeStep={activeStep}
                    classes={{
                        dot: dotStyle || '',
                        dotActive: activeDotStyle || '',
                    }}
                    nextButton={ hideNextButton ? null :
                        <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}
                                classes={{root: rightButtonStyle || ''}} >
                            <KeyboardArrowRight classes={{root: arrowStyle || ''}}/>
                        </Button>
                    }
                    backButton={ hideBackButton ? null :
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}
                                classes={{root: leftButtonStyle || ''}} >
                            <KeyboardArrowLeft classes={{root: arrowStyle || ''}}/>
                        </Button>
                    }/>
            </StyledMobileStepper>
            <SwipeableViews
                style={hideOverflow ? null : styles.root}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents >
                { itemsList.map((item: Object, index: number): any => (
                    <SlideContainer key={index+1}>
                        { Math.abs(activeStep - index) <= 2 ? (
                            children(item, index)
                        ) : null }
                    </SlideContainer>
                ))}
            </SwipeableViews>
        </CarouselContainer>
    );
};
