// @flow
import merge from 'lodash/merge';
import type Node from 'react';
import React, {createContext, useContext, useMemo} from 'react';
import {EventParams} from '../../hooks/useInternalAnalytics';

type AnalyticsProviderProps = {
    initParams: EventParams,
    children: Node,
}

export const getSegment = (flag: boolean | number): string => {
    switch (true) {
        case !flag:
            return 'control';
        case flag:
            return 'variant';
        default:
            return `variant-${flag}`;
    }
};

export const analyticsContext: React$Context<EventParams> = createContext({});
export const AnalyticsProvider = ({children, initParams}: AnalyticsProviderProps): Node => {
    const prevParams = useContext(analyticsContext);
    const contextValue = useMemo((): EventParams => merge({}, prevParams, initParams), []);
    return (
        <analyticsContext.Provider value={contextValue}>
            {children}
        </analyticsContext.Provider>
    );
};
