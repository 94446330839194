import styled from 'styled-components';
import {FlexRowCentered, mobileBreakPoint} from '../../StyledUtils';
import {imgsPath} from '../../utils/const';

export const ConnectWithGoogleContainer = styled(FlexRowCentered)`
    justify-content: center;
    background: #057AFF;
    color: white;
    border-radius: 99px;
    padding: 2px 24px 2px 2px;
    cursor: pointer;
    width: fit-content;
`;

export const Title = styled.span`
    margin-left: 15px;
    font-size: 16px;
    font-weight: 500;
`;

export const GoogleIconContainer = styled(FlexRowCentered)`
    height: 52px;
    width: 52px;
    background: white;
    border-radius: 50%;
    justify-content: center;
    flex-shrink: 0;
`;

export const GoogleIcon = styled.div`
    height: 28px;
    width: 28px;
    background: url(${imgsPath}/icons/google-icon.svg) no-repeat;
    background-size: contain;
`;

export const Description = styled.span`
    margin-top: 16px;
    color: #B3B3B3;
    font-size: 11px;
    font-weight: 400;
    text-align: inherit;
    line-height: 15px;
    & > a {
        text-decoration: underline !important;
    }
    @media (max-width: ${mobileBreakPoint}){
        margin-top: 8px;
        font-size: 10px;
        line-height: 14px;
    }
`;

export const Terms = styled.a`
    color: #545454;
`;
