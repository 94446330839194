import styled from 'styled-components';
import {FlexRow} from '../../../StyledUtils';

export const ButtonContainer = styled.div`
  z-index: 1;
  cursor: pointer;
  padding: 18px 24px;
  border-radius: 999px;
  align-items: center;
  width: fit-content;
  justify-content: center;
  border: 1.5px solid #037AFF;
`;

export const ButtonContentContainer = styled(FlexRow)`
  align-items: center;
  justify-content: center;
`;

export const Icon = styled.div`
  width: 20px;
  height: 20px;
  margin-left: 10px;
  background: url(${props => props.buttonIconPath}) center center/contain no-repeat;
`;

export const Text = styled.div`
  color: #037AFF;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
`;
