import styled from 'styled-components';
import {FlexColumn, FlexRow, mobileBreakPoint} from '../../../StyledUtils';
import {CarouselContainer} from '../../../components/CarouselOurTechnology/StyledCarouselOurTechnology';
import {ButtonContainer, Text} from '../../../components/buttons/outlineRounded/StyledOutlineRoundedButton';
import {Icon, Title, Wrapper, Description, Container} from '../../../components/FeatureCard/StyledFeatureCard';
import {Tag} from '../../../components/Tags/StyledTags';

export const StyledPremiumContainer = styled(FlexColumn)`
  position: relative;
  justify-content: center;
  background: linear-gradient(117.26deg, #0160FE 0.26%,#0094FF 100%);
  @media (max-width: ${mobileBreakPoint}) {
    flex-direction: column;
    padding: 40px 0 200px 0px;
  }
`;

export const PremiumSectionContent = styled(FlexColumn)`
  margin-top: 80px;
  align-items: center;
  justify-content: center;
  @media (max-width: ${mobileBreakPoint}) {
    margin-top: 0px;
  }
  ${CarouselContainer}{
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const PremiumFeaturesCards = styled(FlexRow)`
  width: 1126px;
  margin: 60px 0px;
  justify-content: space-around;
  @media (max-width: ${mobileBreakPoint}) {
    width: 100%;
    margin: 0px;
    align-items: center;
    flex-direction: column;
  }
  
  ${Container}{
    width: 280px;
    transition: transform 0.1s ease-in-out;
    position: initial;
      &:hover {
        transform: scale(1.1);
      }
  }
  ${Wrapper}{
    position: initial;
    top: 100px;
  }
  ${Icon}{
    width: 56px;
    height: 56px;
  }
  ${Title}{
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin: 15px 0px;
  }
  ${Description}{
    color: #FFFFFF;
    line-height: 24px;
  }
`;

export const SeparatorLine = styled.div`
  height: 228px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  @media (max-width: ${mobileBreakPoint}) {
    height: 0px;
    width: 100%;
    margin: 39px 0px;
  }
`;

export const PremiumFeaturesTags = styled.div`
  margin-bottom: 64px;
  @media (max-width: ${mobileBreakPoint}) {
    margin: 0px;
  }
`;

export const PremiumWrapper = styled(FlexColumn)`
  align-items: center;
  ${ButtonContainer}{
    margin: 17.5px;
    background: #FFFFFF;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.18), 
      0px 0px 2px rgba(0, 38, 80, 0.12), 
      0px 0px 1px rgba(0, 38, 80, 0.04);
    transition: transform 0.1s ease-in-out;
    &:hover {
      transform: scale(1.1);
      box-shadow: 0px 6px 127px rgba(0, 0, 0, 0.25), 
        0px 2.50666px 53.0576px rgba(0, 0, 0, 0.179714), 
        0px 1.34018px 28.3671px rgba(0, 0, 0, 0.149027), 
        0px 0.751293px 15.9024px rgba(0, 0, 0, 0.125), 
        0px 0.399006px 8.44563px rgba(0, 0, 0, 0.100973), 
        0px 0.166035px 3.51442px rgba(0, 0, 0, 0.0702864);
    }
  }
  ${Text}{
    color: #373B3E;
  }
  @media (max-width: ${mobileBreakPoint}) {
    height: 0px;
    width: 100%;
    margin-top: 0px;
  }
`;

export const ButtonSubtitleContainer = styled(FlexRow)`
  width: 100%;
  margin: 17.5px;
  align-items: center;
  justify-content: center;
  @media (max-width: ${mobileBreakPoint}) {
    margin: 0px;
  }
`;

export const ButtonSubtitleWrapper = styled(FlexRow)`
  align-items: center;
  justify-content: center;
`;

export const ButtonSubtitleIcon1 = styled.div`
  width: 30px;
  height: 30px;
  background: url(${props => props.iconPath}) center center/contain no-repeat;
`;

export const ButtonSubtitleIcon2 = styled(ButtonSubtitleIcon1)`
  width: 40px;
  height: 40px;
`;

export const ButtonSubtitleText = styled.div`
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
  margin: 12px;
`;
