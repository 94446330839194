import {useSelector, shallowEqual} from 'react-redux';

export const useDeviceInfo = () => {
    const deviceInfo = useSelector((state) => state.deviceInfo, shallowEqual);
    return deviceInfo || {};
};

export const useAuth = () => {
    const auth = useSelector((state) => state.auth, shallowEqual);
    return auth || {};
};
