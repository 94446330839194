// @flow

import styled, {css, keyframes, BaseThemedCssFunction} from 'styled-components';
import {imgsPath} from './utils/const';

export const zIndexModal = 1302;
export const zIndexBehindModal = 1302;
export const zIndexOnTopOfAll = 9999;

export const smallDesktopBreakPointInt = 1730;
export const mobileBreakPointInt = 894;
export const mobileBreakPoint = `${mobileBreakPointInt}px`;
export const desktopBreakPointInt = 1130;
export const desktopBreakPoint = `${desktopBreakPointInt}px`;
export const tabletBreakPointFromInt = 1129;
export const tabletBreakPointFrom = `${tabletBreakPointFromInt}px`;
export const tabletBreakPointToInt = 895;
export const tabletBreakPointTo = `${tabletBreakPointToInt}px`;

export const desktopMediaQuery = (desktopCss: BaseThemedCssFunction): BaseThemedCssFunction => css`
    @media screen and (min-width: ${desktopBreakPoint}){
        ${desktopCss}
    }
`;
export const tabletMediaQuery = (tabletCss: BaseThemedCssFunction): BaseThemedCssFunction => css`
    @media screen and (min-width: ${tabletBreakPointTo}) and (max-width: ${tabletBreakPointFrom}){
        ${tabletCss}
    }
`;
export const mobileMediaQuery = (mobileCss: BaseThemedCssFunction): BaseThemedCssFunction => css`
    @media screen and (max-width: ${mobileBreakPoint}){
        ${mobileCss}
    }
`;

export type IIconSize = 'xs' | 'sm' | 'md' | 'lg';

export const iconSizes = {
   xs: 16,
   sm: 24,
   md: 20,
   lg: 32
};

export const getIconSize = (size: string): number => size ? iconSizes[size] : iconSizes.sm;
const getIconCss = (s: string): string => {
    const size = getIconSize(s);
    return (`
      display: block;
      height: ${size}px;
      width: ${size}px; 
      fill: #929292;
      margin-right: 8px;
  `);
};

export const trustLevelColors = {
  safe: '#14D478',
  not_safe: '#FF4746',
  suspicious: '#FFA033',
  highly_suspicious: '#FFA033',
  unknown: '#929CA5'
};

export const getTrustLevelColor = (trustLevel: string): string => (trustLevel && typeof(trustLevel) === 'string' && trustLevelColors[trustLevel.toLowerCase()]) || trustLevelColors.unknown;

const getCircleIconCss = (s: string, isEmptyCircle?: boolean): string => {
    const size = getIconSize(s);
    const diff = isEmptyCircle ? 4 : 8; // parent of <svg>
    return (`
      height: ${size + diff}px;
      width: ${size + diff}px; 
      svg {
         height: ${size}px;
         width: ${size}px;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      fill: #fff;
      background-color: #929292;
      border-radius: 50%;
  `);
};

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexColumnCentered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FlexRowCentered = styled(FlexRow)`
  align-items: center;
`;

export const Icon = styled.i.attrs({
    'data-automation': 'icon'
})((props: any): string => `
  ${getIconCss(props.size)}
`);

export const LinkIcon = styled.a.attrs({
    'data-automation': 'link-icon',
    'rel': 'noopener'
})((props: any): string => `
  ${getIconCss(props.size)};
`);

export const CircleIcon = styled(Icon)(({size}: any): string => `
  ${getCircleIconCss(size)};
`);

export const EmptyCircleIcon = styled(Icon)(({size}: any): string => `
  ${getCircleIconCss(size, true)};
  fill: #929292;
  background-color: #fff;
  border: 2px solid #929292; 
`);

export const CircleLinkIcon = styled(LinkIcon)(({size}: any): string => `
  ${getCircleIconCss(size)};
`);

export const trustLevelDonutImages = {
  safe: 'green',
  not_safe: 'red',
  suspicious: 'orange',
  highly_suspicious: 'orange',
  unknown: 'gray'
};

export const getTrustLevelDonutImage = (trustLevel: string): string => (trustLevel && typeof(trustLevel) === 'string' && trustLevelDonutImages[trustLevel.toLowerCase()]) || trustLevelDonutImages.unknown;

export const Donut = styled.div`
  width: 18px;
  height: 18px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: ${({trustLevel}: any): string => `url(${imgsPath}/donut/${getTrustLevelDonutImage(trustLevel)}.png)`};
`;

export const Shield = styled(Donut)`
  background-image: ${({trustLevel}: any): string => `url(${imgsPath}/shields/${getTrustLevelDonutImage(trustLevel)}.svg)`};
`;

export const WotLogo = styled.a.attrs({
  'alt': 'WOT Logo',
  'href': '/',
})`
  width: 73px;
  height: 33px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/images/logo.svg);
`;

export const CallToAction = styled.a`
  border-radius: 4px;
  background-color: #f4f6f2;
  padding: 14px;
  min-width: 192px;
  color: #1b1b1b;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
`;

export const FadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const FadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;
