// @flow

const styles: Function = (): Object => ({
    reviewsDotStyle: {
        backgroundColor: '#FFFFFF',
    },
    reviewsActiveDotStyle: {
        backgroundColor: '#037AFF',
    },
    reviewsRightButton: {
        position: 'absolute',
        top: '400px',
        right: '117px',
        zIndex: '1',
        padding: '0',
        minWidth: '0',
        minHeight: '0',
        borderRadius: '50%',
        backgroundColor: '#037AFF',
        '&:hover': {
            backgroundColor: '#165AD9'
        }
    },
    reviewsLeftButton: {
        position: 'absolute',
        top: '400px',
        left: '117px',
        zIndex: '1',
        padding: '0',
        minWidth: '0',
        minHeight: '0',
        borderRadius: '50%',
        backgroundColor: '#037AFF',
        '&:hover': {
            backgroundColor: '#165AD9'
        }
    },
    reviewsArrow: {
        color: '#FFFFFF',
    },
    reviewsDisabledArrow: {
        backgroundColor: '#9e9e9e87',
    }
});

export default styles;
