// @flow
const styles: Function = (theme: Object): Object => ({
    root: {
        flexGrow: 1,
        zIndex: `1305 !important`,
    },
    paper: {
        [theme.breakpoints.up('sm')]: {
            padding: 18,
        },
        overflow: 'visible',
    },
    phoneContainer: {
        marginTop: 20,
    },
    phoneDropdown: {
        position: 'fixed !important',
    },
    phoneInput: {
        maxWidth: '98%',
        boxShadow: 'none !important',
    },
    wideLoader: {
        minWidth: 320,
        margin: '70px 0px',
    },
});

export default styles;
