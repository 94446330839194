// @flow
import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';

export function usercard(state: Object = {}, action: Object): Object {
    switch (action.type) {
        case 'USERCARD_REQUESTED':
        case 'USERCARD_FAILED':
            return merge({}, state, {
                card: {
                    status: action.type,
                },
            });
        case 'USERCARD_ENDED':
            return merge({}, state, {
                card: {
                    data: merge({}, { avatar: action.data.data.picture }, action.data.data),
                    status: action.type,
                },
            });
        case 'USERRATINGS_REQUESTED':
        case 'USERRATINGS_FAILED':
            return merge({}, state, {
                ratings: {
                    status: action.type,
                },
            });
        case 'USERRATINGS_SUCCEED':
            return merge({}, state, {
                ratings: {
                    data: [...(state.ratings ? state.ratings.data : []), ...action.data.data],
                    status: action.type,
                    moreContent: action.data.moreContent,
                },
            });
        case 'USERSITES_REQUESTED':
        case 'USERSITES_FAILED':
            return merge({}, state, {
                sites: {
                    status: action.type,
                },
            });
        case 'USERSITES_SUCCEED':
            return merge({}, state, {
                sites: {
                    data: [...(state.sites ? state.sites.data : []), ...action.data.data],
                    status: action.type,
                    moreContent: action.data.moreContent,
                },
            });
        case 'OTHER_USERSITES_SUCCEED':
            return merge ({}, state, {
                otherUserSites: {
                    data: [...action.data.data],
                    status: action.type,
                    moreContent: action.data.moreContent,
                },
            });
        case 'USERSITE_DELETE_REQUESTED':
        case 'USERSITE_DELETE_FAILED':
            return merge({}, state);
        case 'USERSITE_DELETE_SUCCEED':
            const oldSitesList = state.sites.data;
            const deletedSite = action.data.url;
            let data = [];
            let _state = merge({}, state);

            oldSitesList.forEach((site: Object) => {
                site.url !== deletedSite && data.push(site);
            });

            _state.sites = {
                status: action.type,
                data,
            };

            return _state;
        case 'AVATAR_UPDATE_REQUESTED':
        case 'AVATAR_UPDATE_FAILED':
            return merge({}, state, {
                card: {
                    avatarStatus: action.type,
                },
            });
        case 'AVATAR_UPDATE_SUCCEED':
            return merge({}, state, {
                card: {
                    avatarStatus: action.type,
                    picture: action.data.link,
                },
            });
        case 'ACCOUNT_DELETE_REQUESTED':
        case 'ACCOUNT_DELETE_FAILED':
        case 'ACCOUNT_DELETE_SUCCEED':
            return merge({}, state, {
                card: {
                    status: action.type,
                },
            });
        case 'RESEND_EMAIL_SUCCEED':
        case 'RESEND_EMAIL_FAILED':

        default:
            return merge({}, state);
    }
}
