// @flow
import merge from 'lodash/merge';

export function recentReviews(state: Object = {}, action: Object): Object {
    switch (action.type) {
        case 'RECENT_REVIEWS_REQUESTED':
            return merge({}, state, { status: action.type });
        case 'RECENT_REVIEWS_FAILED':
            return merge({}, state, { status: action.type });
        case 'RECENT_REVIEWS_SUCCEED':
            let old = state.data || [];
            let newReviews = [...old, ...action.data];
            return merge({}, state, { data: newReviews, status: action.type });
        default:
            return merge({}, state);
    }
}
