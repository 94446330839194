// @flow
import merge from 'lodash/merge';
import {useContext} from 'react';
import {useFeatureValue} from '@growthbook/growthbook-react';
import {getAppState} from '../layouts/default';
import {isNode} from '../utils/isnode';
import {getAnalyticsUuid, sendInternalEvent} from '../utils/analytics';
import { getOS, getUtmString, isEmptyObject } from '../utils';
import {analyticsContext} from '../components/AnalyticsProvider/AnalyticsProvider';

export type AnalyticsSender = (params?: EventParams) => Promise<void>;
export type EventParams = {
    type?: string,
    platform?: string,
    testGroup?: string,
    specificFields?: {
        segment?: boolean,
        [key: string]: any
    }
}

type AnalyticsEvent = {
    product: string;
    platform: string;
    uuid: string;
    event_type: string;
    timestamp: number;
    test_group: string;
    user_agent: string;
    payload: Payload;
}

type Payload = {
    eventSpecificFields?: Record<string, string>;
    isLoggedIn?: boolean;
    isPremium?: boolean;
    os?: string;
}

const buildUtmData = (): Object => {
    const utmObj = {};
    for (const utm of getUtmString().split('&')) {
        const [key, value] = utm.split('=');
        if (value) {
            utmObj[key] = value;
        }
    }
    return utmObj;
};

export const useInternalAnalytics = (initParams: EventParams = {}, withoutContext: boolean = false): AnalyticsSender => {
    const providerParams = withoutContext ? {} : useContext(analyticsContext);
    return async (params: EventParams = {}): Promise<void> => {
        const {auth = {}, deviceInfo = {}} = getAppState();
        const {isMobile, browserName, lang} = deviceInfo;
        const isLoggedIn = Boolean((auth.token) || (!isEmptyObject(auth)));
        const isPremium = Boolean(auth.isUserPremium);
        if (isNode()) {
            return;
        }
        const {platform, testGroup, type, specificFields} = merge({}, providerParams, initParams, params);
        const utmData = buildUtmData();
        const event: AnalyticsEvent = {
            product: 'WOT',
            country: lang,
            platform,
            event_type: type,
            test_group: testGroup,
            user_agent: browserName,
            payload: {
                isLoggedIn,
                isPremium,
                os: getOS(),
                eventSpecificFields: {
                    ...utmData,
                    device: isMobile ? 'mobile' : 'desktop',
                    ...(specificFields || {}),
                }
            }
        };
        try {
            sendInternalEvent({...event, timestamp: Date.now()});
        } catch (e) {
            console.error(e);
        }
    };
};

export const useEnabledFeatureAnalytics = (initParams: EventParams = {}): AnalyticsSender | () => {} => {
    const sendAnalytics = useInternalAnalytics(initParams);
    const providerContext = useContext(analyticsContext);
    const testGroup = (initParams.testGroup || providerContext.testGroup || '').replace(/_/g, '-');
    const isFeatureAvailable = useFeatureValue(testGroup, null);
    return isFeatureAvailable === null ?
        () => {/* don't send Analytics*/}:
        sendAnalytics;
};
