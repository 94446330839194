// @flow
import React from 'react';
import {useSelector} from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {useDeviceInfo, useTranslate} from '../../../hooks';
import {FrameContent} from '../../../components/FrameContent/FrameContent';
import InstallButtonContainer from '../../../components/buttons/InstallContainer/InstallButtonContainer';
import supportedlist from './supportedlist.json';
import {BROWSER_STORES_LINKS} from '../../../utils/const';
import {Description, StyledReviewsContainer, BrowserIconsRow, BrowserIcon, VerticalSeparatorLine, ReviewsTopBackground, ReviewsContent} from './StyledReviewsSection';
import {SectionHeader} from '../../../components/SectionHeader/SectionHeader';
import customerreviews from './customerreviews.json';
import {UserReviewCard} from '../../../components/UserReviewCard/UserReviewCard';
import {Carousel} from '../../../components/Carousel/Carousel';
import styles from './styles';
import { REFERRAL_STORE_LINKS } from '../../../utils/const'

const ReviewsSection = ({ classes }: Object): any => {
    const translate = useTranslate();
    const { isMobile } = useDeviceInfo();
    const isUserPremium = useSelector((state: Object): Object => state.auth?.isUserPremium);
    const mobileDownloadLink = REFERRAL_STORE_LINKS.BOOTOM_HP;
    const onCtaClick = (browserName: string) => {
        const link = isMobile ? mobileDownloadLink : BROWSER_STORES_LINKS[browserName];
        window.open(link);
    };

    const customCarouselProps = {
        dotStyle: classes.reviewsDotStyle,
        activeDotStyle: classes.reviewsActiveDotStyle,
        rightButtonStyle: classes.reviewsRightButton,
        leftButtonStyle: classes.reviewsLeftButton,
        arrowStyle: classes.reviewsArrow,
        hideNextButton: !!isMobile,
        hideBackButton: !!isMobile,
        hideOverflow: !isMobile,
        disabledArrowStyle: classes.reviewsDisabledArrow
    };


    return (
        <StyledReviewsContainer>
            <ReviewsTopBackground/>
            <FrameContent>
                <ReviewsContent>
                    <SectionHeader
                        iconPath={'/images/home/message-icon.svg'}
                        title={translate('pages.home.review-section.title')}/>
                    <Carousel itemsList={customerreviews}
                              customCarouselProps={customCarouselProps}>
                        {(item: any, index: number): any => (
                            <UserReviewCard
                                avatarNo={index+1}
                                starsValue={item.starsValue}
                                userReview={translate(item.userReview)}
                                userName={item.userName}
                                reviewDate={item.reviewDate}/> )}
                    </Carousel>
                    <Description>{translate('pages.home.review-section.subtitle')}</Description>
                    <InstallButtonContainer customLink={isMobile && mobileDownloadLink} trackingCategory={'Homepage'} trackingAction={'Reviews_Install'} wotProductSource={'hompage_Bottom'} round bigButton showPremiumCta isUserPremium={isUserPremium}/>
                    <BrowserIconsRow>
                        { supportedlist.map((item: Object): any => {
                            if (item.browser === 'separator') {
                                return (
                                    <VerticalSeparatorLine
                                        key={item.browser}/>
                                );
                            } else {
                                return (
                                    <BrowserIcon
                                        key={item.browser}
                                        imgPath={item.icon}
                                        onClick={(): void => onCtaClick(item.browser)}/>
                                );
                            }
                        })}
                    </BrowserIconsRow>
                </ReviewsContent>
            </FrameContent>
        </StyledReviewsContainer>
    );
};

export default withStyles(styles, { withTheme: true })(ReviewsSection);
