import styled from 'styled-components';
import {FlexColumn, FlexRow, mobileBreakPoint} from '../../StyledUtils';

export const UserReviewCardContainer = styled(FlexColumn)`
  width: 100%;
  min-height: 170px;
  max-width: 902px;
  margin-top: 50px;
  position: relative;
  border-radius: 24px;
  align-items: center;
  justify-content: center;
  padding: 70px 0px 42px 0px;
  background: rgba(250, 250, 250, 0.75);
  @media (max-width: ${mobileBreakPoint}) {
    min-height: 250px;
    width: calc(100% - 60px);
    scroll-snap-align: center;
    padding: 70px 24px 42px 24px;
  }
`;

export const Avatar = styled.div`
  top: -50px;
  position: absolute;
  width: 96px;
  height: 96px;
  margin: 0px 10px;
  border-radius: 50%;
  box-sizing: border-box;
  background: url(/images/avatars/homepage-avatar-${props => props.avatarNo}.svg) center center/contain no-repeat;
  filter: drop-shadow(0px 24px 32px rgba(0, 0, 0, 0.04)) drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.04)) 
    drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));
`;

export const UserReview = styled.div`
  width: 534px;
  color: #545454;
  font-size: 20px;
  margin: 16px 0px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  @media (max-width: ${mobileBreakPoint}) {
    width: 100%;
  }
`;

export const UserDetailsContainer = styled(FlexRow)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UserDetail = styled.div`
  color: #929CA5;
  font-size: 16px;
  font-weight: 400;
  margin: 0px 13px;
  line-height: 24px;
  text-align: center;
`;
