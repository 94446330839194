// @flow
import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import classnames from 'classnames';
import InputAdornment from '@material-ui/core/InputAdornment';

import { withStyles } from '@material-ui/core/styles';


import styles from './styles';

export const CommonInput = withStyles(styles)(
    ({
         inputClass,
         helperText,
         value,
         adornment,
         endAdornment,
         classes,
         underline=true,
         shouldShownLabel=true,
         ...props
     }: Object): any => {
        return (
            <TextField
                InputLabelProps={{
                    disableAnimation: false,
                    classes: {
                        shrink: value && shouldShownLabel
                            ? (adornment
                                ? classes.labelHackAdornment
                                : classes.labelHack)
                            : classes.labelInvisible,
                        root: value && shouldShownLabel ? classes.labelVisible : classes.labelInvisible,
                        focused: value && shouldShownLabel ? classes.labelVisible : classes.labelInvisible,
                    },
                    ['data-automation']: `${props['data-automation']}-${value ? 'label-up' : 'label'}`,
                }}
                FormHelperTextProps={{
                    classes: {
                        root: classes.helperText,
                    },
                }}
                InputProps={{
                    'data-automation': props['data-automation'],
                    'classes': {
                        root: classnames(inputClass),
                        underline: classes.underline,
                        input: classnames(
                            classes.inputRoot,
                            value && shouldShownLabel ? classes.inputHack : '',
                            endAdornment ? classes.endAdornmentHack : '',
                        ),
                    },
                    'className': classes.input,
                    'disableUnderline': !underline || !value,
                    'startAdornment': adornment ? (
                        <InputAdornment
                            position="start"
                            className={classes.adornment}
                            data-automation={props['data-automation'] + '-start-adornment'}
                        >
                            {adornment}
                        </InputAdornment>) : (<i/>),
                    'endAdornment': endAdornment ? (
                        <InputAdornment
                            position="end"
                            className={classes.adornmentEnd}
                            data-automation={props['data-automation'] + '-end-adornment'}
                        >
                            {endAdornment}
                        </InputAdornment>
                    ) : (<i/>),
                }}
                inputProps={{
                    'data-automation': props['data-automation'] + '-input',
                }}
                helperText={<span data-automation={props['data-automation'] + '-helper'}>{helperText}</span>}
                value={value}
                {...props}
            />
        );
    });
