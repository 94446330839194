// @flow
import { all, put, takeLatest } from 'redux-saga/effects';
import env from '../utils/env.js';
import { getWotTraceId } from '../utils';

export function* getScorecard(action: Object): Iterable<any> {
    try {
        let root = env(['configuration', 'scorecardApi']);
        if (typeof root !== 'string' || root.length < 5) {
            yield put({ type: 'SCORECARD_FAILED', data: { message: 'empty configuration fro api' } });
        } else {
            const wotTraceId = getWotTraceId();

            let response = yield fetch(`${root}scorecard?url=${action.target}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'wot-trace-id': wotTraceId
                },
                credentials: 'same-origin'
            });

            let result = yield (response && response.json());

            if (response && response.status === 200 && result && !result.status) {
                yield put({ type: 'SCORECARD_SUCCEED', data: result });
            } else {
                yield put({ type: 'SCORECARD_FAILED', data: result });
            }
        }
    } catch (err) {
        yield put({ type: 'SCORECARD_FAILED', data: err });
    }
}

function* scorecardSaga(): Iterable<any> {
    yield all([takeLatest('SCORECARD_REQUESTED', getScorecard)]);
}

export default scorecardSaga;
