// @flow
import React from 'react';
import {useDeviceInfo, useTranslate} from '../../../hooks';
import { FrameContent } from '../../../components/FrameContent/FrameContent';
import { FeatureCard } from '../../../components/FeatureCard/FeatureCard';
import tagslist from './tagslist.json';
import { Tags } from '../../../components/Tags/Tags';
import { OutlineRoundedButton } from '../../../components/buttons/outlineRounded/OutlineRoundedButton';
import { SectionHeader } from '../../../components/SectionHeader/SectionHeader';
import { PremiumFeaturesCards, PremiumSectionContent, PremiumFeaturesTags, StyledPremiumContainer, PremiumWrapper, SeparatorLine,
    ButtonSubtitleIcon1, ButtonSubtitleIcon2, ButtonSubtitleText, ButtonSubtitleContainer, ButtonSubtitleWrapper } from './StyledPremiumSection';
import { getStoreLink } from '../../../utils';
import { REFERRAL_STORE_LINKS } from '../../../utils/const'

export const PremiumSection = (): any => {
    const translate = useTranslate();
    const { isMobile } = useDeviceInfo();
    const btnLink = getStoreLink('homepage_premium');
    const btnLinkMobile = REFERRAL_STORE_LINKS.FREE_TRIAL;

    return (
        <StyledPremiumContainer>
            <FrameContent>
                <PremiumSectionContent>
                     <SectionHeader
                         iconPath={'/images/stars/white-full-star.svg'}
                         title={translate('pages.home.premium-section.title')}
                         subtitle={translate('pages.home.premium-section.subtitle')}
                         subtitleComponent={'h2'}/>
                    <PremiumFeaturesCards>
                        { isMobile ? <SeparatorLine/> : null }
                        <FeatureCard
                            hideButton={true}
                            iconPath={'/images/home/premium-anti-phishing.svg'}
                            title={translate('pages.home.premium-section.card1.title')}
                            description={translate('pages.home.premium-section.card1.desc')}/>
                        <SeparatorLine/>
                        <FeatureCard
                            hideButton={true}
                            iconPath={'/images/home/premium-adult-protection.svg'}
                            title={translate('pages.home.premium-section.card3.title')}
                            description={translate('pages.home.premium-section.card3.desc')}/>
                        { isMobile ? <SeparatorLine/> : null }
                    </PremiumFeaturesCards>
                    <PremiumFeaturesTags>
                        <PremiumWrapper>
                            <OutlineRoundedButton
                                buttonText={translate('components.floating.premium.free-trial.button')}
                                buttonLink={isMobile ? btnLinkMobile : btnLink}
                                buttonIconPath={'/images/icons/arrows/arrow-right-orange.svg'}
                                trackingCategory={'Homepage'}
                                trackingAction={'Premium_button_click'} />
                            { isMobile ? null :
                                <Tags
                                    tagsToMap={tagslist}
                                    iconPath={'/images/stars/white-full-star.svg'}
                                    selectedIconPath={'/images/icons/checkmark-white.svg'}
                                    trackingCategory={'Homepage'}
                                    trackingAction={'Features_slide_click'}/>
                            }
                            <ButtonSubtitleContainer>
                                { isMobile ? null : <ButtonSubtitleIcon1 iconPath={'/images/home/30-days-cycle.svg'}/> }
                                <ButtonSubtitleText>{translate('pages.home.premium-section.button.subtitle1')}</ButtonSubtitleText>
                                { isMobile ? null :
                                    <ButtonSubtitleWrapper>
                                        <ButtonSubtitleIcon2 iconPath={'/images/home/shield-check.svg'}/>
                                        <ButtonSubtitleText>{translate('pages.home.premium-section.button.subtitle2')}</ButtonSubtitleText>
                                        <ButtonSubtitleIcon2 iconPath={'/images/home/calendar.svg'}/>
                                        <ButtonSubtitleText>{translate('pages.home.premium-section.button.subtitle3')}</ButtonSubtitleText>
                                    </ButtonSubtitleWrapper> }
                            </ButtonSubtitleContainer>
                        </PremiumWrapper>
                    </PremiumFeaturesTags>
                </PremiumSectionContent>
            </FrameContent>
        </StyledPremiumContainer>
    );
};
