// @flow
import { createTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

export const HEADER_HEIGHT = 64;
export const MAX_FOOTER_HEIGHT = 150;
export const MIN_HEIGHT_LIMITED_LAYOUT = `calc(100vh - ${HEADER_HEIGHT}px - ${MAX_FOOTER_HEIGHT}px)`;
export const MAX_WIDTH_LIMITED_LAYOUT = 1200;
export const MIN_WIDTH_LIMITED_LAYOUT = 280;

export const theme = createTheme({
    palette: {
        secondary: {
            light: '#fff',
            main: '#fff',
            dark: grey[100],
        },
        primary: {
            light: '#77BC31',
            main: '#6cb720',
            dark: '#61ab1f',
            contrastText: '#fff',
        },
        error: {
            light: '#e57373',
            main: '#f44336',
            dark: '#d32f2f',
            contrastText: '#fff',
        },
    },
    overrides: {
        MuiAppBar: {
            root: {
                alignItems: 'center',
                boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 0.15)'
            },
        },
        MuiToolbar: {
            root: {
                width: '100%',
                minHeight: `${HEADER_HEIGHT}px !important`,
                height: HEADER_HEIGHT,
                boxSizing: 'border-box',
            },
            gutters: {
                minHeight: `${HEADER_HEIGHT}px !important`,
                height: HEADER_HEIGHT,
            },
        },
        MuiMobileStepper: {
            root: {
                backgroundColor: 'transparent',
            },
            dots: {
                width: '110px',
                justifyContent: 'space-between',
            },
            dotActive: {
                backgroundColor: 'black'
            }
        }
    },
    constants: {
        MAX_WIDTH_LIMITED_LAYOUT,
        MIN_WIDTH_LIMITED_LAYOUT,
        MIN_HEIGHT_LIMITED_LAYOUT,
    },
});
